import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";

const useAudio = () => {


  const url = "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-6.mp3"
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const AudioPlayer = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div>
       {/* < ViewHeader/>
       <Grid container columns={12} spacing={1}>
        <Grid item md={1} lg={1}></Grid>

        <Grid item md={10} lg={10}> */}
          {/* video content Grid */}
         
          <Button variant = "contained"  size = "small"
          sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', }}} 
                        onClick={toggle}>{playing ? "Pause" : "Play"}</Button>
 
        {/* </Grid>
        <Grid item md={1} lg={1}></Grid>
      </Grid> */}
    </div>
  );
};

export default AudioPlayer;