import React, { useState } from "react";
import "../../components/Header/Header.scss";
import "../../styles/Link.scss";
import "./AdminHeader.scss";
import { BrowserRouter as router, Route, Link } from "react-router-dom";
import { Badge, IconButton } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';




class AdminHeader extends React.Component {
    render() {
        return (
            <header className="">
                {/* <!-- Logo --> */}
                <div className="logo-part">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-xl-12 d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                                <div className="header-logo d-flex justify-content-between align-items-center"><a className="header-logo__img" href="#"><img src="assets/images/header/company-logo1.svg" /></a>
                                    <div className="sidenav-btn sidenav-btn--dashboard d-inline-block d-md-none"><span className="sidenav-btn__item"></span><span className="sidenav-btn__item"></span><span className="sidenav-btn__item"></span></div>
                                </div>
                                <div className="header-search d-inline-flex align-items-center" style={{paddingRight:"14px"}}>
                                    {/* <img src="assets/images/header/notification.png"className="notification-icon"></img>
                                    <span className="badge1">3</span> */}
                                    <Badge badgeContent={3}  sx={{
                                                "& .MuiBadge-badge": {
                                                color: "white",
                                                backgroundColor: "red"
                                                }
                                            }}>
                                        <NotificationsIcon color="action" />
                                    </Badge>
                                    <ul className="dashboard-head-links mb-0 d-none d-md-flex align-items-center flex-wrap clearfix">
                                        <li className="menu-part-list__item float-md-left">
                                            <a className="dropdown-head" >
                                                <IconButton sx = {{p:0}}><PersonIcon /></IconButton>
                                                {/* <img src="assets/images/user.png"  height="32px" width="32px"/> */}
                                                </a>
                                            <div className="dropdown-nav">
                                                <ul className="menu-dropdown mb-0">
                                                    <li className="menu-dropdown__item"><a href="/Userdetails/1">My Profile</a></li>
                                                    <li className="menu-dropdown__item"><a href="">Change Password</a></li>
                                                    <li className="menu-dropdown__item"><a href="/">Logout</a></li>
                                                    
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="sidenav-btn sidenav-btn--dashboard d-none d-md-inline-block ml-4 d-lg-none"><span className="sidenav-btn__item"></span><span className="sidenav-btn__item"></span><span className="sidenav-btn__item"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
export default AdminHeader;