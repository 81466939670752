import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Preview } from '@mui/icons-material';
import { 
  // useNavigate,
  useHistory } from 'react-router-dom';

export default function Sortstudent() {
  const history = useHistory();
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
//   const handleclick = (event) => {
//     history.push('/longanswer');
    
//   };

  return (
    <div>
      <FormControl sx={{marginTop:'1rem',align:'right', minWidth: 80,flex: 1 }}>
        <Select
          displayEmpty
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          size="small"
          sx={{backgroundColor:"#f95877",fontFamily:'Helvetica,Arial,sans-serif',fontWeight:500,textAlign:'left',color:'#fff',height: '2.6375em',border:'0px',borderRadius:'8px'}}
          value={age}
          onChange={handleChange}
        > 
          <MenuItem  value=""><em>Sort by</em></MenuItem>
          <MenuItem  value={10}>Last Update(Newest)</MenuItem>
          <MenuItem  value={11}>Last Update(Oldest)</MenuItem>
          <MenuItem  value={12}>Last Update(Highest)</MenuItem>
          <MenuItem  value={13}>Last Update(Lowest)</MenuItem>
          
          
        </Select>
        </FormControl>
    </div>
  );
}