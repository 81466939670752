import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllMycourse } from "../../store/action";
import * as React from "react";
import Mycourse from "./Enrolledcourses";
import { Grid, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Pagination from '@mui/material/Pagination';


function Home() {
  const [page, setPage] = React.useState(1);
 
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getAllMycourse({page}));
  }, [page]);

  
  return (
    <Grid container columns={12} >
      
      <Grid minWidth="100%" item lg={12} md={12} sm={12} xs={12} >
        <Card
        elevation="none"
        style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            minHeight:"auto",
            minWidth: "768px",
            maginLeft:"auto",
            maxWidth:"fit-content",
            
            border:"none",
            display:"flex",
          
          }}
        >
          <Grid container width="100%" height="auto" spacing={1} columns={12} sx={{padding: 4}} >
            {/* <Grid item lg={6} md={6} sm={6} xs={12}  >
            <Typography sx={{fontSize:".8225rem",fontWeight:"400",textTransform: 'uppercase',paddingTop:0.85,fontFamily:"Exo\ 2,Helvetica Neue,Arial,sans-serif",color:"rgba(39,44,51,.5)"}}>Displaying endrolled courses</Typography>
            </Grid>
            
            <Grid item lg={12} md={12} sm={12} xs={12} align="left" sx={{marginTop:2}}  >
              <p1>{"ENROLLED COURSES"}</p1>
            </Grid> */}
            <Grid sx={{justifyContent:"flex-start",minWidth:'768px',alignContent:'space-around'}} item lg={12} md={12} sm={12} xs={12}   >
            <Mycourse />
            </Grid>
            <Grid container spacing={3} columns={12}  >
              <Grid item lg={12} md={12} sm={12} xs={12} align="left" sx={{marginTop:4}} >
                  <Stack spacing={2}>
                      <Pagination 
                      
                      count={3} 
                      onChange={(e, value) => setPage(value)}
                  
                      />
                  </Stack>
              </Grid>

          </Grid> 
          </Grid> 
          
          
        </Card>

      </Grid>
      {/* <Grid item lg={1} md={1} sm={1} xs={1}  >

      </Grid> */}
    </Grid>

  )
    
}

export default Home;