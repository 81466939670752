import { Component } from "react";
import Table from 'react-bootstrap/Table';
import { FaSortAlphaDown,FaSortAlphaDownAlt,FaSortAmountDownAlt,FaSortAmountDown,FaSort } from 'react-icons/fa';
import Filter from "./Filter";

export default class SortableTable extends Component{
    props : any;
    tableData : any = [];
    state:any={sortedColumn:{name:"",type:"type"}};
    constructor(props:any){
        super(props);
        this.initialize(props.data);
    }

    getHeaderFields=()=>{
        let headers : any = [];
        if(this.tableData.length > 0){
            Object.keys(this.tableData[0]).forEach((field:any,index:any)=>{
                const sortable : any = Object.values(this.tableData[0])[index];
                headers.push({
                    value : field,
                    is_sortable : sortable.sortable,
                    column_id : index
                });
            });
        }
        return headers;
    }

    public initialize=(data:any)=>{
        this.tableData = data.columnDefinition;
        console.log("Hitted");
    }

    onSort(field:string,type:string,column_id:number){
        this.tableData = Filter.sort(type,this.tableData,column_id);
        this.setState({sortedColumn:{name:field,type:type}});
    }

    render(){
        return (
            <Table striped bordered hover>
            <thead>
                <tr>
                    {
                        this.getHeaderFields().map((header:any,index:any)=>(
                            <th key={index}>
                                <div style={{display:"flex",justifyContent: "space-between"}}>
                                    {header.value}
                                    {
                                        header.is_sortable &&
                                        <div>
                                        {
                                            (() => 
                                                {
                                                    if(this.state.sortedColumn.name == header.value && this.state.sortedColumn.type == "asc")
                                                        return(
                                                            <FaSortAmountDown onClick={()=>this.onSort(header.value,"desc",header.column_id)}/>
                                                        )
                                                    else if(this.state.sortedColumn.name == header.value && this.state.sortedColumn.type == "desc")
                                                        return(
                                                            <FaSortAmountDownAlt onClick={()=>this.onSort(header.value,"asc",header.column_id)}/>
                                                        )
                                                    else
                                                        return(
                                                            <FaSort onClick={()=>this.onSort(header.value,"asc",header.column_id)}/>
                                                        )
                                                }
                                            )()  
                                        }
                                        </div>
                                    }
                                </div>
                            </th>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                {
                    this.tableData.map((fields:any,index:any)=>(
                        <tr key={index}>
                            {
                                Object.values(fields).map((field:any,index:any)=>{
                                    if(field.action != undefined)
                                        return(
                                            <td style={field.style} key={index} onClick={()=>field.action.event(field.action.param)}>
                                                {field.value}
                                            </td>
                                        )
                                    else
                                        return(
                                            <td style={field.style} key={index} >{field.value}</td>
                                        )
                                })
                            }
                        </tr>
                    ))
                }
            </tbody>
            </Table>
          );
        }
    }