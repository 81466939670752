import React from 'react'
import Skeleton from '@mui/material/Skeleton';


function Loader() {
  return (
    <div><Skeleton variant="rectangular" sx={{ bgcolor: '#f5f6fa.900' }} width={"auto"} height={"100vh"} /></div>
  )
}

export default Loader