import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import RichTextbox from './RichTextbox';
import { 
  // useNavigate 
  Link

} from 'react-router-dom';



const choice = ['Answer 1','Answer 2','Answer 3','Answer 4','Answer 5']
export default function Longanswer() {

  // const navigate = useNavigate();
  // const handlecancel = (event) => {
  //   navigate('/createquiz'); 
  //     };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <Grid container columns = {12} spacing = {1} sx = {{mt:3,mb:3}} >
        <Grid item xs = {2} sm = {2} md = {1} lg ={1} >
          {/* left */}
          </Grid>
        <Grid item xs = {8} sm = {8} md = {10} lg ={10}>
        <Typography variant = "h4" sx = {{mt:2,mb:2}}>Adding a Long answer question</Typography>

              
        <Typography variant="h6" sx={{ color: "text.secondary" }}>
          General :
        </Typography>

           <Grid container columns = {12} sx={{ mt: 2 }} >

              <Grid item xs = {12} sm = {12} md = {3} lg = {3} sx = {{mt:2}} >
                <Typography>Question name</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} sx = {{mb:2}}>
                <TextField fullWidth></TextField>
              </Grid>

              <Grid item xs = {12} sm = {12} md = {3} lg = {3} sx = {{mt:2}}>
                <Typography>Question text</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} sx = {{mb:2}}>
                <RichTextbox />
              </Grid>

              <Grid item xs = {12} sm = {12} md = {3} lg = {3} sx = {{mt:2}}>
                <Typography>Default mark</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} sx = {{mb:2}}>
                <TextField ></TextField>
              </Grid>

              <Grid item xs = {12} sm = {12} md = {3} lg = {3} sx = {{mt:2}}>
                <Typography>Correct Answer</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} sx = {{mb:2}}>
              <RichTextbox />
              </Grid>


           </Grid>

        
        <Typography variant = "h6" sx = {{color:'text.secondary'}}>Answers :</Typography>



            <Box  sx = {{mb:3,p:2,mt: 2,border:1,borderColor:"#c0c1c4"}}>
            <Grid container columns = {12}>
              <Grid item xs = {12} sm = {12} md = {3} lg = {3} sx = {{mt:2}} >
                <Typography>Answer</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} sx = {{mb:2}}>
                <RichTextbox />
              </Grid>


            </Grid>
            </Box>

        
            
        <Grid container columns = {12} sx = {{mt:2}}>
          <Grid item xs = {12} sm = {12} md = {12} lg = {12}>
            <Stack direction = "row" justifyContent = "center" spacing = {2}>
              <Button variant = "contained" 
              sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                bgcolor: '#f95877',
                color: 'white', },}}
              
              >Save changes</Button>

<Link to="/createquiz" style={{ textDecoration: "none",color: "#000000"  }}>

              <Button variant = "contained"
              // onClick={handlecancel}

              
              sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                bgcolor: '#f95877',
                color: 'white', },}}
              >Cancel</Button>

              </Link>

            </Stack>
          </Grid>
          </Grid>

        </Grid>
        <Grid item xs = {2} sm = {2} md = {1} lg ={1}>
          {/* right */}
          </Grid>

        </Grid>
  )
}
