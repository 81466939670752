import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCourseContent } from '../../store/action';
import Loader from '../Skeleton/Loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const title = ["Learning Objective and Outcome","Project | Assignment Topic | Problem | Tasks","Guide to Conduct Physical Inventory Audits","Perform Data Analysis"]

function ContentView({coursecontent}) {


    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };


    console.log("coursecontent",coursecontent)

    if (!coursecontent) {
        return <Loader/>;
      }


  return (

    <div>
    {coursecontent && coursecontent.map((header,id) => (
        <div key = {id}>
    <Accordion expanded={expanded === id} onChange={handleChange(id)} 
    sx = {{mb:3,mt:3}}
    >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
            <Typography variant = "caption" sx={{ color: 'text.secondary',fontWeight:700 }}>{header.title}</Typography>
            </AccordionSummary>

            {header.content.blocks.map((subheader,id) =>(


            // <Link to={subheader.link} key = {id} style={{ textDecoration: "none" }}> 

                <AccordionDetails key = {id} >
                <Stack
                justifyContent="space-between"
                alignItems="center"
                direction="row"
                spacing={2}
                >
                    <li>
                <Typography variant = "caption" sx = {{color: '#afb1b3',fontWeight:800}}>
                {subheader.text}
                </Typography>
                </li>
                {/* {subheader.audio ?(<AudioPlayer/>): null} */}
                </Stack>
                </AccordionDetails>
                
        // </Link> 

                    
            ))}
            
        </Accordion>
        </div>

        ))}
        </div>

  )
}


const mapStateToProps = (state) => {
    return ( {coursecontent: state.coursecontent}) ;
  };
  
export default connect(mapStateToProps,fetchCourseContent("/contentcourse"))(ContentView);