import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Oldpayments from "./pages/Payments/payments";

import { StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./newtheme";

import Payments from "./JsComponents/Payment";
import AdminHeader from "./components/Dashboard/AdminHeader";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Courses from "./pages/Courses";
import signup from "./pages/Login/signup";
import Users from "./pages/Users";
import ProgramForm from "./components/Forms/ProgramForm";
import Home from "./JsComponents/Home";
import UserTimeline from "./JsComponents/StudentDashboard/Studenttimeline";
import { Provider } from "react-redux";
import store from "./store";
import AdminNavbar from "./components/Dashboard/AdminNavbar";
import { Box } from "@mui/material";
import PdfViewer from "./JsComponents/ViewCourse/PdfViewer";
import AudioPlayer from "./JsComponents/ViewCourse/AudioPlayer";
import CourseList from "./JsComponents/Home/CourseList";
import ViewCourse from "./JsComponents/ViewCourse";
import Quiz from "./JsComponents/Quiz";
import StudentFeedback from "./JsComponents/Feedback/StudentFeedback";
import TextViewer from "./JsComponents/ViewCourse/TextViewer";
import Mycourse from "./JsComponents/Mycourse";
import Lesson from "./JsComponents/ViewCourse/Lesson";

import PlayGround from "./JsComponents/PlayGround";
import StudentDashboard from "./JsComponents/StudentDashboard";
import MultipleChoice from "./JsComponents/CreateQuiz/MultipleChoice";
import Matching from "./JsComponents/CreateQuiz/Matching";
import Createpage from "./JsComponents/CreateQuiz/Createquepage";
import Createquizbank from "./JsComponents/CreateQuiz/Createquizbankindex";
import Longanswer from "./JsComponents/CreateQuiz/Longanswer";
import Shortanswer from "./JsComponents/CreateQuiz/Shortanswer";
import Truefalse from "./JsComponents/CreateQuiz/Truefalse";
import QuizDashboard from "./JsComponents/Quiz/QuizDashboard";
import Participant from "./JsComponents/Partcipants/index";
import Studentdetails from "./JsComponents/Partcipants/Studentdetails";
import Eventscheduler from "./JsComponents/Partcipants/Scheduler";
import InstructorHome from "./JsComponents/InstructorDashboard/InstructorHome";
import CreateCourse from "./JsComponents/CreateCourse";

import ProgramHome from "./JsComponents/Programs/ProgramHome";
import AssignCourse from "./JsComponents/AssignCourse";
import CourseForm from "./pages/CourseForm";

const routing = (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <Router>
          <Route
            exact
            path={[
              "/dashboard",
              "/mycourses",
              "/coursepreview",
              "/pdfviewer",
              "/audioplayer",
              "/quiz",
              "/timeline",
              "/textviewer",
              "/lesson",
              "/playground",
              "/studentdashboard",
              "/editcourse",
              "/courses",
              "/programs",
              "/programform",
              "/multichoice/:id",
              "/matching",
              "/createquizbank",
              "/createquestion/:id",
              "/longanswer",
              "/oldprogram",
              "/oldpayments",
              "/createcourse/:idnum",
              "/shortanswer/:id",
              "/truefalse/:id",
              "/quizdashboard",
              "/participants",
              "/Userdetails/:id",
              "/Userevents",
              "/instructordashboard",
              "/createcourse",
              "/payments",
              "/instructoreditcourse"
            ]}
            component={AdminHeader}
          />

          <Route
            exact
            path={[
              "/mycourses",
              "/coursepreview",
              "/pdfviewer",
              "/audioplayer",
              "/quiz",
              "/textviewer",
              "/lesson",
              "/playground",
              "/studentdashboard",
              "/editcourse",
              "/courses",
              "/programs",
              "/multichoice/:id",
              "/matching",
              "/createquizbank",
              "/createquestion/:id",
              "/longanswer",
              "/oldprogram",
              "/oldpayments",
              "/createcourse/:idnum",
              "/timeline",
              "/shortanswer/:id",
              "/truefalse/:id",
              "/quizdashboard",
              "/participants",
              "/Userdetails/:id",
              "/Userevents",
              "/instructordashboard",
              "/createcourse",
              "/payments",
              "/instructoreditcourse"
            ]}
            component={AdminNavbar}
          />

          <Route exact path="/" component={Login} />
          <Route exact path="/signup" component={signup} />

          <Box sx={{ ml: 13 }}>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/oldpayments" component={Oldpayments} />

            <Route exact path="/courses" component={Home} />
            {/* <Route exact path="/oldprogram" component={Programs} /> */}
            <Route exact path="/mycourses" component={Mycourse} />
            <Route exact path="/coursepreview" component={ViewCourse} />
            <Route exact path="/pdfviewer" component={PdfViewer} />
            <Route exact path="/audioplayer" component={AudioPlayer} />
            <Route exact path="/playground" component={PlayGround} />
            <Route exact path="/courselist" component={CourseList} />
            <Route exact path="/quiz" component={Quiz} />
            <Route exact path="/textviewer" component={TextViewer} />
            <Route exact path="/lesson" component={Lesson} />
            <Route exact path="/participants" component={Participant} />
            <Route exact path="/Userdetails/:id" component={Studentdetails} />
            <Route exact path="/Userevents" component={Eventscheduler} />
            <Route
              exact
              path="/instructordashboard"
              component={InstructorHome}
            />
            <Route exact path="/createcourse" component={CourseForm} />
            <Route exact path="/editcourse" component={CourseForm} />

            <Route exact path="/instructoreditcourse" component={CreateCourse} />



            {/* <Route exact path="/createcourse/:idnum" component={CreateCourse} /> */}
            <Route exact path="/payments" component={Payments} />
            <Route exact path="/programform" component={ProgramForm} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/multichoice/:id" component={MultipleChoice} />
            <Route exact path="/matching" component={Matching} />
            <Route exact path="/createquestion/:id" component={Createpage} />
            <Route exact path="/createquizbank" component={Createquizbank} />
            <Route exact path="/longanswer" component={Longanswer} />
            <Route exact path="/shortanswer/:id" component={Shortanswer} />
            <Route exact path="/truefalse/:id" component={Truefalse} />
            <Route exact path="/quizdashboard" component={QuizDashboard} />
            <Route
              exact
              path="/studentdashboard"
              component={StudentDashboard}
            />
            <Route exact path="/programs" component={ProgramHome} />
            <Route exact path="/timeline" component={UserTimeline} />
            <Route exact path="/assigncourse" component={AssignCourse} />

          </Box>
        </Router>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);
ReactDOM.render(routing, document.getElementById("root"));

serviceWorkerRegistration.unregister();

reportWebVitals();
