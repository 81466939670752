import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux'
import {  fetchInstructordashboard } from '../../store/action'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import InstructorTab from './InstructorTab';
import Loader from '../Skeleton/Loader';
import DashboardBreadcrums from '../Quiz/DashboardBreadcrums';

const Icons = [
  {
    id: 0,
    icon: <VerifiedUserIcon fontSize="large" sx = {{color:"green"}}/>,
    svg:"http://test.aitahub.com/assets/images/student/pr5.svg"
  },
  {
    id: 1,
    icon: <GppMaybeIcon fontSize="large" sx = {{color:"orange"}}/>,
    svg:"http://test.aitahub.com/assets/images/student/pr4.svg"
  },
  {
    id: 2,
    icon: <SchoolIcon fontSize="large" sx = {{color:"green"}}/>,
    svg:"http://test.aitahub.com/assets/images/programs/pr2.svg"
  },{
    id: 3,
    icon: <GroupsIcon fontSize="large"  sx = {{color:"blue"}}/>,
    svg:"	http://test.aitahub.com/assets/images/programs/cds.svg"
  }
]

  

function InstructorHome({carddata}) {

  if (!carddata) {
    return  <Loader/>
    // <h3>Loading ...</h3>;
  }

  // console.log(carddata.name)


  const breadcrums = ["home","instructordashboard"];


  return (
    
    <Box component = "div" sx = {{mt:1,mb:3}}>

      <Grid container columns = {12} sx = {{p:3}}>

      <Grid  container columns={12} sx  = {{mb:1,ml:'3rem'}}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                  }}
                >
                  <Typography
                    variant="button"
                    sx={{fontWeight:400,fontSize: '1.55rem'}}
                  >
                    Instructor
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    mb: 2,
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                  }}
                >
                  <DashboardBreadcrums pages={breadcrums} />
                </Grid>
            </Grid>
        <Grid item sm = {1} md = {.5} lg = {.5}></Grid>
        <Grid item sm = {10} md = {11} lg = {11}>{/*Content grid */}

          <Grid container columns = {12} spacing = {3} >

            {carddata.map((carddata, id)=>(

              <Grid key = {id} item xs = {12} sm = {12} md = {6} lg = {3} xl = {3}>
                <Card sx = {{p:1.5,border:"1px solid #d4dbf3",cursor:"pointer",

          ':hover': {
            bgcolor: '#fafbfd',
           },
            '&:active': {
                boxShadow: '#d4dbf3',
                // backgroundColor: '#0062cc',
                // borderColor: '#f95877',
              },
              '&:focus': {
                boxShadow: '#d4dbf3',
              },
              
              
              }} elevation = {2}>
               
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
                    <IconButton sx = {{mr:.5,ml:-.5}} ><img  src={carddata.svg} width = {"40px"} height = {"40px"} />  </IconButton>

                    <Stack direction = "column" spacing = {0} sx = {{mt:.5}}>
                    <Typography  sx = {{fontWeight:500,
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "#1f252b",
                    fontFamily: "Poppins"
    
    }}>{carddata.name}</Typography>
                    <Typography sx = {{fontWeight:800,
                    fontSize: "24px",
                    lineHeight: "28px",
                    color: "#1f252b",
                    fontFamily: "Poppins",
                    ml:.5
    
    }}>{carddata.count}</Typography>
     

                  </Stack>
                  </Box>

                </Card>

                
              </Grid>

            ))}

            <InstructorTab/>

        </Grid>

        </Grid>
          <Grid item sm = {1} md = {.5} lg = {.5}></Grid>

      </Grid>

    </Box>
  
  )
}


const mapStateToProps = (state) => {
  return{carddata:state.instructordashboard}
}
// const page = "/instructor"
export default connect(mapStateToProps,fetchInstructordashboard("/instructordashboard")) (InstructorHome)