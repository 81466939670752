import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Createquedialog from './Createquizbank';
import {getCreatequeicon} from '../../store/action'
import {getquizbankdetails,getquizbankheadcell} from '../../store/action'
import QueTable from './Quizbanktab';
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Searchbar from'../Partcipants/Searchbar';
import Sortstudent from '../Partcipants/Sortstudent';
import DashboardBreadcrums from "../Quiz/DashboardBreadcrums";
import { useLocation } from 'react-router';
import EditQuizBank from './EditQuizBank';
import { Alert, Box, Snackbar } from '@mui/material';

export default function Createquizbank() {
  const dispatch = useDispatch();
  const[pulltrigger,setpulltrigger] = React.useState(false);
  const[EditTrigger,setEditTrigger] = React.useState(false);

  const[quizbankId,setQuizbankId] = React.useState();


	const {search} = useLocation()

  const searchParams  = new URLSearchParams(search);
	const [Loadingresult,setLoadingresult] = React.useState(false)
	const quizbankIdNum = searchParams.get('id')
  console.log("parent edit triger",EditTrigger)



  const breadcrums = ["Dashboard", "Create quiz", "Quiz Bank"];

  const pull_trigger = (data) => {
    setpulltrigger(true) // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
    setTimeout(() => setpulltrigger(false), 500)
  }

  const Edit_TriggerOn = (data) => {
    setEditTrigger(true) 
  }
  const Edit_TriggerOff = (data) => {
    setEditTrigger(false) 
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setEditTrigger(false);

  };


  useEffect(() => {
    dispatch(getquizbankdetails());
    setQuizbankId(quizbankIdNum)
  }, [pull_trigger,quizbankIdNum,Edit_TriggerOn,Edit_TriggerOff]);

    return (
        <div>
            <Grid sx={{pt:'2rem',paddingLeft:'5rem',paddingRight:'3.5rem',mb:'3rem'}} spaxing={4} container column={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant = "button"        sx={{fontWeight:400,fontSize: '1.55rem'}}>Quiz Bank</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                    
                    marginBottom:'2rem',
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                    }}
                >
                <DashboardBreadcrums pages={breadcrums} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>

                {(() => {
                    if (!quizbankIdNum) {
                      return (
                        <div><Createquedialog  func={pull_trigger} /></div>
                      )
                    }  else {
                      return (
                        <div><EditQuizBank quizbankIdNum = {quizbankIdNum} edit_triggeron = {Edit_TriggerOn}
                        edit_triggeroff = {Edit_TriggerOff}
                        /></div>
                      )
                    }
                  })()}      


                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                    <Searchbar type={'Search question'} />
                </Grid>
                <Grid align="right"item lg={3} md={3} sm={12} xs={12}>
                    <Sortstudent  />
                </Grid>
                <Grid sx={{marginTop:'2rem'}}item lg={12} md={12} sm={12} xs={12}>
                  <QueTable func={pull_trigger}  />
                </Grid>
                {/* <Grid sx={{marginTop:'.5rem',alignItem:'left'}}item lg={12} md={12} sm={12} xs={12}>
                  <Selectaction />
                </Grid> */}
            </Grid>


            <Box >
                  <Snackbar anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                  }} open={EditTrigger} autoHideDuration={6000} onClose={handleAlertClose}>
                      <Alert onClose={handleAlertClose} severity = "success" sx={{ width: '100%' }}>
                          Quiz Details Updated Successfully!
                      </Alert>
                  </Snackbar>
            </Box>
        </div>
    )
}