import React from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


export default function RadioQuiz({ questNo,quickCheckId,quiz,timeEnded}) {

  const [answerId, setAnswerId] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);




  const handleChange = (event) => {
    setAnswerId(event.target.value);
    console.log('event.target.value : ' + JSON.stringify(event.target.value))
    setOpenAlert(false);
  };

  
//   const handleClick = () => {
//     setOpenAlert(true);
// };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpenAlert(false);

  };

  const createQuizMark = (questionId) => {

    if (!answerId) {
      setOpenAlert(true);
      }

    
else{

    setOpen(true);

    var data = new FormData();
    data.append('quickCheckId', quickCheckId);
    data.append('questionId', questionId);
    data.append('answerId', answerId);
    data.append('answer','');
    
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'http://127.0.0.1:5000/quizMarks',
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
    
    console.log('quickCheckId', quickCheckId);
    console.log('questionId', questionId);
    console.log('answerId', answerId);
  };
}

  return (
      <Grid container columns={12}>
        <Grid item sm={1} md={1} lg={1}>
          <Typography variant="h6">Q.{questNo}</Typography>
          {/* Question no */}
        </Grid>

        <Grid item sm={11} md={11} lg={11}>
          {/* Question content*/}
          <Box sx={{ p: 3, mb: 3, borderRadius: 2, bgcolor: "#f6f8ff" }}>
            <Stack direction="column" spacing={1}>
              <Typography variant="body1">{quiz.description}</Typography>
              <Typography variant="body1">{quiz.question}</Typography>
              <Typography variant="body2">select any one answer:</Typography>
              {quiz.image? (<Box
                component="img"
                sx={{

                  pt:2,

                  height: 233,
                  width: 350,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                alt="The house from the offer."
                src={quiz.image}
                />) :null}

            <FormControl sx = {{mt:1,p:1}}>
              {/* <FormLabel
              //  id="demo-controlled-radio-buttons-group"
               > */}
                <Typography sx = {{color:"text.secondary"}}> {quiz.anstext}</Typography>
              {/* </FormLabel> */}
              <RadioGroup
                // aria-labelledby="demo-controlled-radio-buttons-group"
                // name="controlled-radio-buttons-group"
                value={answerId}
                onChange={handleChange}
              >
                {quiz.answer.map((option, id) => (

                  <div key={id}>
                    <FormControlLabel
                      value={option.answerId}
                      control={<Radio color='primary' />}
                      label={option.answer}
                      disabled = {timeEnded | open}
                    />
                  </div>
                ))}
              </RadioGroup>
            </FormControl>

            <Button variant = "contained" disabled = {timeEnded | open}
             sx={{maxWidth: '70px', maxHeight: '35px', minWidth: '70px', minHeight: '35px',
             bgcolor:"#f95877" ,color: 'white',':hover': {
              bgcolor: '#f95877',
              color: 'white', }
             }}
             onClick = {() => createQuizMark(quiz.id)}
             >
              
              <Typography variant = "body2"  sx= {{fontSize:18,textTransform: 'none',color:"white"}}>Check</Typography>
              
              </Button>
            </Stack>

          </Box>


          {/* alert */}
          <Box >
            <Snackbar anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                  }} open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                      <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
                          Please Choose The Answer!
                      </Alert>
                  </Snackbar>
          </Box>

        </Grid>
      </Grid>
  );
}
