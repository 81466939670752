import React from "react";
import { Grid } from "@mui/material";
import ViewHeader from "./ViewHeader";
import pdf from '../../media/angular tutorial.pdf'


export default function PdfViewer() {
    return (
      <div >
        <ViewHeader/>
        <Grid container columns={12} spacing={1} sx = {{mt:5,mb:5}}>
        <Grid item md={1} lg={1}></Grid>

        <Grid item md={10} lg={10}>
          {/* video content Grid */}
          <iframe
          title="file"
          width="100%"
          height="600px"
        //   width = "1112.83px" height="612px"
          src={pdf}
        />
        </Grid>
        <Grid item md={1} lg={1}></Grid>
      </Grid>
        
      </div>
    );
  }