import * as React from "react";
import Button from "@mui/material/Button";
import  { useState } from 'react';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Fade from '@mui/material/Fade';
import CheckIcon from '@mui/icons-material/Check';
import Popper from '@mui/material/Popper';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import { Link } from "react-router-dom";
import AutoStoriesTwoToneIcon from '@mui/icons-material/AutoStoriesTwoTone';


function MyCourses() {
  const users = useSelector((state) => state.Tiles.mycourses);
  console.log("users", users);
  
  
  const [clicked, setClicked] = useState(false)
  const [value, setValue] = React.useState(4);
  const [Indexvalue,setIndexvalue] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const handleClick = (newPlacement,index,e) =>  {
    setIndexvalue(index);
    setAnchorEl(e.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  // useEffect(() => {
  //   callRestApi({page}).then( 
  //       result => setIndexvalue(result.data));
        
  // },[page])
  
  // var pagecount = Math.ceil((parseInt(Indexvalue.length) / 4)+1);
      
      
      
  
  
  const handleIconClick = () => {
         setClicked(true)
        
  }
  return (
        <div>
          
          <Popper  open={open} anchorEl={anchorEl} placement={placement} transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={50}>
                        <Card sx={{marginLeft:"auto",marginLeft:"auto",height:"auto",borderRadius:"0.5rem",width:"auto",padding:"1rem",maxWidth:"304px"}} display ="flex">
                          <Grid container sx={{width:'100%'}}  columns={12}  >
                            <Grid item lg={2} md={2} sm={2} xs={2}  sx={{paddingLeft:1,paddingTop:1}}  >
                              <img   src='https://luma-laravel.demo.frontendmatter.com/images/paths/angular_430x168.png' alt="google"  style = {{borderRadius:"0.25rem",height:'40px',width:'40px',aspectRatio:"auto 40 / 40",objectFit:'cover'}} />

                            </Grid>
                            <Grid item lg={10} md={10} sm={10} xs={10}    >
                              <Typography variant="h7" component="div" sx={{ fontSize: '1rem',fontFamily:"Exo\ 2,Helvetica Neue,Arial,sans-serif",color:"#303956",fontWeight:'500',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                                {users[Indexvalue].title} 
                              </Typography>
                              <Typography variant="h7" component="div" sx={{ fontSize: '11px',fontWeight:'bold',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                                Elijah Murray
                              </Typography>

                            </Grid>
                          </Grid>
                          <Grid container   columns={12}  >
                            <Grid item lg={12} md={12} sm={12} xs={12}    >
                              <Typography variant="h7" component="div" sx={{color:"rgba(39,44,51,.7)", fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",fontSize: '.8125rem',fontWeight:'400',flexGrow: 1 ,paddingLeft:1,paddingTop:"1rem",paddingBottom:"1rem"}}>
                                {users[Indexvalue].description} 
                              </Typography>
                              

                            </Grid>
                          </Grid>

                          
                          {users.data[Indexvalue].notes && users.data[Indexvalue].notes.map((item,index) => (
                            <Grid container columns={12}  >
                              <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                               <CheckIcon fontSize="small" sx={{marginLeft:1}} />
                              </Grid>
                              <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                                <Typography variant="h7" component="div" sx={{color:"rgba(39,44,51,.5)", fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",fontSize: '.8125rem',fontWeight:'400',flexGrow: 1}}>
                                   Fundamentals of working with Wordpress
                                </Typography>
                              </Grid>
                            </Grid>
                            
                          ))}
                          <Grid container columns={12} sx={{marginTop:"1rem"}}  >
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <Grid container columns={12}  >
                                <Grid align="Left"  item lg={2.5} md={2.5} sm={2.5} xs={2.5}> 
                                  <AccessTimeIcon fontSize="24px" sx={{marginLeft:1}} />  
                                </Grid>
                                <Grid  item lg={6} md={6} sm={6} xs={6}  >
                                  <Typography  component="div" sx={{marginTop:'.20rem', fontSize: '.8125rem',fontWeight:'400',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}}>
                                      {users[Indexvalue].duration}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container columns={12}  >
                                <Grid align="Left"  item lg={2.5} md={2.5} sm={2.5} xs={2.5}> 
                                  <PlayCircleOutlineIcon fontSize="24px" sx={{marginLeft:1}} />  
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}  >
                                  <Typography  component="div" sx={{marginTop:'.20rem', fontSize: '.8005rem',fontWeight:'400',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}}>
                                      12 Lessons
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container columns={12}  >
                                <Grid align="Left"  item lg={2.5} md={2.5} sm={2.5} xs={2.5}> 
                                  <AssessmentIcon fontSize="24px" sx={{marginLeft:1}} />  
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}  >
                                  <Typography  component="div" sx={{ marginTop:'.20rem',fontSize: '.8005rem',fontWeight:'400',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}}>
                                     Beginner
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              
                              <Button sx={{marginTop:"1rem",padding:"9px",color:"white",fontSize:".8025rem",fontWeight:"600",fontFamily:"Exo\ 2,Helvetica Neue,Arial,sans-serif",display:"inline"}}>watch trailer</Button>
                            </Grid>
                            
                           
                          </Grid>
                        </Card>
                      </Fade>
                    )}
          </Popper>
          
          <Grid  container spacing={3} columns={12}  >
            {users && users.data.map((item,index) => (
              <Grid item lg={3} md={4} sm={6} xs={6}  sx={{marginTop:2}}  >
            <Link to="/coursepreview" style={{ textDecoration: "none",color: "#000000"  }}>

                <Card 
                // onClick={(e) => {handleClick('right',index,e)}} 
                sx={{borderRadius:"0.5rem",height:'auto'}} display ="block">
                  <img   src='https://luma-laravel.demo.frontendmatter.com/images/paths/angular_430x168.png' alt="google"  style = {{height:'128px',overflow:'hidden',position:'relative',width:'100%',objectFit:'fill'}} />
                  <Grid container spacing={1} sx={{height:"auto",minHeight:"105px"}} columns={12}  >
                    <Grid align="left" sx={{MarginTop:3}} item lg={8} md={8} sm={8} xs={8}  >
                      <Typography  component="div" sx={{ fontSize: '1rem',color:'#303956',fontFamily:'Exo\ 2,Helvetica Neue,Arial,sans-serif',fontWeight:'620',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                        {item.title} 
                      </Typography>
                      <Typography variant="h7" component="div" sx={{ fontSize: '.8125rem',color:"#272c33",fontWeight:'400',fontHeight:'1.5',fontFamily:'Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                        Elijah Murray
                      </Typography>
                      <Rating
                        name="simple-controlled"
                        value='3.5'
                        sx={{paddingLeft:1.5}}
                        size="small"
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </Grid>
                    
                    <Grid align="right" sx={{paddingTop:0}} item lg={4} md={4} sm={4} xs={4}  >
                      <IconButton
                              aria-label="more"
                              id="long-button"
                              
                              aria-haspopup="true"
                              onClick={() => handleIconClick()}
                            >
                              {clicked ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
                      </IconButton>
                      
                    </Grid>
                  </Grid>
                  <Divider />
                  <div style={{paddingTop:'.5rem',paddingBottom:'.5rem',display:'flex',flexDirection:'row',justifyContent:'space-around',alignContent:'center'}}>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  
                      
                        <AccessTimeIcon   sx={{fontSize:'22px'}} />
                      
                        
                        
                    
                      <Typography  sx={{ml:'4px',fontSize: '.9225rem',fontWeight:'500',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}}  >
                          {item.duration}
                      </Typography>

                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                      
                              <AutoStoriesTwoToneIcon sx={{fontSize:'22px'}} />
                      

                    
                      <Typography   sx={{ml:'4px',fontSize: '.8205rem',fontWeight:'400',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}}>
                        12 Lessons
                      </Typography>

                   
                  </div>
                  </div>
                  
                    

                  
                </Card>
        </Link>

                
              </Grid>
              
              
            ))}
            
          </Grid>
          {/* <Grid container spacing={3} columns={12}  >
            <Grid item lg={12} md={12} sm={12} xs={12} align="left" sx={{marginTop:4}} >
              <Stack spacing={2}>
                <Pagination 
                  
                  count={pagecount} 
                  onChange={(e, value) => setPage(value)}
              
                />
              </Stack>
            </Grid>

          </Grid> */}
        </div>
          


     

    
    
        
        
      
   
      
    
  );
};

export default MyCourses;