import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {getstudentdetail} from '../../store/action';
import QueTable from './Studentstab';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Sortstudent from './Sortstudent';
import { useSelector } from "react-redux";
import Avatar from '@mui/material/Avatar';
import ParticipantTab from './Participantstab';
import Detailscard from './Deatilscard';
// const callRestApi = async ({id}) => {

//     let restEndpoint  = 'http://localhost:8000/studentlist?id='+ id;
      
//       const response = await fetch(restEndpoint);
//       const jsonResponse = await response.json();
//       // console.log(jsonResponse);
//       return jsonResponse;
//     }; 

export default function Studentdetails() {
  let { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getstudentdetail({id}));
    
  }, [id]);
  const users = useSelector((state) => state.Stulist.studentdetails);
  console.log("usersdetails",users)
  const [value, setValue] = React.useState('1');
  console.log("value",value)
  const handleChange = (event, newValue) => {
    setValue(newValue);
    
  };
    return (
        <div>
            {users && 
            <Grid sx={{p:'4rem'}} spaxing={2}  container  column={12}>
                <Grid item lg={1} md={0} sm={0} xs={0}></Grid>
                <Grid item lg={9} md={12} sm={12} xs={12}>
                    <div style={{display:'flex',flexDirection:'row'}}>
                            
                        <Avatar
                        sx={{height:'71px',width:'64px',marginBottom:'.5rem'}}
                         alt="Remy Sharp"  src={users[0].image}/>
                            
                        <div style={{display:'block'}}>
                        <Typography  sx={{alignText:"left",marginLeft:'.5rem',fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '2rem',fontWeight:"700"}}>{users[0].Name}</Typography>
                        <Stack direction="row" spacing={1}>
                        <Typography  sx={{alignText:"left",marginLeft:'.5rem',marginBottom:'1rem',fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.875rem',fontWeight:"500"}}>Userid:</Typography>
                        <Chip size="small" sx={{bgcolor:'#f95877',color:"#fff",fontWeight:500}} label={users[0].email}  />
                        </Stack>
                        </div>
                    </div>
                    
                </Grid>
                <Grid align="right" item lg={1} md={0} sm={0} xs={0}>
                    {/* <Button style={{backgroundColor:"#f95877",color:'#fff',fontWeight:600,paddingTop:'8px',paddingBottom:'8px',paddingLeft:'20px',paddingRight:'20px',marginTop:"1rem",border:'1px solid rgba(80, 72, 229, 0.5)',borderRadius:'8px'}}  >Edit</Button> */}
                </Grid>
                <Grid item lg={1} md={0} sm={0} xs={0}></Grid>
                <Grid item lg={1} md={0} sm={0} xs={0}></Grid>
                <Grid width='100%' sx={{mt:'1rem'}}item lg={10} md={12} sm={12} xs={12}>
                  <Detailscard users={users} />
                </Grid>
                
                <Grid item lg={1} md={0} sm={0} xs={0}></Grid>
                {/* <Grid sx={{marginTop:'.5rem',alignItem:'left'}}item lg={12} md={12} sm={12} xs={12}>
                  <Selectaction />
                </Grid> */}
            </Grid>
           }
        </div>
    )
}