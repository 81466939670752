import  React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { Box, Button, Card, CardMedia, Grid, IconButton, Stack } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { getAllPosts } from "../../store/action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from '@mui/material/Pagination';







function CourseList({courses}) {



  const [page, setPage] = React.useState(1);


  console.log(courses)


  if (!courses) {
    return <h3>Loading ...</h3>;
  }



  return (
    <Box sx={{ mt: 4 }}>
      <Grid width="auto" container columns={12}>
        <Grid item xs={2} sm={3} md={3} lg={3}>
          {/* left pad */}
        </Grid>
        <Grid item xs={8} sm={6} md={6} lg={6}>

          <Grid container spacing = {1} sx = {{mb:2}}>
            <Grid item xs = {12} sm = {12} md = {4} lg = {4} >

            <Typography variant = "button" sx = {{fontSize:".825rem",p:1}}>DISPLAYING 14 COURSES
            </Typography>
            </Grid>
            <Grid item xs = {12} sm = {12} md = {8} lg = {8}>

              <Stack direction = "row" spacing = {1} alignItems = "center"  justifyContent = "flex-end">
                <Typography variant = "button" sx = {{fontSize:".825rem"}}>SORT BY</Typography>
                <Button
                  id="demo-customized-button"
                  style={{border:"none",color:"black"}}
                  disableElevation
                  
                  // onClick={() => dispatch(getSortedpost({page}))}
                  endIcon={<UnfoldMoreIcon  />}
                >
                <Typography variant = "button" sx = {{fontSize:".825rem"}}>NEWEST</Typography>

                                 
              </Button>

                <Button
                  id="demo-customized-button"
                  style={{border:"none",color:"black"}}
                  disableElevation
                  
                  // onClick={() => dispatch(getSortedratingpost({page}))}
                  endIcon={<UnfoldMoreIcon  />}
                >
                <Typography variant = "button" sx = {{fontSize:".825rem"}}>POPULARITY</Typography>

                               
              </Button>

              </Stack>
            </Grid>

          </Grid>
          {/* list content grid*/}

          <List
            // sx={{ width: "100%", maxWidth: "auto",}}
          >


      {courses.map((course,id) =>(

          <Link key = {id} to="/coursepreview" style={{ textDecoration: "none",color: "#000000"  }}>
      
            <Card  elevation = {1} sx = {{mb:3}}>
              <Grid container spacing = {1} >
                <Grid item xs = {12} sm = {12} md = {5} lg = {5}>

                <CardMedia
          component="img"
          height="100%"
          image={course.image}
          alt="uh uh!"
        />
                </Grid>
                <Grid item xs = {12} sm = {12} md = {7} lg = {7}>
                  <Grid container >
                    <Grid item xs = {10} sm = {10} md = {10} lg = {10}>
                      <Stack sx = {{p:1}} spacing = {1}>
                    <Typography sx = {{fontSize:"1.2rem",color:"text.secondary"}}>{course.title}</Typography>
                    <Typography variant = "subtittle1" sx = {{fontSize:".900rem"}}>{course.preview}</Typography>

                    <Typography sx = {{fontSize:".8125rem", color:"grey"}}>{course.author}</Typography>
                    <Rating  name="size-small" defaultValue={course.rating} size="small" readOnly/>
                    </Stack>

                    </Grid>
                    <Grid item xs = {2} sm = {2} md = {2} lg = {2} >

                    <Stack justifyContent = "center" sx = {{p:1}}>
                      <IconButton sx = {{borderRadius:0}}><FavoriteBorderIcon/></IconButton>
                      </Stack>

                    </Grid>
              
                    <Grid item xs = {12} sm = {12} md = {12} lg = {12} >
                      <Divider 
                      sx = {{ml:-1}}
                      />
                    </Grid>

                    <Grid item xs = {6} sm = {6} md = {6} lg = {6} sx = {{p:1}}>
                      
                      <Stack direction = "row" spacing = {1}  >
                        <IconButton sx = {{p:0}}><AccessTimeIcon fontSize="small"/></IconButton>
                        <Typography sx = {{fontSize:".8000rem"}}>{course.duration}</Typography>
                       
                      </Stack>

                    </Grid>
                    <Grid item xs = {6} sm = {6} md = {6} lg = {6} sx = {{p:1}}>
                    <Stack direction = "row" spacing = {1}  >
                        <IconButton sx = {{p:0}}><PlayCircleOutlineIcon fontSize="small"/></IconButton>
                        <Typography sx = {{fontSize:".8000rem"}}>{course.lessons}</Typography>
                      </Stack>

                    </Grid>
                  </Grid>

                  
                </Grid>

              </Grid>
           
            </Card>
            
            </Link>

))}
            

            



          </List>


          <Grid container spacing={3} columns={12}  >
              <Grid item lg={12} md={12} sm={12} xs={12}  sx={{mb:4}} >
                  <Stack direction = "row" spacing={2} alignItems = "center"  justifyContent = "flex-end">
                      <Pagination 
                      
                      count={3} 
                      onChange={(e, value) => setPage(value)}
                  
                      />
                  </Stack>
              </Grid>
              </Grid>


        </Grid>
        <Grid item xs={2} sm={3} md={3} lg={3}>
          {/* right pad */}
        </Grid>
      </Grid>
     </Box>
  );
}


const mapStateToProps = (state) => {
  return { courses: state.Tiles.courses};
};

export default connect(mapStateToProps, getAllPosts)(CourseList);

