import classNames from "classnames";
import { Component, useState } from "react";
import { Link } from "react-router-dom";
import Service from "../../service/service";
import "../../components/Footer/Footer.scss";
import ReactPaginate from "react-paginate";
import { Modal, Button } from "react-bootstrap";
import Config from "../../components/Global";
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import { BlockUI } from 'primereact/blockui';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
export default class DashboardContent extends Component {

    state: any = {
        panelBlocked:true
    };
    name:string ="";
    props : any;
    constructor(props: any) {
        super(props);
        this.initialize();
    }

    initialize(){
        new Service().getUserByEmail(Config.getClaims().email).then(res=>{
            this.name = `${res.data.data.first_name} ${res.data.data.last_name}`;
            this.setState({panelBlocked:false});
        }).catch(error=>{
            this.setState({panelBlocked:false});
            if((error+'').includes('401'))
                this.props.history.push("/")
        })
    }

    showSideNavBar(value: any) {
        this.state.sidenavValue = value;
        this.setState(this.state);

    }
    handleModal() {
        this.setState({ show: !this.state.show })
    }
    render() {
        return (
            <span>
                <AdminNavbar menu="dashboard" />
                <div className="container tab-pad">
                    <div className="row">
                        {/* <div className="col-md-9" style={{width:"20%"}}></div> */}
                        <div className="col-12 col-md-12 col-xl-10">
                            <div className="dashboard-content">

                                <div className="d-flex align-items-center">
                                    <div className="dashboard-sidenavBtn d-md-none" onClick={() => this.showSideNavBar("show")}><span className="dashboard-sidenavBtn__item"></span><span className="dashboard-sidenavBtn__item"></span><span className="dashboard-sidenavBtn__item"></span></div>
                                    <h4 className="dashboard-content__profileName">Hello, 
                                        <span style={{color:"#fa5a78"}}>{this.name}</span>
                                    </h4>
                                </div>
                                <div className="dashboard-content__banner position-relative">
                                    <div className="col-12 col-md-8 col-lg-6 dashboard-baner-text" style={{ position: "absolute" }}>
                                        <h3 className="fnt-32 text-black fw-600">Build High Growth, High Pay Careers</h3>
                                    </div><img className="dashboard-baner-bg" src="assets/images/dash-bg.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BlockUI blocked={this.state.panelBlocked} fullScreen>
                    <Panel>
                    <div style={{textAlign:"center"}}>
                        <ProgressSpinner hidden={!this.state.panelBlocked}/>
                    </div>
                    </Panel>
                </BlockUI>
            </span>

        );
    }
}