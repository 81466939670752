import { Component, ReactNode,useRef } from "react";
import AdminHeader from "../Dashboard/AdminHeader";
import AdminNavbar from "../Dashboard/AdminNavbar";
import "./ProgramForm.scss";
import { Link } from "react-router-dom";
import Service from "../../service/service";
import React from "react";
import { type } from "os";
import Multiselect from 'multiselect-react-dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { BlockUI } from 'primereact/blockui';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import DashboardBreadcrums from "../../JsComponents/Quiz/DashboardBreadcrums";
import { Grid, Typography } from "@mui/material";

class ProgramForm extends React.Component {
    isEditForm: boolean = false;
    selectedProgramList: any = [];
    programList: any = [];
    categories: any = [];
    assets: any = [];
    state: any = {};
    courses: any = [];
    courseIds: any = [];
    selectedCourses: any = [];
    props:any;
    form : any = {
        title : {valid : false},
        price : {valid : false},
        offer_price : {valid : false},
        thumbnail : {valid : false},
        brochure : {valid : false},
        program_advice : {valid : false},
        description : {valid : false},
        course : {valid : false},
        category : {valid : false}
    };
    validForm : boolean = false;
    formSubmitted : boolean = false;
    blockedPanel : boolean = false;
    toast:any;
    constructor(props: any) {
        super(props);
        this.props = props;
        this.toast = React.createRef();
        this.onSelectCategory = this.onSelectCategory.bind(this);
        if (props.location.state != undefined) {
            this.state = props.location.state.program;
            this.isEditForm = true;
            this.selectedCourses = this.state.course_list;
            this.state.course_list.forEach((course: any) => {
                this.courseIds.push(course.id);
            })
        }
        this.getProgramGroups();
        this.getCourses();
    }

    getProgramGroups() {
        this.categories = new Service().getCategories();
    }
    getCourses() {
        new Service().getCoursesList().then((res: any) => {
            this.courses = res.data;
            this.setState(this.courses);
        });
    }

    onSelectCourse = (event: any, selectedCourse: any) => {
        this.selectedCourses.push(selectedCourse);
        this.courseIds.push(selectedCourse.id);
        this.validateForm();
        this.setState(this.selectedCourses);
    }
    onSelectCategory = (event: any) => {
        this.state.category = event.target.value;
        this.validateForm();
    }

    onChangeValue = (event: any) => {
        this.setState({ [event.target.id]: event.target.value });
        this.validateForm();
    };
    onUploadBrochure = (event: any) => {
        this.assets.push({
            type: 'brochure',
            file: event.target.files[0]
        });
        this.validateForm();
    }
    onUploadThumbnail = (event: any) => {
        this.assets.push({
            type: 'thumbnail',
            file: event.target.files[0]
        });
        this.validateForm();
    }
    onUploadAdviceDoc = (event: any) => {
        this.assets.push({
            type: 'programadvice',
            file: event.target.files[0]
        });
        this.validateForm();
    }

    onRemove = (course: any, removed: any) => {
        this.selectedCourses = course;
        this.courseIds.splice(this.courseIds.indexOf(removed.id), 1);
        this.validateForm();
        this.setState(this.selectedCourses);
    }

    cancel(){
        this.props.history.push("/programs");
    }

    saveProgram = () => {
        this.formSubmitted = true;
        if(this.validateForm()){
            this.blockedPanel = true;
            const program = {
                "id": this.state.id != undefined ? this.state.id : 0,
                "title": this.state.title,
                "description": this.state.description,
                "price": this.state.price,
                "offer_price": this.state.offer_price,
                "category": this.state.category,
                "course_list": this.courseIds,
                "thumbnail_asset_id": this.state.thumbnail_asset_id != undefined ? this.state.thumbnail_asset_id : 0,
                "brochure_asset_id": this.state.brochure_asset_id,
                "program_advice_asset_id": this.state.program_advice_asset_id,
                "is_published" : this.state.is_published ? this.state.is_published : false
            };
            if (!this.isEditForm) {
                let count = 0;
                new Service().addProgram(program).then(
                    (response: any) => {
                        if(this.assets.length == 0){
                            this.blockedPanel = false;
                            this.props.history.push("/programs");
                        }
                            
                        this.assets.forEach((asset: any,index:any) => {
                            new Service().addAsset(asset.file, response.data.id, asset.type).then((res: any) => {
                                count++;
                                if(this.assets.length == count){
                                    this.blockedPanel = false;
                                    this.props.history.push("/programs");
                                }
                            }).catch(error=>{
                                this.blockedPanel = false;
                                this.setState({isUIBlocked:this.blockedPanel});
                                this.toast.current.show({severity:'error', summary: 'Error saving the file', detail:'Something went wrong.', life: 3000});
                                console.log(error);
                            });
                        });
                    }
                ).catch(err=>{
                    this.blockedPanel = false;
                    this.setState({isUIBlocked:this.blockedPanel});
                    if((err+"").includes("409"))
                        this.toast.current.show({severity:'error', summary: 'Error saving the Program', detail:'The Program with this title already exists.', life: 3000});
                    else
                        this.toast.current.show({severity:'error', summary: 'Error saving the Program', detail:'Something went wrong.', life: 3000});
                        
                });
            } else {
                let count = 0;
                new Service().updateProgram(program).then(
                    (response: any) => {
                        if(this.assets.length == 0){
                            this.blockedPanel = false;
                            this.props.history.push("/programs");
                        }
                        this.assets.forEach((asset: any,index:any) => {
                            new Service().addAsset(asset.file, response.data.id, asset.type).then((res: any) => {
                                count++;
                                if(this.assets.length == count){
                                    this.blockedPanel = false;
                                    this.props.history.push("/programs");
                                }
                            }).catch(error=>{
                                this.blockedPanel = false;
                                this.setState({isUIBlocked:this.blockedPanel});
                                this.toast.current.show({severity:'error', summary: 'Error saving the file', detail:'Something went wrong.', life: 3000});
                            });
                        });
                    }
                ).catch(error=>{
                    this.blockedPanel = false;
                    this.setState({isUIBlocked:this.blockedPanel});
                    this.toast.current.show({severity:'error', summary: 'Error updating the program', detail:'Something went wrong.', life: 3000});
                });
            }
        }
    }

    validateForm(){
        const title = document.getElementById('title') as HTMLInputElement;
        this.form.title.valid = title.value.length < 5 ? false : true;
        const price = document.getElementById('price') as HTMLInputElement;
        this.form.price.valid = price.value.length < 3 ? false : true;
        const offer_price = document.getElementById('offer_price') as HTMLInputElement;
        this.form.offer_price.valid = offer_price.value.length < 3 ? false : true;
        const thumbnail = document.getElementById('thumbnail') as HTMLInputElement;
        this.form.thumbnail.valid = (thumbnail.value.length > 5 || this.state.thumbnail_asset_id != undefined) ? true : false;
        const brochure = document.getElementById('brochure') as HTMLInputElement;
        this.form.brochure.valid = (brochure.value.length > 5 || this.state.brochure_asset_id != undefined) ? true : false;
        const pa = document.getElementById('program_advice') as HTMLInputElement;
        this.form.program_advice.valid = (pa.value.length > 5 || this.state.program_advice_asset_id != undefined) ? true : false;
        const description = document.getElementById('description') as HTMLInputElement;
        this.form.description.valid = description.value.length < 5 ? false : true;
        this.form.course.valid = this.courseIds.length > 0 ? true : false;
        this.form.category.valid = this.state.category ? true : false; 
        this.setState({form : this.form});
        
        this.validForm = true;
        Object.values(this.form).forEach((ele:any)=>{
            if(Object.values(ele)[0] === false){
                this.validForm =  false;
                return;
            }
        });
        return this.validForm;
    }

     createBreadcrums: any= ["HOME","CREATE PROGRAM"];
     editBreadcrums: any= ["HOME","EDIT PROGRAM"];


    render() {
        return (
            <section>
                <div className="container">
                    <AdminHeader />
                    <AdminNavbar menu="programs" />
                    <div className="container" style={{marginTop:"30px"}}>
                        <div className="row">
                            <div className="header">
                                {/* <Link to={'/programs'} style={{ width: '5%', textAlign: 'center' }} >
                                    <FaArrowAltCircleLeft className="back-btn"/>
                                </Link> */}
                                <Grid width="auto" container columns={12} sx = {{mb:-1,ml:.5}} >
                                        <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        sx={{
                                            textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                                        }}
                                        >
                                        <Typography
                                            variant="button"
                                            sx={{fontWeight:400,fontSize: '1.55rem'}}
                                        >
                                            {this.isEditForm?  'Edit Program' :'Create Program'}
                                        </Typography>
                                        </Grid>
                                        <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        sx={{
                                            mb: 2,
                                            textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                                        }}
                                        >
                                            { this.isEditForm?
                                        <DashboardBreadcrums pages={this.editBreadcrums} />
                                        :
                                        <DashboardBreadcrums  pages={this.createBreadcrums}/>
                                            }
                                        </Grid>
                                </Grid>
                                {/* <h3 className="back-btn">{this.isEditForm?"Edit Program":"Add Program"}</h3> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="programForm">
                                <div className="card my-3">
                                    <div className="card-body">
                                        <h5 className="card-header" style={{ fontSize: "1rem", backgroundColor: "#fff" }}>Program Form</h5>
                                        <div className="program-form pt-4">
                                            <div className="row">
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Program Title<span style={{ color: "red" }}>*</span> </label>
                                                        <input type="text" className="form-control my-1" id="title" defaultValue={this.state.title} onChange={this.onChangeValue} />
                                                        {
                                                            (!this.form.title.valid && this.formSubmitted) &&
                                                            <span className="error">* Title is required.</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Program Price<span style={{ color: "red" }}>*</span> </label>
                                                        <input type="number" className="form-control my-1" id="price" defaultValue={this.state.price} onChange={this.onChangeValue} />
                                                        {
                                                            (!this.form.price.valid && this.formSubmitted) &&
                                                            <span className="error">* Price is required.</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Offer Price<span style={{ color: "red" }}>*</span> </label>
                                                        <input type="number" className="form-control my-1" id="offer_price" defaultValue={this.state.offer_price} onChange={this.onChangeValue} />
                                                        {
                                                            (!this.form.offer_price.valid && this.formSubmitted) &&
                                                            <span className="error">* Offer Price is required.</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Category<span style={{ color: "red" }}>*</span> </label>
                                                        <select className="dropDownElem" onChange={this.onSelectCategory} defaultValue={this.state.category}>
                                                            {this.categories.map((category: any, index: any) => {
                                                                return <option value={category.value} id='category' key={index}>{category.name}</option>
                                                            })}
                                                        </select>
                                                        {
                                                            (!this.form.category.valid && this.formSubmitted) &&
                                                            <span className="error">* Please select one category.</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-group px-3 my-2">
                                                    <label className="form-label">Courses<span style={{ color: "red" }}>*</span> </label>
                                                    <Multiselect
                                                        options={this.courses} // Options to display in the dropdown
                                                        selectedValues={this.selectedCourses} // Preselected value to persist in dropdown
                                                        onSelect={this.onSelectCourse} // Function will trigger on select event
                                                        onRemove={this.onRemove} // Function will trigger on remove event
                                                        displayValue="title"
                                                    />
                                                    {
                                                        (!this.form.course.valid && this.formSubmitted) &&
                                                        <span className="error">* Please select atleast one course.</span>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-group px-3 my-2">
                                                    <label className="form-label">Description<span style={{ color: "red" }}>*</span> </label>
                                                    <textarea className="form-control my-1" id="description" onChange={this.onChangeValue} rows={4} cols={50} defaultValue={this.state.description}></textarea>
                                                    {
                                                        (!this.form.description.valid && this.formSubmitted) &&
                                                        <span className="error">* Description is required.</span>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-group px-3 my-4"
                                                    style={{ display: "flex", flexWrap: "wrap", alignItems: "flex-end" }}>
                                                    <label className="form-label" style={{ paddingRight: "20px" }}>Publish<span style={{ color: "red" }}>*</span> </label>
                                                    <InputSwitch className="my-1" checked={this.state.is_published} id="is_published" onChange={this.onChangeValue} />
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Brochure Pdf<span style={{ color: "red" }}>*</span> </label>
                                                        <input type="file" accept="application/pdf" className="form-control my-1" id="brochure" onChange={this.onUploadBrochure} />
                                                        {
                                                            (!this.form.brochure.valid && this.formSubmitted) &&
                                                            <span className="error">* Please upload brochure file.</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Thumbnail Image<span style={{ color: "red" }}>*</span> </label>
                                                        <input type="file" accept="image/jpg, image/jpeg, image/png" className="form-control my-1" id="thumbnail" onChange={this.onUploadThumbnail} />
                                                        {
                                                            (!this.form.thumbnail.valid && this.formSubmitted) &&
                                                            <span className="error">* Please upload thumbnail.</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Program Advice<span style={{ color: "red" }}>*</span> </label>
                                                        <input type="file" accept="application/pdf" className="form-control my-1" id="program_advice" onChange={this.onUploadAdviceDoc} />
                                                        {
                                                            (!this.form.program_advice.valid && this.formSubmitted) &&
                                                            <span className="error">* Please upload program advice document.</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-start my-2">
                                                <button className="btn btn-primary mx-3 btn-save" type="button" onClick={this.saveProgram} >Save</button>
                                                <button className="btn btn-primary" onClick={() => this.cancel()}>Cancel</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BlockUI blocked={this.blockedPanel} fullScreen>
                    <Panel>
                    <div style={{textAlign:"center"}}>
                        
                    </div>
                    </Panel>
                </BlockUI>
                <ProgressSpinner hidden={!this.blockedPanel} style={{position:"absolute",top:"45%",left:"45%"}}/>
                <Toast ref={this.toast} />
            </section>
        );
    }
}
export default ProgramForm;
