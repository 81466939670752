import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid,Box, IconButton, Stack, TextField, Typography, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NotesIcon from '@mui/icons-material/Notes';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListIcon from '@mui/icons-material/List';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Tooltip from '@mui/material/Tooltip';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import axios from 'axios'
import Moment from 'moment';




const questionType = ['Does not repeat','Daily','Weekly on Monday',,'Monthly on the second Monday','Every weekday (Monday to Friday)']

export default function Reminder({selected,users}) {

  
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState();
  const [description, setDescription] = React.useState();
  const [time, setTime] = React.useState(Moment().format("HH:mm"));
  const [value, setValue] = React.useState(dayjs(new Date()));
  const [seletctVal, setSeletctVal] = React.useState("Does not repeat");
  const [openAlert, setOpenAlert] = React.useState(false);
  const [succesAlert, setSuccesAlert] = React.useState(false);



  console.log("title",title)
  console.log("description",description)
  console.log("date",Moment(value).format('YYYY-MM-DD')+" "+time);
  
  console.log("time",time)



  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpenAlert(false);
    setSuccesAlert(false);

  };
  const handleChange = (newValue) => {
    setValue(newValue);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAlert(false);



  }
  const selectHandler = (event) => {
    setSeletctVal(event.target.value);
  };



  const createEvent= () => {

    const scheduledAt = Moment(value).format('YYYY-MM-DD')+" "+time

    if ((typeof title === 'undefined') || (typeof description === 'undefined')) {
      console.log(title)
      setOpenAlert(true);
      }

    
    else{

      const email_id = []

      for (let i = 0; i < selected.length; i++) {
        const key = Object.keys(users).find(user => users[user].id === selected[i])
        const email = users[key].email
        email_id.push(email)
        }

        var data = JSON.stringify({
          "title": title,
          "scheduledAt": scheduledAt,
          "description": description,
          "instructor_id": '2583df24-8754-471f-a35c-48340f6a9b41',
          "createdBy":"jagadeeshan",
          "student_id": selected,
          "email_id":email_id
        });

        console.log("createevent",data)
        
        var config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'http://127.0.0.1:5000/event',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {

          setOpen(false);
          setSuccesAlert(true);
          setTitle()
          setDescription()
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });

        
      };
    }
  

  return (
    <div>
        <Tooltip title="Task Schedule">
          <IconButton
          onClick={handleClickOpen}
          >
            <CalendarMonthIcon />
          </IconButton>
        </Tooltip>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Reminder
      </Button> */}
      
      <Dialog
        open={open}
        onClose={handleClose}
        // aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle 
        // id="responsive-dialog-title" 
        sx = {{mt:.5,p:.5,
        // bgcolor:"rgb(241 243 244)"
        // bgcolor:"#fee7eb"

        }}>
          <Typography
          sx={{ flex: '1 1 100%',ml:3}}
          variant="h6"
          id="tableTitle"
          component="div"
        >
         Create Event
        </Typography>
          <Stack alignItems={"end"} justifyContent ={"center"} sx = {{mr:1,mt:-4.5,flex: '1 1 100%' }}>
          <IconButton onClick={handleClose}><CloseIcon /></IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent  sx = {{p:1,mr:1}}>


            <Grid container columns = {12} sx = {{mt:2,mb:2}} rowSpacing = {2} >
                <Grid item xs = {1.5} sm  = {1.5} md = {1.5} lg = {1.5} >
                    </Grid>
                    <Grid item xs = {10.5} sm  = {10.5} md = {10.5} lg = {10.5} sx = {{mb:1}}>

                        <TextField
                            id="standard-search" 
                            placeholder="Add title"
                            variant="standard"
                            fullWidth
                            multiline

                            inputProps={{
                                sx: { fontWeight:500,
                                    fontSize: "20px",
                                    lineHeight: "28px",
                                    color: "#1f252b",
                                    "&::placeholder": {
                                        color: "#000000",
                                        opacity: .7
                                        
                                    }
                                },
                              }}
                            onChange = {(e)=>{setTitle(e.target.value);setOpenAlert(false);}}
                              
                            />
                    
                    </Grid>

                    <Grid item xs = {1.5} sm  = {1.5} md = {1.5} lg = {1.5} >
                        <Stack alignItems={"center"} sx = {{mt:2}}>
                            <AccessTimeIcon
                            //  fontSize='small'
                             />
                        </Stack>
                    </Grid>
                    <Grid item xs = {10.5} sm  = {10.5} md = {10.5} lg = {10.5}>
                        <Grid container columns ={12} spacing = {2}>

                            <Grid item sm = {6} md = {6} lg ={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker 
                                    inputFormat="DD/MM/YYYY"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params) => <TextField  {...params} />}
                                    />
                                </LocalizationProvider>


                            </Grid>

                            <Grid item sm = {6} md = {6} lg ={6}>

                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            <TimePicker
                                           
                                            value={value}
                                            onChange={handleChange}
                                            renderInput={(params) => <TextField {...params}   />}
                                           
                                            />

                                     </LocalizationProvider> */}

                                     <TextField
                                            id="time"
                                            type="time"
                                            defaultValue={time}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            inputProps={{
                                            step: 300, 
                                            }}
                                            fullWidth
                                            onChange = {(e)=>{setTime(e.target.value)}}
                                            
                                        />



                            </Grid>

                        </Grid>
                    
                    </Grid>

                    <Grid item xs = {1.5} sm  = {1.5} md = {1.5} lg = {1.5} >
                        <Stack alignItems={"center"} sx = {{mt:2}}>
                        <ListIcon 
                        // fontSize='small'
                        />
                        </Stack>
                    </Grid>
                    <Grid item xs = {10.5} sm  = {10.5} md = {10.5} lg = {10.5} >

                   <FormControl sx = {{minWidth:504}} >

                <Select  value={seletctVal} onChange={selectHandler}
                    sx = {{ color: "#1f252b"}}
                    // size = "small"
                
                >
                  {questionType.map((type,id) => (
                    <MenuItem value={type} key={id} 
                    sx = {{ color: "#1f252b"}}
                    >
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
                    
                    </Grid>

                    <Grid item xs = {1.5} sm  = {1.5} md = {1.5} lg = {1.5} >
                        <Stack alignItems={"center"} sx = {{mt:2}}>
                        <NotesIcon 
                        // fontSize='small'
                        />
                        </Stack>
                    </Grid>
                    <Grid item xs = {10.5} sm  = {10.5} md = {10.5} lg = {10.5}  >

                        <TextField
                            id="filled-multiline-static"
                            multiline
                            rows={3}
                            placeholder="Add description"
                            variant="filled"
                            fullWidth

                            inputProps={{
                                style: {
                                    color: "#1f252b",
                                },
                              }}
                            onChange = {(e)=>{setDescription(e.target.value);setOpenAlert(false);}}
                            
                            />
                    
                    </Grid>


                    

            </Grid>
        </DialogContent>
        <DialogActions sx = {{p:1,mr:1}}>

          <Button onClick={createEvent} 
          size = "small"
          >
            <Typography sx = {{fontFamily:"Poppins",color:"#ffffff",textTransform: 'none'}}>Add Event</Typography>
          </Button>
          
        </DialogActions>
      </Dialog>

      {/* alert */}
      <Box >
            <Snackbar anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                  }} open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                      <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
                          Fields cannot be empty!
                      </Alert>
                  </Snackbar>

                  <Snackbar anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                  }} open={succesAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                      <Alert onClose={handleAlertClose} severity = "success" sx={{ width: '100%' }}>
                          Event Added Successfully!
                      </Alert>
                  </Snackbar>
          </Box>

    </div>
  );
}