import { Component } from "react";
import { Link } from "react-router-dom";
import Service from "../service/service";
import "../components/Footer/Footer.scss";
import ReactPaginate from "react-paginate";
import { Modal, Button } from "react-bootstrap";
import AdminNavbar from "../components/Dashboard/AdminNavbar";
import { FaSortAlphaDown,FaSortAlphaUp,FaSortAmountUp,FaSortAmountDown,FaSort } from 'react-icons/fa';
import Moment from 'react-moment';
import 'moment-timezone';
import Filter from "../components/Filter";
import { InputText } from 'primereact/inputtext';

export default class Users extends Component {

    state: any = {
        showUserModel: false,
        showCourseModel:false,
        sidenavValue: '',
        showProfile: false
    };
    userList: any = [];
    currentUsersList :any=[];
    userData: any = [];
    userId: any = [];
    programList:any=[];
    userCourse : any=[];
    perPage:number =10;
    datasOnPage : any = [];
    currentProgram:any={};
    isSorted:boolean=false;
    props:any;
    constructor(props: any) {
        super(props);
        this.props = props;
        new Service().getUserList().then(
            res => {
                this.userList = res;
                this.currentUsersList = [...this.userList];
                this.datasOnPage = [...this.currentUsersList].slice(0,this.perPage);
                this.setState(this.datasOnPage);
            }
        ).catch(err=>props.history.push("/"));
    }

    getProgramList(){
        new Service().getProgramList().then((res:any)=>{
            this.programList = res.data;
            this.validateUserRegisteredPrograms();
        }).catch(err=>this.props.history.push("/"))
    }

    validateUserRegisteredPrograms(){
        this.userData.program_list.forEach((userProgram:any)=>{
            this.programList.find((ele:any)=>{
                if(ele.title === userProgram.title){
                    ele.is_registered = true;
                }
            })
        });
    }

    getUserById(userId: any) {
        new Service().getUserById(userId).then((result) => {
            this.userData = result;
            this.setState(this.userData);
            this.showUserProfile();
        }).catch(err=>this.props.history.push("/"));
    }

    showUserDetails(user:any){
        this.userData = user;
        this.setState(this.userData);
        this.showUserProfile();
    }

    showUserProfile() {
        this.setState({ showProfile: !this.state.showProfile })
    }

    //pagination
    handlePageClick = (data: any) => {
        let selected = data.selected + 1;
        this.datasOnPage = [...this.userList].slice(this.perPage*data.selected,this.perPage*selected);
        console.log(this.datasOnPage);
        this.setState(this.datasOnPage);
    };

    initializeTable(){
        let table = {
            styleProps : {
                width : "100%",
                height : "70vh"
            },
            columnDefinition : []
        };

        this.userList.forEach((element:any) => {
            Object.values(element).forEach((ele:any)=>{
                
            });
        });
    }

    //getuserBy courseId
    // getUserByCourseId(courseid: any) {
    //     new Service().getUserByCourseId(courseid).then(
    //         result => {
    //             this.userByCourseId = result;
    //             this.setState(this.userByCourseId);
    //         }
    //     )
    // }
    /*getUserCourseById(userId: any) {
        this.userId = userId;
        new Service().getUserCourseById(userId).then((result) => {
            this.userCourse = result;
            this.setState(this.userCourse);
        })
    }*/
    showSideNavBar(value: any) {
        this.state.sidenavValue = value;
        this.setState(this.state);

    }
    handleModal(event:any) {
            this.setState(
                event === 'user' 
                ? { showUserModel: !this.state.showUserModel }
                : { showCourseModel: !this.state.showCourseModel })
    }
    handleEnrollModal() {
        this.setState({ enrollSucess: !this.state.enrollSucess })
    }
    /*assignCourseToUser(id: any) {
        new Service().assignUserCourse(id, this.userId).then(
            response => {
                new Service().getUserCourseById(this.userId).then((result) => {
                    this.userCourse = result;
                    this.setState(this.userCourse);
                })
                if (response === 200) {
                    this.handleModal();
                    this.handleEnrollModal();
                }
            }
        )
    }*/

    getCourseByProgramId(program: any) {
        new Service().getProgramById(program.id).then((res:any)=>{
            this.userCourse = res.data.data.course_list;
            this.currentProgram = program;
            this.setState(this.userCourse);
        });
    }

    enrollUserToProgram(program:any){
        var user : any = {...this.userData};
        user.program_list = [program.id];
        this.userData.program_list.forEach((ele:any)=>{user.program_list.push(ele.id)});
        new Service().updateUser(user).then((res:any)=>{
            new Service().getUserByEmail(this.userData.email).then((res:any)=>{
                this.userData = res.data.data;
                this.validateUserRegisteredPrograms();
                this.setState(this.userData);
            });
        });
        this.setState({ showUserModel: !this.state.showUserModel });
    }

    onSearch=(event:any)=>{
        this.currentUsersList = Filter.search(this.userList,event.target.value);
        this.datasOnPage = [...this.currentUsersList].slice(0,this.perPage);
        this.setState(this.datasOnPage);
    }
    // updated_course_list() {

        
    //     this.allCourseList.forEach((element1: any) => {
    //         this.userCourse.forEach((element2: any) => {
    //             if (element1.id === element2.id) {
    //                 console.log(element1.id + "  " + element2.id);
    //                 this.allCourseList.splice(element1);
    //                 // this.setState(this.allCourseList);
    //             }
    //         });
    //     });
    //     console.log(this.allCourseList);
    // }

    onSort(type:number,order:number){
        // this.isSorted = !this.isSorted;
        // this.currentUsersList = Filter.sort(type,this.userList,order);
        // this.datasOnPage = [...this.currentUsersList].slice(0,this.perPage);
        // this.setState(this.datasOnPage);
    }

    render() {
        return (
            <span>
                <AdminNavbar menu="users" />
                <div className="container tab-pad">
                    {this.state.showProfile == false &&
                        <div className="col-12 col-md-12 col-xl-12">
                            <div className="dashboard-content">
                                <div className="content-container">
                                    <div style={{width:"100%"}}>
                                        <div className="user-page-header">
                                            <h1>Users</h1>
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-search" />
                                                <InputText onChange={this.onSearch} style={{paddingTop:"5px",paddingBottom:"5px",cursor:"text"}} placeholder="Search" />
                                            </span>
                                        </div>
                                        <table className="table table-bordered text-wrap table-fixed" style={{ fontSize: "12px" }}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    S. No.
                                                </th>
                                                <th>
                                                    <div className="table-header">
                                                        Username
                                                        {/* <div>
                                                            <FaSortAlphaDown onClick={()=>this.onSort(1,3)}/>
                                                            <FaSortAlphaUp onClick={()=>this.onSort(2,3)}/>
                                                        </div> */}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="table-header">
                                                        Login ID
                                                        {/* <div>
                                                            <FaSortAlphaDown onClick={()=>this.onSort(1,7)}/>
                                                            <FaSortAlphaUp onClick={()=>this.onSort(2,7)}/>
                                                        </div> */}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="table-header">
                                                        Registered On
                                                        {/* <div>
                                                            <FaSortAmountDown onClick={()=>this.onSort(1,10)}/>
                                                            <FaSortAmountUp onClick={()=>this.onSort(2,10)}/>
                                                        </div> */}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="table-header">
                                                        Email
                                                        {/* <div>
                                                            <FaSortAlphaDown onClick={()=>this.onSort(1,1)}/>
                                                            <FaSortAlphaUp onClick={()=>this.onSort(2,1)}/>
                                                        </div> */}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="table-header">
                                                        Mobile
                                                        {/* <div>
                                                            <FaSortAmountDown onClick={()=>this.onSort(1,2)}/>
                                                            <FaSortAmountUp onClick={()=>this.onSort(2,2)}/>
                                                        </div> */}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="table-header">
                                                        Status
                                                        {/* <FaSort onClick={()=>this.onSort(this.isSorted ? 1: 2,9)}/> */}
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.datasOnPage.map((user: any, index: any) => (
                                                    <tr key={index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td className="text-wrap">
                                                            <span style={{ cursor: "pointer",color:"#fa5a78",fontWeight:"bold" }} 
                                                            onClick={() => { this.showUserDetails(user); this.getProgramList() }}>
                                                                {user.first_name}
                                                            </span></td>
                                                        <td className="text-wrap">{user.login_id}</td>
                                                        {/* <td className="text-wrap">{user.created_on}</td> */}
                                                        <td><Moment format="DD/MM/YYYY hh:mm">{user.created_on}</Moment></td>
                                                        <td className="text-wrap">{user.email}</td>
                                                        <td className="text-wrap">{user.mobile}</td>
                                                        <td className="text-wrap" style={user.is_active ? {color:"#00c04b",fontWeight:"bold"} : {color:"#fa5a78",fontWeight:"bold"}}>
                                                            {user.is_active ? "Active" : "In-Active"}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                    </div>
                                    <div style={{ width: "65%", margin: "auto" }}>
                                        <ReactPaginate
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={Math.ceil(this.currentUsersList.length/this.perPage)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            activeClassName={'activeElement'}
                                        // containerClassName={'pagination'}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.showProfile === true &&
                        <span>
                            <div style={{ display: "flow-root", marginTop: "23px", padding: "10px 66px" }}>
                                <button type="button" className="btn btn-light" style={{ backgroundColor: "lightgray", color: "black" }} onClick={() => { this.showUserProfile() }}>Back</button>

                                <button className="col-sm-2 col-lg-2 col-md-2 btn btn--primary form-submit__btn" style={{ float: "right", width: "fit-content" }} type="button"
                                    onClick={() => { this.setState({ visibleProgram: "visible" }); this.handleModal('user') }} >Enroll Program</button>
                            </div>
                            <div style={{ fontWeight: "bold", fontSize: "24px", padding: "10px 66px" }}>Profile</div>
                            <div className="row">
                                <div className="d-flex align-items-center justify-content-center col-lg-12 col-md-12 col-12" style={{ paddingLeft: "5rem" }}>
                                    <table className="table userProfileSec">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <tr>
                                                        <td>Full Name :</td>
                                                        <td className="name">{this.userData.first_name}{this.userData.last_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Login Id :</td>
                                                        <td className="loginId">{this.userData.login_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email :</td>
                                                        <td className="email">
                                                            {this.userData.email}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mobile Number :</td>
                                                        <td className="sortable_name">{this.userData.mobile}</td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td>College Name :</td>
                                                        <td className="name">{this.userData.college_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Current Education :</td>
                                                        <td className="name">{this.userData.current_education}</td>
                                                    </tr>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div style={{ fontWeight: "bold", fontSize: "24px", padding: "10px 66px" }}>Enrolled Programs</div>
                            <div className="row">
                                <div className="d-flex align-items-center justify-content-center" style={{ paddingLeft: "5rem" }}>
                                    {
                                        this.userData.program_list.length === 0 &&
                                        <p style={{ textAlign: "center" }}>No Programs Enrolled</p>
                                    }
                                    {
                                        this.userData.program_list.length != "" &&
                                        <table className="table table-bordered user-programs-list">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Enrolled On</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.userData.program_list.map((program: any,index:any) => (
                                                        <tr key={index}>
                                                            <td  className="programTitle" onClick={() =>{this.handleModal('course');this.getCourseByProgramId(program)} }>{program.title}</td>
                                                            <td>{program.description}</td>
                                                            <td>{<Moment format="DD/MM/YYYY hh:mm">{program.enrolled_on}</Moment>}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                            <Modal show={this.state.enrollSucess} onHide={() => this.handleEnrollModal()} centered size="sm" >
                                <Modal.Body>
                                    Sucessfully Program Assigned
                                </Modal.Body>
                            </Modal>
                            <Modal show={this.state.showUserModel} onHide={() => this.handleModal('user')} centered size="lg" backdrop="static" scrollable>
                                <Modal.Header className="alignImage">
                                    <div style={{ paddingLeft: "46rem" }}>
                                        <img src="assets/images/cancel.png" style={{ width: "12px", height: "12px", cursor: "pointer" }} onClick={() => this.handleModal('user')} />
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <table className="table table-bordered" style={{ border: "1px solid #000000",fontSize:"12px"}}>
                                        <thead>
                                            <tr>
                                                <th scope="col">S. No.</th>
                                                <th scope="col">Program</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">Enroll</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.programList.map((program: any, index: any) =>{ 
                                                    if(program.is_published){
                                                        return(
                                                    <tr key={index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{program.title}</td>
                                                        {/* <td>{course.enrollments[0].type}</td> */}
                                                        <td>{program.category}</td>
                                                        {/*
                                                            course.workflow_state != "unpublished" &&
                                                            <td><button className="col-sm-2 col-lg-2 col-md-2 btn btn--primary form-submit__btn" style={{ fontSize: "12px", width: "fit-content" }} type="button"
                                                                onClick={() => this.assignCourseToUser(course.id)} >Assign</button></td>
                                                        */}
                                                        {
                                                            <td>
                                                                <button disabled={(program.is_registered)} type="button" className="btn btn-light enroll-btn" style={(!program.is_registered) ? {backgroundColor : "#fa5a78", color:"#fff"} :{ backgroundColor: "lightgray", color: "black" }} onClick={()=>this.enrollUserToProgram(program)}>
                                                                    {program.is_registered ? 'Already Enrolled' : 'Enroll'}
                                                                </button>
                                                            </td>
                                                        }
                                                    </tr>
                                                )}
    })}
                                        </tbody>
                                    </table>

                                </Modal.Body>
                            </Modal>
                            <Modal show={this.state.showCourseModel} onHide={() => this.handleModal('course')} centered size="lg" backdrop="static" scrollable>
                                <Modal.Header className="alignImage">
                                    <div>Courses of <strong>{this.currentProgram.title}</strong></div>
                                    <div>
                                        <img src="assets/images/cancel.png" style={{ width: "12px", height: "12px", cursor: "pointer" }} onClick={() => this.handleModal('course')} />
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <table className="table table-bordered program-courses-list" style={{ border: "1px solid #000000" }}>
                                        <thead>
                                            <tr>
                                                <th scope="col">S. No.</th>
                                                <th scope="col">Course</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.userCourse.map((course: any, index: any) => (
                                                    <tr key={index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td><span>{course.title}</span></td>
                                                        {/* <td>{course.enrollments[0].type}</td> */}
                                                        <td>{course.description}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>

                                </Modal.Body>
                            </Modal>
                        </span>
                    }

                </div>

            </span>
        );
    }
}