import * as React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllPosts,getSortedpost,getSortedratingpost } from "../../store/action";
import CourseTiles from "./CourseTiles";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Pagination from '@mui/material/Pagination';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import DashboardBreadcrums from "../Quiz/DashboardBreadcrums";
// import "../../App.css";

function CourseHome() {
  const [Date,setDate] = React.useState("Datecreatedby");
  const [Order,setorder] = React.useState("desc");
  //   const [rowsPerPage, setRowsPerPage] = React.useState(2);  
  const breadcrums = ["HOME","COURSES"];

  
  return (
    <Box sx={{ mt: 4,ml:'5rem' ,mr:'3.42rem'}}>

    {/* <Grid  container columns={12}> */}

    <Grid  container columns={12} sx  = {{mb:0}}>
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
      }}
    >
      <Typography
        variant="button"
        // sx={{ lineHeight: 1.1, fontSize: "1.1rem", fontWeight: 548 }}
        sx={{fontWeight:400,fontSize: '1.55rem'}}
      >
        Courses
      </Typography>
    </Grid>
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        mb: 2,
        textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
      }}
    >
      <DashboardBreadcrums pages={breadcrums} />
    </Grid>
    </Grid>


      <Grid item lg={2} md={2} sm={2} xs={2}  >

      </Grid>
      <Grid item lg={8} md={8} sm={8} xs={8}  >
        <Card
        elevation="none"
        style={{
          justifyContent: "flex-start",
          minHeight:"100vh",
          alignItems: "flex-start",
          height:"auto",
          minWidth: "768px",
          maginLeft:"auto",
          maxWidth:"fit-content",
          border:"none",
          display:"flex",
          
          }}
        >
          <Grid container width="fit-content"  spacing={1} columns={12} sx={{padding: 0,mt:'.1rem'}} >
            <Grid align="left" item lg={1} md={1} sm={1} xs={12}  >
              <Typography sx={{fontSize:".8125rem",textTransform: 'uppercase',fontWeight:"400",paddingTop:0.85,fontFamily:"Exo\ 2,Helvetica Neue,Arial,sans-serif",color:"rgba(39,44,51,.5)"}}>SORT BY</Typography>
            </Grid>
            <Grid align="left" item  lg={2} md={2} sm={2} xs={2} >
              <Button
                  id="demo-customized-button"
                  style={{fontSize:".8125rem",textDecoration:"underline",fontWeight:"400",border:"none"}}
                  disableElevation
                  
                  // onClick={() => dispatch(getSortedpost({page}))}
                  endIcon={<UnfoldMoreIcon sx = {{mb:.3}} />}
                >
                  NEWEST                  
              </Button>
            </Grid>
            <Grid  align = "right" item lg={9} md={9} sm={9} xs={9}  >
            <Link to="/createcourse" style={{ textDecoration: "none",color: "#000000"  }}>
              <Button
                  id="demo-customized-button"
                  style={{fontSize:".8125rem",fontWeight:"400",border:"none"}}
                  disableElevation
                  endIcon={<AddIcon />}
                  // onClick={() => dispatch(getSortedratingpost({page}))}
                  
                >
                 Create Course                  
              </Button>
            </Link>
            
            </Grid>
            {/* <Grid align="right" item  lg={2} md={2} sm={2} xs={2} >
            
            </Grid> */}
            {/* <Grid item lg={1} md={1} sm={1} xs={1}>
              <p1>{"POPULARITY"}</p1>
            </Grid>    */}
            {/* <div sx={{display:'flex',justifyContent: 'space-between'}}> */}
            {/* <Grid item lg={6} md={6} sm={6} xs={6} align="left" sx={{marginTop:2}}  >
              <p1>{"COURSES"}</p1>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} align="right"  >
            <Link to="/createcourse" style={{ textDecoration: "none",color: "#000000"  }}>
              <Button
                  id="demo-customized-button"
                  style={{fontSize:".8125rem",fontWeight:"400",border:"none"}}
                  disableElevation
                  endIcon={<AddIcon />}
                  // onClick={() => dispatch(getSortedratingpost({page}))}
                  
                >
                 Create Course                  
              </Button>
            </Link>
            </Grid> */}
            {/* </div> */}
            <Grid sx={{justifyContent:"flex-start",minWidth:'768px',alignContent:'space-around'}} item lg={12} md={12} sm={12} xs={12}   >
            <CourseTiles />
            </Grid>
            
          </Grid> 
          
          
        </Card>

      </Grid>
      <Grid item  lg={2} md={2} sm={2} xs={2}   >

      </Grid>
    {/* </Grid> */}

    </Box>


  )
    
}

export default CourseHome;