import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function DashboardBreadcrums({ pages }) {
  return (
    <div>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {pages.map((pages, id) => (
            <Link key={id} underline="none" 
            // color="inherit"
             to={"/" + pages.toLowerCase()} style={{ textDecoration: "none",color: "grey"  }}>
              <Typography variant="button">{pages}</Typography>
            </Link>
          ))}
        </Breadcrumbs>
      </div>
    </div>
  );
}
