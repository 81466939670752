import { Avatar, Box, Button, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import {coursePreview} from "../../store/action"
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import Rating from '@mui/material/Rating';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import {Link} from "react-router-dom";
import AudioPlayer from "./AudioPlayer"
import Feedback from "../Feedback";
import ContentView from "./ContentView";
import axios from 'axios';
import { useHistory } from 'react-router-dom';




const commonStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.primary',
    m: 0,
    border: 1,
    width: '3.5rem',
    height: '3.5rem',
  };
  

const CoursePreview = ({ course }) => {
    const history = useHistory();



    const [expanded, setExpanded] = React.useState(false);



    const userId = '1f25b73b-c690-4d27-80b8-9a40d2965734'
    const quizId = 'f8f798bb-bfda-4784-896e-2ce25b43fd19'

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const createQuickCheck = () =>{
        
        var data = new FormData();
        data.append('userId', userId);
        data.append('quizId', quizId);

        var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://127.0.0.1:5000/quickCheck',
        data : data
        };

        axios(config)
        .then(function (response) {
        history.push("/quiz?quickCheckId="+response.data.quickCheckId+"&quizId="+quizId);
        console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
        console.log(error);
        });


    }

  if (!course) {
    return <h3>Loading ...</h3>;
  }

  console.log(course.tableofcontent)

  return (
    <Box >
        <Box 
        sx = {{bgcolor:"#fee7eb",p:1}}
        >
            {/* course header pannel*/}
        <Grid container columns = {12} sx = {{mt:8,mb:3}} >
            <Grid item sm = {1} md = {1} lg = {1}></Grid>
            <Grid item sm = {7} md = {7} lg = {7} >{/* content grid */}

                <Typography variant = "h1" color='#000000' sx = {{fontSize:"3.70rem",fontWeight:"600",lineHeight:"1.25"}}>
                {course.name}
                </Typography>
                <Typography variant = "subtitle1" color = '#00000080' sx = {{mt:1,fontSize:"1.2rem"}}>
                    {course.description}</Typography>


            </Grid>
            <Grid item sm = {4} md = {4} lg = {4}></Grid>
            <Grid item sm = {1} md = {1} lg = {1}></Grid>

            <Grid item sm = {11} md = {11} lg = {11}>


            <Stack direction = {"row"} spacing = {2} sx = {{mt:2}}>
            <Link to="/lesson" style={{ textDecoration: "none",color: "#f95877"  }}>

                    <Button variant = "contained" 
                    
                    sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', },}}
                    
                    endIcon={<PlayCircleOutlineOutlinedIcon />}
                    
                    >
                        Watch Trailer

                    </Button>
                    </Link>

                <Button  variant = "contained" 
                    
                    sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', },}}
                    
                    >
                        Start your free trial</Button>


                <Link to="/playground" style={{ textDecoration: "none",color: "#ffffff"  }}>

                    <Button variant = "contained" 
                    sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', },}}
                    
                     >PlayGround</Button>
                </Link>
                <Link to="/participants" style={{ textDecoration: "none",color: "#ffffff"  }}>

                    <Button variant = "contained" 
                    sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', },}}
                    
                     >Participants</Button>
                </Link>
                <Link to="/createquizbank" style={{ textDecoration: "none",color: "#ffffff"  }}>

                    <Button variant = "contained" 
                    sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', },}}
                    
                     >Create quiz</Button>
                </Link>

                </Stack>
                </Grid>
            

        </Grid>
        </Box>

        
<Card elevation = {1}>
<Box sx = {{p:1}}>
<Grid container columns = {12} alignItems = "center" >
    <Grid item sm = {1} md ={1} lg = {1}></Grid>

        <Grid item sm = {2} md ={2} lg = {2} >
            <Grid container columns = {4} >

            <Grid item sm = {1} md ={1} lg = {1}>

                    <Stack direction = "row" spacing = {2}>
                    
                    <Avatar alt="Remy Sharp" src={course.authorimage} />
                    </Stack>

                    </Grid>
                    <Grid item sm = {3} md ={3} lg = {3}>
                    <Stack direction = "column" spacing = {0}>
                    
                    <Typography variant = "body1" sx = {{fontWeight:700}}>{course.author}</Typography>
                    <Typography variant = "caption">{course.profession}</Typography>

                    </Stack>

                </Grid>
        
            </Grid>
            
    </Grid>
        <Grid item sm = {1} md ={1} lg = {1}>
        <Stack direction = "row" spacing = {1} >

            <AccessTimeIcon/>
            <Typography>{course.duration}</Typography>
            </Stack>
        </Grid>
        <Grid item xs = {12} sm = {12} md ={1} lg = {1}>
        <Stack direction = "row" spacing = {1} alignContent="center">
        <InsertChartIcon/>
        <Typography>{course.type}</Typography>
        </Stack>


        </Grid>


        <Grid item sm = {5} md ={5} lg = {5}></Grid>

        <Grid item sm = {12} md ={1} lg = {1}>

        <Stack direction = "column" spacing = {1} alignItems="center">
        <Rating name="size-small" defaultValue={course.avgrating} size="medium" readOnly />
        <Typography > {course.totalrating} ratings</Typography>
        </Stack>
        </Grid>
    
        <Grid item sm = {1} md ={1} lg = {1}></Grid>

    </Grid>
    </Box>
    </Card>


<Box sx = {{mt:0.3,p:1,pt:3}}>

<Grid container columns = {12} >
    <Grid item sm = {1} md = {1} lg = {1}></Grid>
    <Grid item sm = {10} md = {10} lg = {10}>

            <Grid container columns = {12} >
                    <Grid item sm = {12} md = {4} lg = {2.5} >
                    <Typography variant = "button" sx = {{fontWeight:600,letterSpacing:2}}>table of contents
                            </Typography>
                    </Grid>
                    <Grid item sm = {12} md = {8} lg = {9.5} sx = {{mt:1.5}}>
                    <Divider sx={{border:'1px solid grey'}}/>
                    </Grid>
            </Grid>

        <Grid container columns = {12} spacing = {4} sx = {{mb:1}}>

            <Grid item sm = {12} md = {7} lg = {7}>{/* accordian */}
    <Box sx = {{mt:3}}>


    {course.tableofcontent.map((header,id) => (
        <div key = {id}>
    <Accordion expanded={expanded === id} onChange={handleChange(id)} 
    sx = {{mb:3,mt:3}}
    >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
            <Typography variant = "caption" sx={{ color: 'text.secondary',fontWeight:700 }}>{header.title}</Typography>
            </AccordionSummary>

            {header.contenttitle.map((subheader,id) =>{

                
                    if (subheader.link === "/quiz") {
                    return (
                      <div key={id} id={id+1}>
                    <AccordionDetails >
                    <Stack
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    >
                    <Typography variant = "caption" sx = {{color: '#afb1b3',fontWeight:700}}>
                    {subheader.subtitle}
                    </Typography>
                    <Button size = "small" onClick  = {createQuickCheck}>Start</Button>
                    </Stack>
                </AccordionDetails>
                <Divider/>
                      </div>
                    );
                  } 

                  


                return<Link to={subheader.link} key = {id} style={{ textDecoration: "none" }}> 

                <AccordionDetails >
                    <Stack
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    >
                    <Typography variant = "caption" sx = {{color: '#afb1b3',fontWeight:700}}>
                    {subheader.subtitle}
                    </Typography>
                    {subheader.audio ?(<AudioPlayer/>): null}
                    </Stack>
                </AccordionDetails>
                <Divider/>
        </Link> 

                    
                })}
            
        </Accordion>
        </div>

        ))}
        <ContentView/>
                </Box>
                </Grid>
                <Grid item sm = {12} md = {5} lg = {5}>
                    {/* unlock library? */}
                    <Box sx = {{mt:3}}>
                    <Card>
                    <Stack  alignItems="center" sx = {{p:2}}>
                        
                    <Box sx={{ ...commonStyles, borderRadius: '50%'}}  >
                        <Box sx = {{p:1.9}} >
                    <TimerOutlinedIcon />
                    </Box>
                    </Box>
                    </Stack>
                        <Stack direction = "column" spacing = {1} alignItems="center" sx = {{pb:1}}>
                        
                        <Typography variant = "body1" sx={{ color: 'text.secondary',fontWeight:600 }}>Unlock Library</Typography>
                        <Typography variant = "caption" sx = {{fontWeight:20}}>Get access to all videos in the library</Typography>
                        
                        <Button variant = "contained"  sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                            bgcolor: '#f95877',
                            color: 'white', },}}>
                            Buy Now - Only ₹299/mo
                        </Button>
                        



                        </Stack>
                    </Card>
                    </Box>

                </Grid>




            </Grid>
            


            <Feedback/>
        


                </Grid>
                <Grid item sm = {1} md = {1} lg = {1}></Grid>

</Grid>
    


    </Box>
    </Box>

  );
};

const mapStateToProps = (state) => {
  return ( {course: state.coursePreview}) ;
};

export default connect(mapStateToProps,coursePreview)(CoursePreview);