import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {getstudentlist,getstudentlistheadcell} from '../../store/action'
import QueTable from './Studentstab';
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Searchbar from'./Searchbar';
import Sortstudent from './Sortstudent';
import DashboardBreadcrums from '../Quiz/DashboardBreadcrums';


export default function Participant() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getstudentlist());
    dispatch(getstudentlistheadcell());
  }, []);

  const breadcrums = ["HOME","PARTICIPANTS"];

    return (
        <div>
 
          <Grid sx={{pt:'2rem',paddingLeft:'5rem',paddingRight:'3.5rem',mb:4}} spaxing={2}  container  column={12}>

            <Grid width="auto" container columns={12} sx  = {{mb:1}}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                  }}
                >
                  <Typography
                    variant="button"
                    sx={{fontWeight:400,fontSize: '1.55rem'}}
                  >
                    Participants
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    mb: 2,
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                  }}
                >
                  <DashboardBreadcrums pages={breadcrums} />
                </Grid>
            </Grid>
                {/* <Grid item lg={11} md={11} sm={12} xs={12}>
                    <Typography sx={{fontWeight:400,fontSize: '1.875rem',marginBottom:'2rem'}}>Participants</Typography>
                </Grid>
                <Grid align="right" item lg={1} md={1} sm={12} xs={12}>
                    <Button sx={{minWidth:'85px',fontWeight:'500'}} >Add</Button>
                </Grid> */}
                <Grid item lg={9} md={9} sm={12} xs={12}>
                    <Searchbar type={'Search participant'} />
                </Grid>
                <Grid align="right"item lg={3} md={3} sm={12} xs={12}>
                    <Sortstudent />
                </Grid>
                <Grid sx={{marginTop:'2rem'}}item lg={12} md={12} sm={12} xs={12}>
                  <QueTable />
                </Grid>
                {/* <Grid sx={{marginTop:'.5rem',alignItem:'left'}}item lg={12} md={12} sm={12} xs={12}>
                  <Selectaction />
                </Grid> */}
            </Grid>
        </div>
    )
}