import { Box, Button, Grid, Snackbar, Stack, TextField, Typography } from "@mui/material";
import React ,{ useEffect } from "react";
import RichTextbox from "./RichTextbox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Quizservice from "./quizservice";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Select from "@mui/material/Select";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import {
   useHistory,
   useLocation,
  Link
   } from "react-router-dom";
import { queryAllByAltText } from "@testing-library/react";

export default function Truefalse() {
  const history = useHistory();
  const location = useLocation();
  let { id } = useParams();
  const array = [
    { id: 1, name: "Tyler", age: 23 },
    { id: 2, name: "Lauren", age: 28 },
    { id: 3, name: "Nico", age: 14 }
   ];
  const [value, setValue] = React.useState(false);
  const [que,setque] = React.useState('');
  const [editque,seteditque] = React.useState('');
  const [ansid,setansid] = React.useState('');
  const [ansid2,setansid2] = React.useState('');
  const [queid,setqueid] = React.useState('');
  
  console.log("answer",value)
  const [isAddMode,setAddMode] = React.useState(true);
  const[alert,setalert] = React.useState(false);
  const[Loading,setLoading] = React.useState(false);
  const [truecorrect, settruecorrect] = React.useState(0);
  console.log("trueornot",truecorrect)
  const [score, setscore] = React.useState(0);
  const [falsecorrect, setfalsecorrect] = React.useState(0);
  const[arraylist,setarray] = React.useState(array);
  const [popup,setPopup] = React.useState(false);
  

  useEffect(() => {
    if (location.state != undefined) {
        const { questiontext } = location.state
        console.log("data",questiontext);
        setque(questiontext.question);
        console.log("question",editque);
        setqueid(questiontext.id);
        setscore(questiontext.score);
        
        setAddMode(false);
        console.log(isAddMode);
    
    // 
        // get user and set form fields
        new Quizservice().getanswerById(questiontext.id).then(user => {
          setansid(user.data[0].answerId);
          setansid2(user.data[1].answerId)
          console.log("editform",user.data[0].answerId);
          {user.data.map((type,id) => {
            if(type.correct === 1){
              setValue(type.answer);
            }
            // setansid(user.data.answerId);
            
          })}
        })
        
            // setactive(course.is_active);
            // setpublised(course.is_published);
            // setlmslink(course.lms_link);
            // setthumbnailid(course.thumbnail_asset_id);
            // const fields = ['title', 'firstName', 'lastName', 'email', 'role'];
            // fields.forEach(field => setValue(field, user[field]));
            // setUser(user);
        
    
    }
}, []);
  const Handlechange = (event) => {
    setValue(event.target.value);
    if (event.target.value === true){
      
      settruecorrect(1);
      setfalsecorrect(0);
    }
    else{
      setfalsecorrect(1);
      settruecorrect(0);
    }
    
  };

  const getrichttext = (data) => {
    console.log(data)
    setque(data) // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  }


  const handleDeleteFalse = () =>{
    setPopup(false);
   }

  const savequiz = () =>{
    setLoading(true);
    var raw = JSON.stringify({
      "quiz_id": id,
      "question": que,
      "type": "single select",
      "score": score,
      "active": 1,
      "no_correctanswers":1,
      "url":"/truefalse",
      "keywords":'',
      "answers": [
        {
          "answers": "True",
          "correct": truecorrect,
          "active": 1
        },
        {
          "answers": "False",
          "correct": falsecorrect,
          "active": 1
        }
      ]
    });
    
    if (isAddMode) {
        console.log("data",raw);
        new Quizservice().addQueans(raw).then(
            
            (response) => {
                        console.log(response);
                        setLoading(false);
                        setPopup(false);
                        setalert(true);
                        // history.push("/createquestion/"+id);
                        
                    
            }
        );
    } else {
      var editraw = JSON.stringify({
        "quiz_id": id,
        "question": que,
        "questionId":queid,
        "type": "single select",
        "score": score,
        "active": 1,
        "no_correctanswers":1,
        "url":"/truefalse",
        "keywords":'',
        "answers": [
          {
            "answerId":ansid,
            "answers": "True",
            "correct": truecorrect,
            "active": 1
          },
          {
            "answerId":ansid2,
            "answers": "False",
            "correct": falsecorrect,
            "active": 1
          }
        ]
      });
        console.log("data",editraw);
        new Quizservice().updatequestionanswer(editraw).then(
            
            (response) => {
                console.log(response);
                setLoading(false);
                setPopup(false);
                setalert(true);
                // history.push("/courses");
            }
        );
    }

   }
  // const handlecancel = (event) => {
  //   navigate("/createquiz");
  // };
  const [expanded, setExpanded] = React.useState(false);
  

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    
    <>
    <Dialog
      open={popup}
      onClose={handleDeleteFalse}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{fontSize:'.9005rem',color:'#000000'}}  id="alert-dialog-title">
        {isAddMode ? 'Are you sure you want to save the question?' : 'Are you sure you want to edit the question?'}
      </DialogTitle>
      <DialogActions>
        <Button size="small" onClick={handleDeleteFalse}>No</Button>
        <Button size="small" onClick={savequiz} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
    <div style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 999 }}>
    {isAddMode === true  && <Box >
              <Snackbar anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
              }} open={alert} autoHideDuration={6000} onClose={(e) => setalert(false)}>
                  <Alert onClose={(e) => setalert(false)} severity = "success" sx={{ width: '100%' }}>
                      Question Successfully Created!
                  </Alert>
              </Snackbar>
        </Box>}
    {isAddMode === false && <Box >
              <Snackbar anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
              }} open={alert} autoHideDuration={6000} onClose={(e) => setalert(false)}>
                  <Alert onClose={(e) => setalert(false)} severity = "success" sx={{ width: '100%' }}>
                      Question Successfully Updated!
                  </Alert>
              </Snackbar>
        </Box>  }
    </div><Dialog 
                        color="success"
                        PaperProps={{
                            style: {
                           
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            },
                        }} open={Loading} 
                        >
                        <DialogContent sx={{display:'block',justifyContent:'center',alignItem:'center'}}>
                        <CircularProgress sx={{justifyContent:'center',alignItem:'center',ml:'2rem'}} />
                        <h4 style={{color:"#fff"}}>Loading...</h4>
                        </DialogContent>
    </Dialog>
    
    <Grid container columns={12} spacing={1} sx={{ mt: 3, mb: 3 }}>
      <Grid item xs={2} sm={2} md={1} lg={1}>
        {/* left */}
      </Grid>
      <Grid item xs={8} sm={8} md={10} lg={10}>
        <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
        {isAddMode ? 'Adding a True/False question' :'Editing a True/False question' }
        </Typography>

        <Typography variant="h6" sx={{ color: "text.secondary" }}>
          General :
        </Typography>
        <Grid container columns={12} sx={{ mt: 2 }}>
          {/* <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
            <Typography>Question name</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
            <TextField fullWidth></TextField>
          </Grid> */}

          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
            <Typography>Question text</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
            <TextField multiline rows={4} fullWidth value={que} onChange={(e) => {setque(e.target.value)}}></TextField>
            {/* <RichTextbox func={getrichttext} editdata={editque} /> */}
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
            <Typography>Default mark</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
            <TextField value={score} onChange={(e) => {setscore(e.target.value)}}></TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
            <Typography>ID number</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
            <TextField></TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
            <Typography>Correct Answer</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
            <FormControl
              style={{
                minWidth: 120,
                border: "1px solid #8f959e",
                borderRadius: ".5rem",
              }}
            >
              <Select
                minWidth="100vh"
                style={{ widht: "auto", height: "calc(1.5em + 0.75rem + 2px)" }}
                id="demo-simple-select"
                value={value}
                onChange={Handlechange}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container columns={12} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Button
                variant="contained"
                onClick={(e) => setPopup(true)}
                sx={{
                  bgcolor: "#f95877",
                  color: "white",
                  ":hover": {
                    bgcolor: "#f95877",
                    color: "white",
                  },
                }}
              >
                Save
              </Button>
              <Link to="/createquiz" style={{ textDecoration: "none",color: "#000000"  }}>
              
              <Button
                variant="contained"
                // onClick={handlecancel}
                sx={{
                  bgcolor: "#f95877",
                  color: "white",
                  ":hover": {
                    bgcolor: "#f95877",
                    color: "white",
                  },
                }}
              >
                Cancel
              </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} sm={2} md={1} lg={1}>
        {/* right */}
      </Grid>
    </Grid>
    </>
  );
}
