import { Divider } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Quizservice from "./quizservice";



function ConfirmationDialogRaw(props) {
  const options = useSelector((state) => state.Icons.icons);
  const history = useHistory();
  const { onClose, value: valueProp, open,prop,scroll, ...other } = props;
  
  const [value, setValue] = React.useState(valueProp);
  const [quizbankId, setQquizbankId] = React.useState(props.quizid);
  const[userId,setUserId] = React.useState('1f25b73b-c690-4d27-80b8-9a40d2965734');
  const [title,settitle] = React.useState('');
  const [summary,setsummary] = React.useState('');
  const [score,setscore] = React.useState(0);
  const [ispublished,setispublished] = React.useState(0);
  const[startsat,setstartsat] = React.useState(dayjs(new Date()));
  const[endsat,setendsat] = React.useState(dayjs(new Date()));
  const [publised,setpublised] = React.useState(true);
  const[startsdate,setstartsdate] = React.useState(dayjs(new Date()));
  const[alert,setalert] = React.useState(false);
  const[endsdate,setendsdate] = React.useState(dayjs(new Date()));
  const[Loading,setLoading] = React.useState(false);
  const radioGroupRef = React.useRef(null);

  useEffect(() => {
    new Quizservice().getQuiz(quizbankId).then(user => {
            console.log("editquizform",user.data);
            settitle(user.data[0].title);
            setsummary(user.data[0].summary)
            setscore(user.data[0].score);
            setispublished(user.data[0].published);
            setstartsat(user.data[0].startAt);
            setendsat(user.data[0].endsAt);
            setstartsdate(moment(user.data[0].startAt).format('YYYY-MM-DD HH:mm:ss'))
            setendsdate(moment(user.data[0].endsAt).format('YYYY-MM-DD HH:mm:ss'))

    })
    
    
}, []);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
    history.push(options[value].tourl);
  };
  const handlestartsdate = (newValue) => {
        
    setstartsat(newValue);
    setstartsdate(moment(newValue.$d).format('YYYY-MM-DD HH:mm:ss'))
    
    
  };
  const handleendsdate = (newValue) => {
        
    setendsat(newValue);
    setendsdate(moment(newValue.$d).format('YYYY-MM-DD HH:mm:ss'))
    
    
  };
  const savequiz = () =>{
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("quizId",quizbankId);
    bodyFormData.append("userId",userId);
    bodyFormData.append("title",title);
    bodyFormData.append("summary",summary);
    bodyFormData.append("score",score);
    bodyFormData.append("published",ispublished);
    bodyFormData.append("startsAt",startsdate);
    bodyFormData.append("endsAt",endsdate);
    const program = {
        "title": title,
        "summary": summary,

        "type": 1,
        "score": score,
        "published": ispublished,
        "startsAt":startsdate,
        "endsAt":endsdate,
        "mark_deleted": 0,
    };
    
        console.log("data",bodyFormData);
        new Quizservice().updatequiz(bodyFormData).then(
            
            (response) => {
                      console.log(response);
                       setLoading(false)
                       setalert(true);
                       onClose();
                       history.push("/createquizbank")
                       props.edit_triggeron("edit alert is trigerred")
            }
        );
   

   }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handlePublished = (e) =>{
    if (e.target.checked === true){
      setispublished(1)
    }
    else{
      setispublished(0)

    }

    setpublised(e.target.checked)



 }


  return (
    <>
    <div>
    {/* <div style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 999 }}>
    {alert ? <Alert autoHideDuration={6000} onClose={(e) => setalert(false)} severity="success">
              <AlertTitle>Success</AlertTitle>
              Quiz updated succesfully<strong>check it out!</strong>
    </Alert> :null }
    </div> */}
    <Dialog 
                        color="success"
                        PaperProps={{
                            style: {
                           
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            },
                        }} open={Loading} 
                        >
                        <DialogContent sx={{display:'block',justifyContent:'center',alignItem:'center'}}>
                        <CircularProgress sx={{justifyContent:'center',alignItem:'center',ml:'2rem'}} />
                        <h4 style={{color:"#fff"}}>Loading...</h4>
                        </DialogContent>
    </Dialog>
    </div>
    <Dialog
      
      
      disableBackdropClick
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight:'auto'} ,fontFamily:"Helvetica Neue,Helvetica,Arial,sans-serif",color:"#336",fontSize: '.9375rem',fontWeight:"400",textAlign:"left"}}
      maxWidth="sm"
      PaperProps={{style: {backgroundColor: 'transparent'}}}
      
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    > 
      <div >
      <DialogTitle sx={{minHeight: '3rem',color:"#336",backgroundColor:'#fff',fontSize:'1.5rem',display:'flex',padding:'1rem 1rem',borderBottom:"1px solid #dee2e6"}}>Edit Quiz Bank</DialogTitle>
      
      <Grid  container column={12}>
        <Grid  item lg={12} md={12} sm={12} xs={12}>
            <DialogContent  style={{minHeight:"auto",backgroundColor:"#fff"}}  dividers>
            
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container columns={12} >
                            <Grid item sm={5} md={5} lg={5} >
                                <Typography variant="button" sx={{ fontWeight: 600, letterSpacing: 2 }}>BASIC INFORMATION
                                </Typography>
                            </Grid>
                            <Grid item sm={9} md={9} lg={9} sx={{ mt: 1.5, pl: 2 }}>
                                <Divider />
                            </Grid>
                        </Grid>
                        <div style={{ marginBottom: "2rem" }}>
                            <Typography variant="overline" display="block" mb={1} mt={2} sx={{ color: 'text.secondary', fontWeight: 700 }}>
                                QUIZ TITLE
                            </Typography>
                            <TextField fullWidth id='quizTitle' value={title} onChange={(e) => {settitle(e.target.value)}}  helperText="Please see our course title guideline" />
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                            <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 700 }} display="block" mb={1} mt={2}>
                                SUMMARY
                            </Typography>
                            <TextField
                                multiline
                                rows={3}
                                value={summary}
                                onChange={(e) => {setsummary(e.target.value)}}
                                fullWidth
                                id="outlined-multiline-static"
                                helperText="Shortly describe this quiz."
                            />
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                            <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 700 }} display="block" mb={1} mt={2}>
                                SCORE
                            </Typography>
                            <TextField
                                
                                value={score}
                                onChange={(e) => {setscore(e.target.value)}}
                                
                                id="outlined-multiline-static"
                                helperText="describe this duration."
                            />
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                        <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 700 }} display="block" mb={1} mt={2}>
                                STARTS AT
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                            
                            inputFormat="DD/MM/YYYY"
                            
                            value={startsat}
                            onChange={handlestartsdate}
                            renderInput={(params) => <TextField helperText="describe this starts date."  {...params} />}
                            /> 
                        </LocalizationProvider>
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                        <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 700 }} display="block" mb={1} mt={2}>
                                ENDS AT
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                            
                            inputFormat="DD/MM/YYYY"s
                            
                            value={endsat}
                            onChange={handleendsdate}
                            renderInput={(params) => <TextField helperText="describe this ends date."  {...params} />}
                            /> 
                        </LocalizationProvider>
                        </div>
                        <div  style={{ marginBottom: "2rem",display:'flex' }}>
                            <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 700 }} display="block" mt={.5} >
                                PUBLISHED
                            </Typography>
                            <Switch
                            checked={ispublished === 1 ? true : false}
                            onChange={handlePublished}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </Box>
                
            </DialogContent>    

        </Grid>
        
      </Grid>
      </div>
      <DialogActions sx={{backgroundColor:"#fff",borderTop:'1px solid grey'}}>
        <Button onClick ={savequiz} sx={{backgroundColor:"#f95877",border:"1px solid transparent",borderRadius:".5rem",marginLeft:'.5rem',marginRight:'.5rem',color:'white'}} autoFocus>
          Update
        </Button>
        <Button sx={{backgroundColor:"#f95877",border:"1px solid transparent",borderRadius:".5rem",marginLeft:'.5rem',marginRight:'.5rem',color:'white'}} onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    
    </Dialog>


    </>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function Createquedialog(prop) {

  const history = useHistory();

  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState('');
  const [quizid,setQuizId] = useState(prop.quizbankIdNum)

  // const edit_triggeron = prop.edit_triggeron

  prop.edit_triggeroff("edit close alert is trigerred")


  const handleClose = (newValue) => {
    setOpen(false);
    history.push("/createquizbank")


    if (newValue) {
      setValue(newValue);
    }
  };


  return (
    
    <>
    <Box  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} >
    <Button
              
              sx={{ backgroundColor: "#f95877" }}
          >
              Create a new question bank
          </Button>


      </Box>
      <Box>
              <ConfirmationDialogRaw
                  prop = {prop}
                  value={value}
                  keepMounted
                  open={open}
                  onClose={handleClose} 
                  quizid = {quizid}
                  edit_triggeron = {prop.edit_triggeron}

                  
                  />
      </Box>
      </>
  );
}