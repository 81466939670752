import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { PublicClientApplication, BrowserAuthError, InteractionRequiredAuthError, SilentRequest } from "@azure/msal-browser";
import { MSAL_CONFIG } from "../../azure/azure-authentication-config";
import Config from "../../components/Global";

interface IProps { }

type HomeProps = IProps & RouteComponentProps;

interface IState { }

export default class Header extends React.Component<HomeProps, IState> {
  constructor(props: any) {
    super(props);
    this.onAccessToken = this.onAccessToken.bind(this);
  }
  myMSALObj: PublicClientApplication = new PublicClientApplication(
    MSAL_CONFIG
  );
  accessTokenRequest: SilentRequest = {
    scopes: ["user.read"],
    account: undefined
  };
  onAccessToken(accessTokenResponse: any) {
    Config.setToken(accessTokenResponse);
    this.props.history.push("/dashboard");
  }
  showLogin() {

    this.myMSALObj.acquireTokenSilent(this.accessTokenRequest).then(this.onAccessToken).catch((error) => {
      console.log("Silent failed : " + JSON.stringify(error));

      if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
        this.myMSALObj.acquireTokenPopup(this.accessTokenRequest).then(this.onAccessToken).catch(function (error) {
          console.log(error);
        });
      }

    });
  }
  render() {
    return (
      <section className="py-4 py-lg-5 pad">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7 col-xl-8">
              <div className="login-section">
                <div className="login-form custom">
                  <ul className="login-slider login-pad mb-0">
                    <li className="login-slider__item login-form__signup form-section col-12" id="login">
                      <div className="log-head">
                        <h1 className="fnt-32 fw-700 text-2424 mb-2">Log in</h1>
                        <h5 className="fnt-16 fw-500 text-2a2a mb-0">Login to continue</h5>
                      </div>
                      <div className="logn-form">
                        <div className="validate-form mb-3 mb-md-4">
                          <div className="form-required">
                            <div className="form-fields">
                              <p className="mt-2 text-9797 fnt-12 fw-400">Please use your aitahub.com as login email id</p>
                            </div>
                          </div>
                          <div className="form-submit">
                            <Link to="/"><button className="btn btn--primary form-submit__btn js-main-login js-next" type="button" id="authenticationButton"onClick={() => this.showLogin()}>Login</button></Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}