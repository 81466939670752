import React from 'react';

import StudentFeedback from "./StudentFeedback";
import useFetch from "./useFetch";

const Home = () => {
  const { error, isPending, data: StudentFeedbackData } = useFetch('http://localhost:8000/StudentFeedbackData')


  return (
    <div className="home">
      { error && <div>{ error }</div> }
      { isPending && <div>Loading...</div> }
      { StudentFeedbackData && <StudentFeedback StudentFeedbackData={StudentFeedbackData} /> }
    </div>
  );
}
 
export default Home;
