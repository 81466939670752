import React from 'react'
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
// import { useState } from 'react';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';

const commonStyles = {
    bgcolor: "background.paper",
    color:"black",
    borderColor: "#D3D3D3",
    m: 0,
    border: 1.5,
    width: "2.5rem",
    height: "3rem",
  };

  

export default function QuizNavigation ({quiz,questnavId}) {

    // const[open,setOpen] = useState(false)

  return (
    <Grid container columns = {12}>
        
            <Grid item sm = {12} md = {12} lg = {12} sx={{ pl: 2, pr: 2 }} position = "fixed">

            {/* <IconButton onClick = {()=>{setOpen(open?false:true)}}>
                <MenuIcon/>
            </IconButton> */}
            
            {/* {open?  */}
            <Card elevation={1} sx={{ bgcolor: "#fee7eb", p: 2 }} >
          <Typography variant = "h6" sx = {{pb:1}}>Quiz Navigation</Typography>

            <Grid container spacing={2} columns={12} >
              {quiz.map((quiz, id ) => (
                //  <li key={i} id={i+1}>{item.no} {item.name}</li>
                <Grid item md={2} key={id}>
                  <Grid item xs={1} sm={1} md={2}>
                  {/* <Link to={questnavId+"#"+(id+1)} smooth style={{ textDecoration: 'none' }}> */}
                    
                    <Box sx={{ ...commonStyles, borderRadius: 1 }}>
                      <Box sx={{ p: 1.5 }}>
                          {id+1}
                      </Box>
                    </Box>
                    {/* </Link> */}

                  </Grid>
                </Grid>
              ))}
            </Grid>

              <Stack direction = "column" spacing = {2} sx = {{mt:2}}>
            <Link to = "#" style={{ textDecoration: 'none'}}>Finish atempt...</Link>
            <Button variant = "contained"  sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', },}}> 
            <Typography style={{ textTransform: 'none',color:"white"}}> Start a new Preview</Typography>
            
            </Button>

            </Stack>
            
          </Card> 
          {/* :null} */}

              
            </Grid>
          </Grid>
  )
}

  

