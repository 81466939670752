import React from "react";
import { connect } from "react-redux";
import {coursePreview} from "../../store/action"
import ReactPlayer from "react-player";
import { Avatar, Box, Button, Card, Grid, Link, Stack, Typography, } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import Rating from '@mui/material/Rating';
import ViewHeader from "./ViewHeader";




const  Lesson = ({course}) =>{

  const topnav = ["Video","Downloads" ,"Notes" ,"Discussions"]

  if (!course) {
    return <h3>uh oh!</h3>;
  }

  return (
    <Box sx={{mb:5}}>
      <ViewHeader/>

      <Grid container columns={12} spacing={1} >
        <Grid item md={1} lg={1}></Grid>

        <Grid item md={10} lg={10} sx = {{mt:4,ml:-4}}> {/* video content Grid */}
          
          <Grid container columns = {12}>


            <Grid item sm={12}  md={12} lg={12}>
              <ReactPlayer
                width="1112.83px"
                height="612px"
                url="https://youtu.be/0eWrpsCLMJQ"
                controls
              />
            </Grid>

          <Grid container  sx = {{mt:"32px",mb:"32px"}}>
              <Grid item sm = {8} md = {8} lg = {8} >{/*video below content grid */}

                <Typography variant = "h1" color='#000000' sx = {{fontSize:"2.527rem",fontWeight:"700",lineHeight:"1.25"}}>
                Introduction to TypeScript
                </Typography>
                <Typography variant = "subtitle1" color = '#00000080' sx = {{mt:1,fontSize:"1.3rem"}}>
                JavaScript is now used to power backends, create hybrid mobile applications, architect cloud solutions, design neural networks and even control robots. Enter TypeScript: a superset of JavaScript for scalable, secure, performant and feature-rich applications.</Typography>

                </Grid>
                  
                <Grid item sm = {4} md = {4} lg = {4}  >
                      <Stack   direction = "row"   justifyContent="flex-end">

                      <Typography variant = "h1" color='#00000070' sx = {{fontSize:"2.827rem",fontWeight:"700",lineHeight:"1.25"}} >
                        50:13
                    </Typography>
                    </Stack>
                </Grid>

                <Grid item sm = {3} md = {3} lg = {3} sx = {{mt:3}} >
                  <Button variant = "contained" 
                  sx={{ bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', } }}>
                    <Typography variant = "button">Resume Lesson</Typography>
                    </Button>


               </Grid>
                <Grid item sm = {9} md = {9} lg = {9}  ></Grid>
          </Grid>
            


          </Grid>
        </Grid>

        <Grid item sm={1}  md={1} lg={1}></Grid>
      </Grid>



{/* preofessor detail card */}
      <Card elevation = {1}>
<Box sx = {{p:1}}>
<Grid container columns = {12}  alignItems = "center">
    <Grid item sm = {1} md ={1} lg = {1}></Grid>

        <Grid item sm = {2} md ={2} lg = {2} sx = {{ml:-4}} >
            <Grid container columns = {4} >

            <Grid item sm = {1} md ={1} lg = {1}>

                    <Stack direction = "row" spacing = {2}>
                    
                    <Avatar alt="Remy Sharp" src={course.authorimage} />
                    </Stack>

                    </Grid>
                    <Grid item sm = {3} md ={3} lg = {3}>
                    <Stack direction = "column" spacing = {0}>
                    
                    <Typography variant = "body1" sx = {{fontWeight:700}}>{course.author}</Typography>
                    <Typography variant = "caption">{course.profession}</Typography>

                    </Stack>

                </Grid>
        
            </Grid>
            
    </Grid>
        <Grid item sm = {1} md ={1} lg = {1}>
        <Stack direction = "row" spacing = {1} >

            <AccessTimeIcon/>
            <Typography>{course.duration}</Typography>
            </Stack>
        </Grid>
        <Grid item xs = {12} sm = {12} md ={1} lg = {1}>
        <Stack direction = "row" spacing = {1} alignContent="center">
        <InsertChartIcon/>
        <Typography>{course.type}</Typography>
        </Stack>


        </Grid>


        <Grid item sm = {5} md ={5} lg = {5}></Grid>

        <Grid item sm = {12} md ={1} lg = {1}>

        <Stack direction = "column" spacing = {1} alignItems="center">
        <Rating name="size-small" defaultValue={course.avgrating} size="medium" readOnly />
        <Typography > {course.totalrating} ratings</Typography>
        </Stack>
        </Grid>
    
        <Grid item sm = {1} md ={1} lg = {1}></Grid>

    </Grid>
    </Box>
    </Card>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return ( {course: state.coursePreview}) ;
};

export default connect(mapStateToProps,coursePreview)(Lesson);