
export class GlobalConfig{
    private static _instance: GlobalConfig = new GlobalConfig();

   public accessToken : any = null; 
   public navbarActive : any;
   private constructor(){}
   public setToken(token:any){
    this.accessToken = token;
    sessionStorage.setItem("token",JSON.stringify(token));
    }
    
    public getToken(){
        if(this.accessToken == null){
            let token = sessionStorage.getItem("token");
            if(token != null)
                this.accessToken = JSON.parse(token);
            else{
                this.accessToken = {};
            }
        }
            
       return this.accessToken.idToken;
   }

   public getClaims(){
        if(this.accessToken == null){
            let token = sessionStorage.getItem("token");
            if(token != null)
                this.accessToken = JSON.parse(token);
            else{
                this.accessToken = {};
            }
        }
            
        return this.accessToken.idTokenClaims;
    }

   public static get Instance(){
       return this._instance || (this._instance = new this());
   }
}
let Config : GlobalConfig = GlobalConfig.Instance;
export default Config
