import React from "react";
import "../../components/Header/Header.scss";
import "../../styles/Link.scss";
import "./AdminHeader.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { FaUserFriends,FaPollH,FaStream,FaMoneyCheckAlt } from 'react-icons/fa';

import { Box, Icon, IconButton } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import SearchIcon from '@mui/icons-material/Search';
import SchoolIcon from '@mui/icons-material/School';

import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReportIcon from '@mui/icons-material/Report';

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AssessmentIcon from '@mui/icons-material/Assessment';


interface Props {
  menu: string
}
export default class AdminNavbar extends React.Component<Props> {
  state: any = {
    active: ""
  };
  constructor(props: any) {
    super(props);
    this.state.active = this.props.menu;
  }
  showSideNavBar(value: any) {
    this.state.sidenavValue = value;
    this.setState(this.state);

  }
  setActive(value: any) {
    this.state.active = value;
    this.setState(this.state);
  }
  render() {
    return (
      <main>
        <div className={classNames("col-12 col-md-2 col-xl-1 sidenav-dashboard scroll-container ps", { "active": this.state.sidenavValue === "show" })} style={{ height: "962.81px" ,marginTop:"-9px",padding:"0px"}}>
          <p className="float-right ml-auto fnt-14 fw-600 mb-0 js-close-dashboardNav d-md-none" onClick={() => this.showSideNavBar("hide")}>close</p>

          <ul className="sidenav-list">

            <li className={classNames("sidenav-list__item", { "active": this.state.active === "dashboard" })}>
              <div>
                <Link style={{ color: "#000000" }} to="/dashboard">
                  <div className="list-head disabledropdown" onClick={() => { this.setState({ active: "dashboard" }) }}>
                    <div className="icon-div">
                    <IconButton className="ic-icon-svg" ><DashboardIcon /></IconButton>
                      {/* <img className="ic-icon-svg" src="assets/images/navbar/dashboard.svg" /> */}
                    </div>
                    <h6 className="list-head__title text2424  mb-0" style={{ fontSize: "11px", textAlign: "center",paddingTop:"5px" }}>Dashboard</h6>
                  </div>
                </Link>
              </div>
            </li>

            <li className={classNames("sidenav-list__item", { "active": this.state.active === "programs" })}>
              <div>
                <Link style={{ color: "#000000" }} to="/programs">
                  <div className="list-head disabledropdown" onClick={() => { this.setState({ active: "programs" }) }}>
                    <div className="icon-div">
                      {/* <FaPollH className="menu-icon"/> */}
                      <IconButton className="ic-icon-svg"><LocalLibraryIcon /></IconButton>

                    </div>
                    <h6 className="list-head__title text2424  mb-0" style={{ fontSize: "11px", textAlign: "center",paddingTop:"5px" }}>Programs</h6>
                  </div>
                </Link>
              </div>
            </li>

            <li className={classNames("sidenav-list__item", { "active": this.state.active === "users" })}>
            <div>

            <Link style={{ color: "#000000" }} to="/participants">

                <div className="list-head disabledropdown" onClick={() => { this.setState({ active: "users" }) }}>
                  
                  <div className="icon-div">
                    {/* <FaUserFriends className="menu-icon"/> */}
                    <IconButton className="ic-icon-svg"><PeopleIcon /></IconButton>
                  </div>
                  <h6 className="list-head__title text2424  mb-0" style={{ fontSize: "11px", textAlign: "center",paddingTop:"5px" }}>Participants</h6>

                </div>
              </Link>
              </div>


            </li>

            <li className={classNames("sidenav-list__item", { "active": this.state.active === "courses" })}>
              <div>
              <Link style={{ color: "#000000" }} to="/courses"> 
              <div className="list-head disabledropdown" onClick={() => { this.setState({ active: "courses" }) }}>
                <div className="icon-div">
                  {/* <FaStream className="menu-icon"/> */}
                  <IconButton className="ic-icon-svg"><ViewListIcon /></IconButton>
                </div>
                  <h6 className="list-head__title text2424  mb-0" style={{ fontSize: "11px", textAlign: "center",paddingTop:"5px" }}>Courses</h6>
                </div>
                </Link>
              </div>
            </li>

            {/* <li className={classNames("sidenav-list__item", { "active": this.state.active === "reports" })}>
              <div className="list-head  disabledropdown" onClick={() => { this.setState({ active: "reports" }) }}>
                <div className="icon-div">
                  <img className="ic-icon-svg" src="assets/images/navbar/reports.svg" />
                  <IconButton className="ic-icon-svg"><DashboardIcon /></IconButton>
                </div>
                <h6 className="list-head__title text2424  mb-0" style={{ fontSize: "11px", textAlign: "center",paddingTop:"5px" }}>Reports</h6>
              </div>
            </li> */}

            <li className={classNames("sidenav-list__item", { "active": this.state.active === "Calender" })}>
              <div className="list-head  disabledropdown" onClick={() => { this.setState({ active: "Calender" }) }}>
              <Link style={{ color: "#000000" }} to="/instructordashboard"> 

                <div className="icon-div">
                  {/* <img className="ic-icon-svg" src="assets/images/navbar/calender.svg" /> */}
                  <IconButton className="ic-icon-svg"><SchoolIcon /></IconButton>
                </div>
                <h6 className="list-head__title text2424  mb-0" style={{ fontSize: "11px", textAlign: "center",paddingTop:"5px" }}>Instructor</h6>
                </Link>
              </div>
            </li>

            <li className={classNames("sidenav-list__item", { "active": this.state.active === "payments" })}>
              <div className="list-head  disabledropdown" onClick={() => { this.setState({ active: "payments" }) }}>
                <Link style={{ color: "#000000" }} to="/payments"> 
                  <div className="icon-div">
                    {/* <FaMoneyCheckAlt className="menu-icon"/> */}
                    <IconButton className="ic-icon-svg"><CurrencyRupeeIcon /></IconButton>
                  </div>
                  <h6 className="list-head__title text2424  mb-0" style={{ fontSize: "11px", textAlign: "center",paddingTop:"5px" }}>Payments</h6>
                </Link>
              </div>
            </li>
          </ul>

          <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
            <div className="ps__thumb-x" tabIndex={0} style={{ left: "0px", width: "0px" }}>
            </div>
          </div>
          <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
            <div className="ps__thumb-y" tabIndex={0} style={{ top: "0px", height: "0px" }}>
            </div>
          </div>

        </div>
      </main>
    );
  }
}
