import * as React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllPublished,getPublishSortedpost,getPublishSortedratingpost } from "../../store/action";
import PublishedTiles from "./PublishedTiles";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Pagination from '@mui/material/Pagination';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
// import "../../App.css";

function PublishedHome() {
  const [page, setPage] = React.useState(1);
  const [Date,setDate] = React.useState("Datecreatedby");
  const [Order,setorder] = React.useState("desc");
  //   const [rowsPerPage, setRowsPerPage] = React.useState(2);  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getAllPublished({page}));
  }, [page]);

  
  return (
    // <Grid width="auto"container columns={12} 
    // >
    //   <Grid item lg={1} md={1} sm={1} xs={1}  >

    //   </Grid>
    //   <Grid item lg={10} md={10} sm={10} xs={10}  >
        <Card
        elevation="none"
        style={{
          justifyContent: "flex-start",
          minHeight:"100vh",
          alignItems: "flex-start",
          height:"auto",
          minWidth: "768px",
          maginLeft:"auto",
          maxWidth:"fit-content",
          border:"none",
          display:"flex",
          
          }}
        >
          <Grid container width="fit-content"  spacing={1} columns={12} sx={{padding: 0}} >
            <Grid item lg={9} md={9} sm={9} xs={12}  >
            <Typography sx={{fontSize:".8225rem",fontWeight:500,textTransform: 'uppercase',paddingTop:0.85,fontFamily:"Exo\ 2,Helvetica Neue,Arial,sans-serif",color:"rgba(39,44,51,.5)"}}>PUBLISED COURSES</Typography>
            </Grid>
            <Grid align="right" item lg={1} md={1} sm={1} xs={12}  >
              <Typography sx={{fontSize:".8125rem",textTransform: 'uppercase',fontWeight:"400",paddingTop:0.85,fontFamily:"Exo\ 2,Helvetica Neue,Arial,sans-serif",color:"rgba(39,44,51,.5)"}}>SORT BY</Typography>
            </Grid>
            <Grid align="right" item  lg={2} md={2} sm={2} xs={2} >
              <Button
                  id="demo-customized-button"
                  style={{fontSize:".8125rem",textDecoration:"underline",fontWeight:"400",border:"none"}}
                  disableElevation
                  
                  onClick={() => dispatch(getPublishSortedpost({page}))}
                  endIcon={<UnfoldMoreIcon  />}
                >
                  NEWEST                  
              </Button>
            </Grid>
            {/* <Grid align="right" item  lg={2} md={2} sm={2} xs={2} >
              <Button
                  id="demo-customized-button"
                  style={{fontSize:".8125rem",textDecoration:"underline",fontWeight:"400",border:"none"}}
                  disableElevation
                  
                  onClick={() => dispatch(getPublishSortedratingpost({page}))}
                  endIcon={<UnfoldMoreIcon  />}
                >
                  POPULARITY                  
              </Button>
            </Grid> */}
            {/* <Grid item lg={1} md={1} sm={1} xs={1}>
              <p1>{"POPULARITY"}</p1>
            </Grid>    */}
            {/* <Grid item lg={12} md={12} sm={12} xs={12} align="left" sx={{marginTop:2}}  >
              <p1>{"PUBLISHED COURSES"}</p1>
            </Grid> */}
            <Grid sx={{justifyContent:"flex-start",minWidth:'768px',alignContent:'space-around'}} item lg={12} md={12} sm={12} xs={12}   >
            <PublishedTiles />
            </Grid>
            <Grid container spacing={3} columns={12}  >
              <Grid item lg={12} md={12} sm={12} xs={12} align="left" sx={{marginTop:4}} >
                  <Stack spacing={2}>
                      <Pagination 
                      
                      count={3} 
                      onChange={(e, value) => setPage(value)}
                  
                      />
                  </Stack>
              </Grid>

          </Grid> 
          </Grid> 
          
          
        </Card>

    //   </Grid>
    //   <Grid item  lg={1} md={1} sm={1} xs={1}   >

    //   </Grid>
    // </Grid>

  )
    
}

export default PublishedHome;