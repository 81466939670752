import React, { useState } from "react";
import { Box, Button, Grid, Stack } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import Home from "./CourseHome";
import CourseListHome from "./CourseList";
import CourseHome from "./CourseHome";
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import AdminHeader from "../../components/Dashboard/AdminHeader";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const [gridOpen, SetGridOpen] = useState(true);
  // const [listOpen , SetListOpen] = useState(false)

  return (
    <div>
      {/* <AdminHeader />
      <AdminNavbar menu="programs" /> */}
      <CourseHome/>
      
      {/* <Grid  container columns={12} spacing={2} sx = {{pl:3,mt:1}}>
        <Grid item sm={1} md={1} lg={1}></Grid>
        <Grid item sm={10} md={10} lg={10}>
          <Stack direction = "row" spacing = {2}>
          <Link to="/" style={{ textDecoration: "none",color: "#000000"  }}>

          <Button
            startIcon={<GridViewIcon />}
            onClick={(e) => {
              SetGridOpen(true);
            }}
            variant="contained"
            sx={{ bgcolor: "#f95877" }}
          >
            Grid
          </Button>
          </Link>

          <Link to="/courselist" style={{ textDecoration: "none",color: "#000000"  }}>

          <Button
            startIcon={<ListIcon />}
            onClick={(e) => {
              SetGridOpen(false);
            }}
            variant="contained"
            sx={{ bgcolor: "#f95877" }}
          >
            List
          </Button>
          </Link>
          </Stack>



        </Grid>

        <Grid item sm={1} md={1} lg={1}></Grid>

      </Grid>
      {gridOpen ?<CourseHome /> : null} */}

    </div>
  );
}
