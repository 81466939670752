import React from 'react'
import {  Box, Button, Card, CardContent, Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { fetchQuiz } from '../../store/action'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Avatar from '@mui/material/Avatar';
import Pagination from '@mui/material/Pagination';
import DashboardBreadcrums from './DashboardBreadcrums';






const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "4px",
  margin:0,
  padding:0,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === 'light' ? '#5567ff' : '#5567ff',
  },
}));

const QuizDashboard = ({quizdata}) => {
  const breadcrums = ["HOME","STUDENT","QUIZZES"]

  const [value,setValue] = React.useState('')
  console.log(value)


    if (!quizdata){
        return (
            <h1>Loading...</h1>
        )
    }

  return (
    <Box >

      <Grid container sx = {{mt:3,mb:3}}>
        <Grid item xs = {2} sm = {2} md = {1} lg = {1}></Grid>
        <Grid item xs = {8} sm = {8} md = {10} lg = {10}>

          <Grid item xs = {12} sm = {12} md = {12} lg = {12} sx = {{textAlign:{xs:"center",sm:"center",md :"left",lg:"left"}}}>
            <Typography variant = "button" sx = {{lineHeight: 1.5,fontSize: '1.55rem',fontWeight: 600}}>Quizzes</Typography>
          </Grid>
          <Grid item xs = {12} sm = {12} md = {12} lg = {12} sx = {{mb:3,textAlign:{xs:"center",sm:"center",md :"left",lg:"left"}}}>
            <DashboardBreadcrums pages = {breadcrums}/>
          </Grid>
          <Grid container rowSpacing={3} columnSpacing = {3}>

            

          {quizdata.map((quizdata,id) => (
            <Grid key = {id} item xs = {12} sm = {12} md = {6} lg = {6}>
              <Card sx = {{borderRadius:".5rem",border:"1px grey"}} elevation={1}>


            <CardContent sx = {{p:0,mb:-3}}>
              <Grid container  sx = {{p:1,mb:-.5}}>
                    <Grid item xs = {3} sm = {3} md = {3} lg = {2} >
                      {/* <Box sx = {{mr:3}}> */}
                          <img src={quizdata.image} alt="aitahub"  width={64}  height = {48}
                           style = {{borderRadius:'0.25rem'}}/>
                          {/* </Box> */}
                    </Grid>
                            
                    <Grid item xs = {7} sm = {7} md = {7} lg = {8} >
                      
                        <Typography sx = {{fontSize: "1rem",fontWeight: 550,color:"text.secondary"}} >{quizdata.name}</Typography>
                        <Typography sx = {{fontSize: '.8125rem',fontWeight: 400}} >{quizdata.date}</Typography>
                    </Grid>
                                                
                    <Grid item xs = {2} sm = {2} md = {2} lg = {2} >
                        <Stack  justifyContent={"flex-end"} >
                        <Typography  textAlign={"right"} variant = "button" 
                        sx = {{fontSize: '.9140625rem',fontWeight: 550,color:"text.secondary",mr:1.5}}
                        >{quizdata.score}</Typography>
                        <Typography textAlign={"right"} variant = "button"
                        sx = {{fontSize: '.8125rem',fontWeight: 400,lineHeight: 1.5}}

                        >score</Typography>
                        </Stack>
                      </Grid>

                </Grid>

                      <Grid item xs = {12} sm = {12} md = {12} lg = {12}>
                      {quizdata.progress?<BorderLinearProgress variant="determinate" value={quizdata.progress} />:
                      <Divider/>
                      }

                      </Grid>
                  <Grid container  >
                      <Grid item xs = {6} sm = {6} md = {6} lg = {6} sx = {{p:1}}>

                      {quizdata.progress?

                      <Button size="small" variant = "contained" startIcon={<RefreshIcon  sx = {{width:16,height:17}}/>}>
                            <Typography variant = "button" >{quizdata.button}
                            
                    </Typography>
                            </Button>
                            :
                            
                    <Button size="small" sx = {{pr:3}}  variant = "contained" startIcon={<PlaylistAddCheckIcon  sx = {{width:16,height:17}}/>}
                    endIcon={<Avatar sx={{ width: "16px", height: "16px",bgcolor:"white",mb:1 }}>

                      <Typography sx = {{fontWeight: 'bold',fontSize:"12px",color:"text.primary",textAlign:"center"}}>5</Typography>
                      </Avatar>}
                    
                    >
                          
                            <Typography variant = "button" >{quizdata.button}
                            
                    </Typography>
                            </Button>
                            
                            }


                        
                          
                        
                  </Grid>

                  <Grid item xs = {6} sm = {6} md = {6} lg = {6} >
                  <Stack direction = "row" justifyContent = {'end'} sx = {{mr:.5}}>
                          
                            <IconButton><MoreHorizIcon/></IconButton>
                            
                        </Stack>
                </Grid>

                </Grid>
            </CardContent>
            </Card>

            </Grid>
            
          ))}

          </Grid>


          <Stack direction = "row" spacing={2} alignItems = "center"  justifyContent = "flex-start" sx = {{mt:3,ml:-2}}>
                      <Pagination 
                      
                      count={3} 
                      onChange={(e, value) => setValue(value)}
                  
                      />
                  </Stack>
                

                            
        </Grid>
        <Grid item xs = {2} sm = {2} md = {1} lg = {1}></Grid>

      </Grid>
    </Box>
  )
}

const mapStateToProps  = (state) =>{
    return({quizdata:state.quiz})

}

export default connect(mapStateToProps,fetchQuiz("/quizdashboard"))(QuizDashboard);