import React from 'react';

import { Avatar, Grid, Link, Stack, Typography } from "@mui/material";

const topnav = ["Video","Downloads" ,"Notes" ,"Discussions"]


export default function ViewHeader() {

  return (
    <div>
       <Grid container columns={12} spacing={1} sx = {{bgcolor:"#f6f8ff",mb:1,mt:0}}>
        <Grid item md={1} lg={1}></Grid>

        <Grid item md={10} lg={10}> {/* topnav content Grid */}

        <Grid container columns = {12} sx = {{mb:1}}>
            <Grid item md={6} lg={6}>

              <Grid container columns = {12}>

                <Grid item sm = {1} md ={1} lg= {1}>

                    <Stack direction = "row" spacing = {2}>
                    
                    <Avatar alt="Remy Sharp" src="https://luma.humatheme.com/public/images/paths/angular_64x64.png" />
                    </Stack>
                  </Grid>

                  <Grid item sm ={11} md ={11} lg ={11}>

                    <Stack direction = "column" sx = {{ml:1}} spacing = {0} justifyContent="flex-start" >
                      <Typography variant = "body1" sx = {{fontWeight:700}}>Angular Fundamentals</Typography>
                      <Stack direction = "row" spacing = {1} justifyContent="flex-start" >

                        <Typography variant = "body2" sx = {{color:"#272c3380"}}>Elijah Murray</Typography>
                        <Typography variant = "subtitle2" sx = {{color: '#b6b9bd'}}>Software Engineer and Developer</Typography>
                    </Stack>
                    </Stack>  
                  </Grid>

                </Grid>


            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Stack direction = "row" spacing = {2} sx={{mr:1.5,mt:2}}  justifyContent="flex-end">
                {topnav.map((topnav,id) =>(

                  <Link href="#" key = {id} underline="none">
                  <Typography sx = {{color:"#000000"}}>{topnav}</Typography>
                  </Link>
                ))}
             
                </Stack>
            </Grid>
          </Grid>


        </Grid>
        <Grid item md={1} lg={1}></Grid>

      </Grid>

      </div>
  )
}
