import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Createquedialog from './Createque';
import {getCreatequeicon} from '../../store/action'
import {getquizbankheadcell} from '../../store/action'
import QueTable from './Quetable';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Searchbar from'../Partcipants/Searchbar';
import Sortstudent from '../Partcipants/Sortstudent';
import DashboardBreadcrums from "../Quiz/DashboardBreadcrums";

export default function Createpage() {
  const dispatch = useDispatch();
  const[pulltrigger,setpulltrigger] = React.useState(false);

  const breadcrums = ["Create quiz", "Quiz Bank"," Create Question"];
  let { id } = useParams();

  const delete_trigger = (data) => {
    setpulltrigger(true) // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  }

  useEffect(() => {
    dispatch(getCreatequeicon());

    dispatch(getquizbankheadcell());
  }, [pulltrigger]);

    return (
        <div>
            <Grid sx={{pt:'2rem',paddingLeft:'5rem',paddingRight:'3.5rem',mb:'3rem'}} spaxing={4} container column={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant = "button"        sx={{fontWeight:400,fontSize: '1.55rem'}}
                                        >Questions</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                    
                    marginBottom:'2rem',
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                    }}
                >
                <DashboardBreadcrums pages={breadcrums} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Createquedialog id={id} />
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                    <Searchbar type={'Search question'} />
                </Grid>
                <Grid align="right"item lg={3} md={3} sm={12} xs={12}>
                    <Sortstudent  />
                </Grid>
                <Grid sx={{marginTop:'2rem'}}item lg={12} md={12} sm={12} xs={12}>
                  <QueTable id={id} func={delete_trigger} />
                </Grid>
                {/* <Grid sx={{marginTop:'.5rem',alignItem:'left'}}item lg={12} md={12} sm={12} xs={12}>
                  <Selectaction />
                </Grid> */}
            </Grid>
        </div>
    )
}