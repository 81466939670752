import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { useHistory} from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SchoolIcon from '@mui/icons-material/School';
import Avatar from '@mui/material/Avatar';
import { green, pink } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { useSelector } from "react-redux";
// import SelectLabels from './Selectedit';
import Tooltip from '@mui/material/Tooltip';
import Reminder from '../../JsComponents/Reminder/index';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#308fe8' : '#308fe8',
    },
  }));

  const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#f95877' : '#000000',
    },
  }));


function EnhancedTableHead(props) {
  const headcells = useSelector((state) => state.Stulist.stulistheadcell);
  console.log("headcelss", headcells);
  const { onSelectAllClick,numSelected, rowCount, onRequestSort } =
    props;
 

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headcells && headcells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{fontSize:'18px',fontWeight:400}}
            
          >
            {headCell.label}
            
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  
  onSelectAllClick: PropTypes.func.isRequired,
  
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({selected,numSelected} ) => {

  const users = useSelector((state) => state.Stulist.stulist);

  // const { numSelected } = props;


  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h8"
          id="tableTitle"
          component="div"
        >
          0 selected
        </Typography>
      )}

      {numSelected > 0 && 
        // <Tooltip title="Task Schedule">
        //   <IconButton>
        //     <CalendarMonthIcon />
        //   </IconButton>
        // </Tooltip>
        <Reminder selected = {selected} users = {users}/>
      
        
      }
    </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

export default function QueTable() {
  const users = useSelector((state) => state.Stulist.stulist);
  console.log("users", users);
  const navigate = useHistory();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  console.log("selected",selected)



  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users && users.map((n) => n.id);
      setSelected(newSelected);
      console.log(selected);
      return;
    }
    setSelected([]);
    
  };


  const handleClick = (event, id,email) => {
    if (event.target.type){
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];
    
      


      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);

    }
    else{
      navigate.push("/Userdetails/" + id);
    }
  };
//   const Handleclickdetails = (event,id) => {
//     navigate.push("/Userdetails/" + id);
// };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    users && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar selected = {selected} numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              // order={order}
              // orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              rowCount={users && users.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {users && users.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event,row.id,row.email)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event,row.id,row.email)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        
                        scope="row"
                        padding="none"
                      >
                        <div style={{display:'flex',flexDirection:'row'}}>
                            
                        {row.image?(<Avatar alt="Remy Sharp"  src={row.image}/>):null}
                            
                            <div style={{display:'block'}}>
                            <Typography  sx={{alignText:"left",paddingLeft:'.5rem',fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"500"}}>{row.Name}</Typography>
                            <Typography  sx={{alignText:"left",paddingLeft:'.5rem',fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.875rem',fontWeight:"400"}}>{row.email}</Typography>
                            </div>
                        </div>
                      </TableCell>
                      <TableCell   align="left"
                      >
                      <div style={{display:'block',flexDirection:'column'}}>
                      <Typography  sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"500"}}>{row.Location}</Typography>
                      <Typography  sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"500"}}>{row.State}</Typography>
                      </div>
                        
                      </TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"500"}}  align="left">{row.orders}</TableCell>
                      <TableCell   align="left">
                      
                      <div style={{display:'flex',padding:'.5rem'}}>
                        
                        <BorderLinearProgress
                         sx={{marginTop:1,width:'100%',minWidth:'60%'}} variant="determinate" value={row.course_status} />
                        <Typography  sx={{alignText:"left",paddingLeft:'.5rem',fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.875rem',fontWeight:"400"}}>{row.course_status}</Typography>
                        <Typography  sx={{alignText:"left",fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.875rem',fontWeight:"400"}}>%</Typography>
                        
                                
                            
                      </div>
                      

                      </TableCell>
                      <TableCell   align="left">
                      
                      <div style={{display:'flex',padding:'.5rem'}}>
                        
                        <BorderLinearProgress2
                         sx={{marginTop:1,width:'100%',minWidth:'60%'}} variant="determinate" value={row.quiz_status} />
                        <Typography  sx={{alignText:"left",paddingLeft:'.5rem',fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.875rem',fontWeight:"400"}}>{row.quiz_status}</Typography>
                        <Typography  sx={{alignText:"left",fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.875rem',fontWeight:"400"}}>%</Typography>
                        
                                
                            
                      </div>
                      

                      </TableCell>
                      {/* <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"500"}}  align="left">{row.spent}</TableCell> */}
                      
                      
                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>

    </Box>
  );
}
