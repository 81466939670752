import * as React from 'react';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Home from './Coursesindex';
import Mycourse from "./Enrolledcourses";

export default function Detailcard({ users}) {
    return (
        <Grid container column={12}>
            <Grid  item lg={12} mg={12} sm={12} xs={12}>
            <Paper sx={{ minWidth:'fit-content',width: '100%' ,bgcolor:"rgb(255, 255, 255)",color:'rgb(18, 24, 40)',borderRadius:'8px'}}>
            <CardHeader

                sx={{padingTop:'32px',paddingBottom:'22px',borderBottom:'1px solid rgb(230, 232, 240)',paddingLeft:'24px',paddingRight:'32px',alignItem:"center"}}
                titleTypographyProps={{fontSize:'1.0909rem',fontWeight:700,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"' }}
                title="Basic Details"
                
            />
            
            <List sx={{p:'0px',m:'0px'}}>
            
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                    <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.2rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Email</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{users[0].email}</Typography>
                </div>
                </ListItemText>
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                    <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.2rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Phone</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{users[0].Phone}</Typography>
                    </div>
                </ListItemText>
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                <Typography minWidth="200px"sx={{textAlign:'left',fontSize:'1.2rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Country</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{users[0].country}</Typography>
                </div>
                </ListItemText>
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.2rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>State/Region</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{users[0].State}</Typography>
                </div>
                </ListItemText>
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.2rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Address 1</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{users[0].Address1}</Typography>
                </div>
                </ListItemText>
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.2rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Address 2</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{users[0].Address2}</Typography>
                </div>
                </ListItemText>

                
            
            
            
            
            </List>

            </Paper>
            
            <Paper sx={{marginTop:'2rem',minWidth:'fit-content', width: '100%' ,bgcolor:"rgb(255, 255, 255)",color:'rgb(18, 24, 40)',borderRadius:'8px'}}>
            <CardHeader

            sx={{padingTop:'32px',paddingBottom:'22px',borderBottom:'1px solid rgb(230, 232, 240)',paddingLeft:'24px',paddingRight:'32px',alignItem:"center"}}
            titleTypographyProps={{fontSize:'1.0909rem',fontWeight:700,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"' }}
            title="Enrolled Courses"
            
            />
            <Home />
            

        </Paper>
        </Grid>
    </Grid>
      
      
    )
}