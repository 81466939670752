import {
  Card,
  CardMedia,
  Grid,
  Stack,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { fetchStudentCourse } from "../../store/action";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

function Courses({ courses }) {
  console.log(courses);

  if (!courses) {
    return <h3>Loading ...</h3>;
  }
  return (
    <Grid container spacing={4} sx={{ mb: 4 }}>
      {courses.map((course, id) => (
        <Grid key = {id} item md={3}>
          <Card elevation={1}>
            <CardMedia
              component="img"
              height="178px"
              width="252px"
              image={course.image}
              alt="uh uh!"
            />
            <Grid
              container
              columns={12}
              sx = {{pl:1,pr:1}}
            >
              <Grid item xs = {10.5} sm = {10.5} md={10.5} lg = {10.5}>
                <Stack
                  spacing={1}
                //   justifyContent={"center"}
                //   justifyItems={"center"}
                  sx = {{mt:1.2}}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "1rem",
                      lineHeight: 1.25,
                      fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                    }}
                  >
                    {course.title}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs = {1.5} sm = {1.5} md={1.5} lg = {1.5}>
                <Stack
                  spacing={1}
                //   justifyContent={"center"}
                //   justifyItems={"center"}
                >
                  <IconButton>
                    <FavoriteBorderIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return { courses: state.studentCourse };
};

export default connect(
  mapStateToProps,
  fetchStudentCourse("coursetiles?_page=2&_limit=2")
)(Courses);
