import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { useSelector } from "react-redux";
import SelectLabels from './Selectedit';
import Tooltip from '@mui/material/Tooltip';
import { useHistory} from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Alert, Button, Dialog, DialogActions, DialogTitle, FormControl, MenuItem, Select, Snackbar } from '@mui/material';
import axios from 'axios'
import EditQuizBank from './EditQuizBank';
import Moment from 'moment';




function EnhancedTableHead(props) {
//   const headcells = useSelector((state) => state.Quizbank.quizbankheadcell);
//   console.log("headcelss", headcells);
  const { onSelectAllClick,numSelected, rowCount, onRequestSort } =
    props;
 

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {/* {headcells && headcells.map((headCell) => ( */}
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Title
            
          </TableCell>
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Edit
            
          </TableCell>
          {/* <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Summary
            
          </TableCell> */}
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Score
            
          </TableCell>
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Published
            
          </TableCell>
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Createdat
            
          </TableCell>
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Startsat
            
          </TableCell>
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Endsat
            
          </TableCell>
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Updatedat
            
          </TableCell>
        
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  
  onSelectAllClick: PropTypes.func.isRequired,
  
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({numSelected,selected,prop,selected_trigger}) => {

  const[deletepopup,setDeletepopup] = React.useState(false);
  const[alert,setAlert] = React.useState(false);



  const handleDelete = () =>{
    setDeletepopup(true);
    setAlert(false)

   }

  const handleDeleteFalse = () =>{
    setDeletepopup(false);
   }

  const deleteQuiz = () =>{

    var data = JSON.stringify({
      "quizId":selected
    });
    
    var config = {
      method: 'put',
    maxBodyLength: Infinity,
      url: 'https://event.aitahub.com/deleteQuiz',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {

      console.log(JSON.stringify(response.data));
      // navigate.push("/createquizbank")
      setDeletepopup(false)
      setAlert(true)
      selected_trigger()
      prop.func("its triggered")

    })
    .catch(function (error) {
      console.log(error);
    });


  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setAlert(false);

  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h8"
          id="tableTitle"
          component="div"
        >
          0 selected
        </Typography>
      )}

      {numSelected > 0 && 
        <Tooltip title="Delete">
          <IconButton onClick = {handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }

      <Dialog
          open={deletepopup}
          onClose={handleDeleteFalse}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{fontSize:'.9005rem',color:'#000000'}}  id="alert-dialog-title">
          <Alert severity="error">Are you sure you want to delete the quiz?</Alert>
          <Typography sx = {{pt:3,pl:7}} variant = "body2" color = "grey">Warning! Quiz cannot be undone.</Typography>
          </DialogTitle>
          <DialogActions sx = {{mr:2}}>
            <Button size="small" onClick={handleDeleteFalse}>No</Button>
            <Button size="small" onClick={deleteQuiz} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>   

        <Box >
              <Snackbar anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
              }} open={alert} autoHideDuration={6000} onClose={handleAlertClose}>
                  <Alert onClose={handleAlertClose} severity = "success" sx={{ width: '100%' }}>
                      Quiz Successfully Deleted!
                  </Alert>
              </Snackbar>
        </Box>

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function QueTable(prop) { 
  const users = useSelector((state) => state.Quebankdetails.quizbankdetails);
  const navigate = useHistory();
  const [selected, setSelected] = React.useState([]);
      
  console.log("selected",selected);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [quizbankId, setQuizBankId] = React.useState();
  const [editquizopen, setEditQuizOpen] = useState(false);
  const [age, setAge] = React.useState('');


  const selected_trigger = (data) => {
    setSelected([])
  }

  const handleChange = (event) => {
    setAge(event.target.value);
  };



  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users && users.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
    
  };

  const handleClick = (event, id,type) => {
    console.log("event",event.target)
    
    if (event.target.type === "checkbox"){
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    }
    else if (event.target.type === "button"){
      setQuizBankId(id)
      // setEditQuizOpen(true)
      navigate.push("/createquizbank?id=" + id);

    }
    else{
      navigate.push("/createquestion/" + id);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    users && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} selected = {selected} prop = {prop} selected_trigger = {selected_trigger}/>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              // order={order}
              // orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              rowCount={users && users.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {users && users.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event,row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}}
                        scope="row"
                        padding="none"
                      >
                        {row.title}
                      </TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}} align="left">
                        {/* <SelectLabels /> */}
                        <Button variant="text" size="small"  onClick={(event) => handleClick(event, row.id)}>Edit</Button>
                        {/* <FormControl sx={{ m: -2, minWidth: 80 }}>
                          <Select
                            displayEmpty
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            size="small"
                            sx={{fontFamily:'Helvetica,Arial,sans-serif',textAlign:'left',color:'black', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                            value={"Edit"}
                            onChange={handleChange}
                          > 
                            <MenuItem  value="Edit" onClick={(event) => handleClick(event, row.id,"edit")} ><em>Edit Quiz</em></MenuItem>

                            
                          </Select>
                      </FormControl> */}
                      {/* {editquizopen?<EditQuizBank quizbankId={quizbankId} editquizopen = {editquizopen}/>:null} */}
                        
                        </TableCell>
                      {/* <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}}  align="left">{row.summary}</TableCell> */}
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}}  align="left">{row.score}</TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}}  align="left">{row.published === 0?'Draft':'Published'}</TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}}  align="left">{Moment(row.createdAt).format('DD-MM-YYYY HH:mm:ss')}</TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450",padding:'15px'}} align="left">{Moment(row.startsAt).format('DD-MM-YYYY')}</TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450",padding:'15px'}} align="left">{Moment(row.endsAt).format('DD-MM-YYYY')}</TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}} align="left">{Moment(row.updatedAt).format('DD-MM-YYYY HH:mm:ss')}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>

    </Box>
  );
}
