import * as React from 'react';
import Card from '@mui/material/Card';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

export default function Searchbar({type}) {
  return (
    <Card
      component="form"
      sx={{ marginTop:"1rem" ,display: 'flex', width: '100vx' }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1,fontSize:'16px',fontWeight:300 }}
        placeholder = {type}
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      
    </Card>
  );
}