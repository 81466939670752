import axios from "axios";
export default class Quizservice {

  
  async addQuiz(data:any){
    const response = await axios({
      url: 'https://event.aitahub.com/quiz',
      method: 'POST',
      data: data,
      headers: { "Content-Type": "multipart/form-data" }
    })
    return response.data;

  }

  async addQueans(data:any){
    const response = await axios({
      url: 'https://event.aitahub.com/questionanswer',
      method: 'POST',
      data: data,
      headers: { "Content-Type": "application/json"}
    })
    return response.data;

  }

  async getQuiz(quizbankId: any){
    const response = await axios({
      url: 'https://event.aitahub.com/quizByQuizId/' + quizbankId,
      method: 'GET',
      headers: { "Content-Type": "application/json" }
    })
    return response.data;

  }

  async updatequiz(data:any){
    console.log("service",data)
    const response = await axios({
      url: 'https://event.aitahub.com/quiz',
      method: 'PUT',
      data: data,
      headers: { "Content-Type": "application/json"}
    })
    return response.data;

  }

  async getanswerById(questionId: any) {
    const userById = await axios({
      url: "https://event.aitahub.com/answer/" + questionId,
      method: "GET",
      headers: { "Content-Type": "application/json"}
    });
    return userById.data;
  }

  async updatequestionanswer(data:any){
    const response = await axios({
      url: "https://event.aitahub.com/questionanswer",
      method: 'PUT',
      data: data,
      headers: { "Content-Type": "application/json"}
    })
    return response.data;

  }

  

  
}

