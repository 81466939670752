import React, { useState, useRef, useEffect } from 'react'
import {  fetchQuizData } from "../../store/action";
import { Box,  Grid, Typography,Button,Stack } from "@mui/material";
import RadioQuiz from "./RadioQuiz";
import TextBoxQuiz from "./TextBoxQuiz";
import QuizNavigation from './QuizNavigation';
import Checkbox from "./Checkbox";
import RichtextQuiz from "./Richtext";
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from 'react-router-dom';
import Quizresult from './Quizresult';


const Quiz = () => {

 
	const {search} = useLocation()
	const history = useHistory();
	const searchParams  = new URLSearchParams(search);
	const [Loadingresult,setLoadingresult] = useState(false)
	const quickCheckId = searchParams.get('quickCheckId')
	const quizId = searchParams.get('quizId')
	const page  = "/questAnsByQuiz/"+quizId
	console.log("page",page)
	const questnavId = "?quickCheckId="+quickCheckId+"&quizId="+quizId
	// = "quiz?quickCheckId=43ea8354-0afd-4971-812e-766d09b463ce&quizId=ed707df5-c1c4-44d2-b392-07173fca7cba"


	const dispatch = useDispatch();
	useEffect(() => {
	  dispatch(fetchQuizData(page));
	  
	}, [page]);

	const quiz = useSelector((state) => state.quizData);

  // We need ref in this, because we are dealing
	// with JS setInterval to keep track of it and
	// stop it when needed
	const Ref = useRef(null);

	// The state for our timer
  const [ timer, setTimer ] = useState('00:00:00');
  const [timeEnded,setTimeEnded] = useState(false)
  
//   console.log(timeEnded)


	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / 1000 / 60 / 60) % 24);
		return {
			total, hours, minutes, seconds
		};
	}


	const startTimer = (e) => {
		let { total, hours, minutes, seconds }
					= getTimeRemaining(e);
		if (total >= 0) {

			// update the timer
			// check if less than 10 then we need to
			// add '0' at the beginning of the variable
			setTimer(
				(hours > 9 ? hours : '0' + hours) + ':' +
				(minutes > 9 ? minutes : '0' + minutes) + ':'
				+ (seconds > 9 ? seconds : '0' + seconds)
			)
		}
        else{

            setTimeEnded(true)
        }
	}


	const clearTimer = (e) => {

		// If you adjust it you should also need to
		// adjust the Endtime formula we are about
		// to code next	
		setTimer('00:10:00');

		// If you try to remove this line the
		// updating of timer Variable will be
		// after 1000ms or 1sec
		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000)
		Ref.current = id;
	}

	const savequiz = () => {
        setLoadingresult(true);

	}


	const getDeadTime = () => {
		let deadline = new Date();

		// This is where you need to adjust if
		// you entend to add more time
		deadline.setSeconds(deadline.getSeconds() + 600);
		return deadline;
	}

	// We can use useEffect so that when the component
	// mount the timer will start as soon as possible

	// We put empty array to act as componentDid
	// mount only
	useEffect(() => {
		clearTimer(getDeadTime());
        
	}, []);

	// Another way to call the clearTimer() to start
	// the countdown is via action event from the
	// button first we create function to be called
	// by the button
	// const onClickReset = () => {
	// 	clearTimer(getDeadTime());
	// }


  if (!quiz) {
    return <h3>Loading quiz...</h3>;
  }

//   console.log("quiz",quiz)

  return (
	<>
    {Loadingresult && <Quizresult quickcheckid={quickCheckId} loadingresult = {Loadingresult} />}
    <Box sx={{ mt: 5 }}>

        <Grid container columns = {12}>
            <Grid item sm = {9} md = {9} lg = {9} sx = {{pl:2}} position = {"fixed"}> 
          
            <Box sx = {{ml:3,p:.5,border:3,borderColor:"#BEBEBE",bgcolor:"white"}} >
            <Typography variant = "body2" sx = {{color:"text.secondary",fontSize:"16px"}}>Time Left {timer}</Typography>
            </Box>
          
            </Grid>
            <Grid item sm = {3} md = {3} lg = {3}></Grid>
          </Grid>


      <Grid container columns={12} >
        <Grid item sm={2} md={2} lg={2}></Grid>

        <Grid item sm={7} md={7} lg={7} >
          
          {quiz.data.map((quiz,  id) => {
			
            if (quiz.type === "single select") {
              return (
                <div key={id} id={questnavId+id+1}>
                  <RadioQuiz questNo={id+1} quickCheckId = {quickCheckId} quiz={quiz}  timeEnded = {timeEnded} />
                </div>
              );
            } else if (quiz.type === "short answer") {
              return (
                <div key={id} id={id+1}>
                  <TextBoxQuiz questNo={id+1} quickCheckId = {quickCheckId} quiz={quiz}  timeEnded = {timeEnded} />
                </div>
              );
            }

			else if (quiz.type === "multi select") {
				return (
				<div key = {id} id={id+1}>
					<Checkbox questNo={id+1} quickCheckId = {quickCheckId} quiz ={quiz}  timeEnded = {timeEnded}/>
					</div>
					)
			  }

			else if (quiz.type === "richtext") {
				return (<div key = {id} id={id+1}><RichtextQuiz questNo={id+1} quickCheckId = {quickCheckId} quiz ={quiz} timeEnded = {timeEnded}/></div>)
			  }


            return <div key={id}></div>;
          })}

        </Grid>
        
        <Grid item sm={3} md={3} lg={3}  >
          {/* Quiz navigation */}

          <QuizNavigation quiz = {quiz.data} questnavId = {questnavId}/>
          
        </Grid>
		<Grid container columns={12} sx={{ mt: 2,mb:2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Button
                variant="contained"
                onClick={savequiz}
                sx={{
                  bgcolor: "#f95877",
                  color: "white",
                  ":hover": {
                    bgcolor: "#f95877",
                    color: "white",
                  },
                }}
              >
                Submit
              </Button>
              <Link to="/coursepreview" style={{ textDecoration: "none",color: "#000000"  }}>
              
              <Button
                variant="contained"
                // onClick={handlecancel}
                sx={{
                  bgcolor: "#f95877",
                  color: "white",
                  ":hover": {
                    bgcolor: "#f95877",
                    color: "white",
                  },
                }}
              >
                Cancel
              </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
	</>
  );
};

// const mapStateToProps = (state) => {
//   return { quiz: state.quizData };
// };

export default Quiz;
// export default connect(mapStateToProps, fetchQuiz("/quiz"))(Quiz);

