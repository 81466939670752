import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {getstudentevents} from '../../store/action';

export default function UserTimeline() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getstudentevents());
    
    }, []);
  const events = useSelector((state) => state.Stulist.studentevents);
  return (
    <Card style = {{borderRadius:".5rem",border:"1px grey"}}>
    {events && events.map((item,index) => (
    <Timeline position="alternate">
      <TimelineItem>
      <TimelineOppositeContent sx={{ py: '12px', px: 2 }}>
          <div sx={{display:'flex'}}>
          <Typography variant="h9" component="span">
            {item.startHour} 
          </Typography>
          <Typography sx={{ml:'1rem'}} variant="h9" component="span">
            - {item.endHour} 
          </Typography>
          </div>
          <Typography>{item.date}</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{bgcolor:"#f95877"}}>
            <LaptopMacIcon sx={{bgcolor:"#f95877"}}  />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent
          sx={{ m: 'auto 0' ,fontSize:'1rem'}}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          {item.label}
        <Typography>{item.groupLabel}</Typography>
        </TimelineContent>
        
        
      </TimelineItem>
      
    </Timeline>
    ))}
    </Card>
  );
}