import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { useSelector } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory, } from 'react-router-dom';

function ConfirmationDialogRaw(props) {
  const options = useSelector((state) => state.Icons.icons);
  console.log(options)
  const history = useHistory();
  const { onClose, value: valueProp, open,id,scroll, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const [description,setDesscription] = React.useState('Select a question type to see its description.');

  // console.log(value)
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
    history.push(options[value].tourl+'/'+id);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    setDesscription(options[event.target.value].description)
  };

  return (
    <Dialog
      
      
      disableBackdropClick
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight:'608px'} ,fontFamily:"Helvetica Neue,Helvetica,Arial,sans-serif",color:"#336",fontSize: '.9375rem',fontWeight:"400",textAlign:"left"}}
      maxWidth="sm"
      PaperProps={{style: {backgroundColor: 'transparent'}}}
      
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    > 
      <div style={{overflow:'clip'}}>
      <DialogTitle sx={{minHeight: '3rem',color:"#336",backgroundColor:'#fff',fontSize:'1.5rem',display:'flex',padding:'1rem 1rem',borderBottom:"1px solid #dee2e6"}}>Choose a question type to add</DialogTitle>
      
      <Grid  container column={12}>
        <Grid  item lg={6} md={6} sm={12} xs={12}>
            <div style={{overflow: 'hidden',borderRight:'1px solid grey', maxHeight: '508px', display: 'block'}} disableScrollLock={false}>
            <DialogContent  style={{minHeight:"auto",backgroundColor:"#fff"}}  dividers>
                
                <Typography sx={{textTransform:"uppercase",fontSize:"100%",paddingTop:"0.2rem",paddingBottom:".4rem",marginBottom:".5rem"}} >Questions</Typography>
                <RadioGroup
                
                ref={radioGroupRef}
                aria-label="ringtone"
                name="ringtone"
                value={value}
                
                onChange={handleChange}
                >
                {options && options.map((option,index) => (          
                    <FormControlLabel
                    sx={{borderBottom:"1px solid #fff"}}
                    value={index}
                    key={option.name}
                    
                    control={<Radio />}
                    label={
                        
                        
                        <>
                            <div style={{display:'flex',flexDirection:'row'}}>
                            
                            <img style={{paddingLeft:'1rem',paddingRight:'1rem',maxHeight:"auto",objectFit:'contain',alignItem:"center"}} src={option.url}/>
                            
                            
                            <Typography  sx={{alignText:"left",paddingLeft:'.5rem',fontFamily:"Helvetica Neue,Helvetica,Arial,sans-serif",color:"#336",fontSize: '.7375rem',fontWeight:"9px"}}>{option.name}</Typography>
                            </div>
                        
                        
                        </>
                        }
                    />
                ))}
                
                </RadioGroup>
                
            </DialogContent>
            </div>

        </Grid>
        <Grid sx={{bgcolor:"#fff"}} item lg={6} md={6} sm={6} xs={6}>
            <Typography sx={{padding:"1.6em"}}>
                Select the required option and click add to create questions
            </Typography>
        </Grid>
      </Grid>
      </div>
      <DialogActions sx={{backgroundColor:"#fff",borderTop:'1px solid grey'}}>
        <Button sx={{backgroundColor:"#f95877",border:"1px solid transparent",borderRadius:".5rem",marginLeft:'.5rem',marginRight:'.5rem',color:'white'}} autoFocus onClick={handleOk}>
          Add
        </Button>
        <Button sx={{backgroundColor:"#f95877",border:"1px solid transparent",borderRadius:".5rem",marginLeft:'.5rem',marginRight:'.5rem',color:'white'}} onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function Createquedialog({id}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
 
  const handleClickListItem = () => {
    setOpen(true);
   
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
    <><Box  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

          <Button
              
              sx={{ backgroundColor: "#f95877" }}
              onClick={handleClickListItem}
          >
              Create a new question
          </Button>


      </Box>
      <Box>
              <ConfirmationDialogRaw
                  value={value}
                  keepMounted
                  open={open}
                  id={id}
                  onClose={handleClose} />
      </Box></>
  );
}