import React from 'react';
import {  Divider, Grid, Typography,Stack } from '@mui/material'
import Rating from '@mui/material/Rating';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#868e96' : '#868e96',
    },
  }));


  function stringAvatar(name) {
    return {
        sx: {
            bgcolor: "#f95877",
            fontSize:13,
          },
      
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  
export default function StudentFeedback({StudentFeedbackData}) {


    console.log(StudentFeedbackData)

    

  return (
    <div>

    <Grid container colums = {12} sx = {{mb:5}}>

    
        {/* <Grid item sm = {1} md = {1} lg = {1}>

        </Grid> */}

            <Grid item sm = {12} md = {12} lg = {12} >{/* content grid */}
            <Stack direction = "column" alignItems="center" spacing = {2} sx = {{p:2,mb:1}}>

              <Typography variant = "h6" sx = {{color:"text.secondary"}}>Feedback
              <Divider sx={{width:'100%',borderRadius:2,border:2,color:"#f88da4"}}/></Typography>
              <Typography variant = "subtitle2" >What other students turned professionals have to say about us after learning with us and reaching their goals.</Typography>
            </Stack>

                <Grid container colums = {12} >

                  <Grid item sm = {1} md = {1} lg = {1}>
                  <Typography variant = "button" sx = {{color:"text.secondary"}}>Reviews</Typography>

                  </Grid>

                  <Grid item sm = {11} md = {11} lg = {11} sx = {{mt:1.5}}>
                  <Divider  />

                  </Grid>

                </Grid>

                <Grid container colums = {12} sx = {{mt:2,mb:4}}>

                  <Grid item sm = {3} md = {3} lg = {3}>

                      <Stack direction = "column">
                      <Typography variant = "h2">4.7</Typography>
                      <Rating name="read-only" value={4} readOnly />
                      <Typography variant = "body2">20 ratings</Typography>


                    </Stack>

                  </Grid>

                  <Grid item sm = {8} md = {8} lg = {8} >

                  <Stack direction ="column" spacing = {2} sx = {{mr:3}}>
                  <BorderLinearProgress variant="determinate" value={75} />
                  <BorderLinearProgress variant="determinate" value={16} />

                  <BorderLinearProgress variant="determinate" value={12} />
                  <BorderLinearProgress variant="determinate" value={9} />

                  <BorderLinearProgress variant="determinate" value={0} />
                  </Stack>

                  </Grid>

                  <Grid item sm = {1} md = {1} lg = {1} >
                  <Stack direction = {"column"} spacing={0.8} alignItems="end" sx={{mt:-1}} >

                  <Rating name="size-small" defaultValue={5} size="small" readOnly />
                  <Rating name="size-small" defaultValue={4} size="small" readOnly/>
                  <Rating name="size-small" defaultValue={3} size="small" readOnly />
                  <Rating name="size-small" defaultValue={2} size="small" readOnly/>
                  <Rating name="size-small" defaultValue={1} size="small" readOnly />
                  </Stack>
                  </Grid>


            

              </Grid>


                <div>

                {StudentFeedbackData.map(StudentFeedbackData => (
        
                  <div key = {StudentFeedbackData.id}>
                    

                <Grid container colums = {12} sx = {{mt:2,mb:2}} >
                    <Grid item sm = {3} md = {3} lg = {3}>
                    <Grid container colums = {12} >
                    <Grid item sm = {3} md = {3} lg = {3}  >
                    <Avatar {...stringAvatar(StudentFeedbackData.Name)}  />
                    </Grid>
                    <Grid item sm = {9} md = {9} lg = {9}  >
                        <Stack direction = {"column"}  >
                            <Typography variant = "body2">{StudentFeedbackData.date}</Typography>
                            <Typography variant = "subtitle1" sx = {{color:"text.secondary"}}>{StudentFeedbackData.Name}</Typography>

                        </Stack>
                    </Grid>

                        </Grid>

                
                    </Grid>
                    <Grid item sm = {9} md = {9} lg = {9}>

                    <Stack direction = {"column"} spacing={1}  >

                    <Rating name="size-small" defaultValue={StudentFeedbackData.rating} size="small" readOnly />
                        <Typography variant = {"subtitle2"}>{StudentFeedbackData.FeedbackDesc}</Typography>
                    </Stack>
                    
                    </Grid>


                </Grid>

                <Divider sx={{ bgcolor: "1px solid #e9edf2" }}/>
              </div>

                  ))}
                  </div>

            </Grid>
        {/* <Grid item sm = {1} md = {1} lg = {1}>
            
        </Grid> */}

        
    </Grid>

    </div>
  )
}
