export default class Filter{
    
    static search(datas:any,searchString:any){
        var searchResult : any = [];
        for(const data of datas) {
            for(const obj of Object.values(data)){
                if(obj != null){
                    if(typeof(obj)== "object"){
                        if(this.checkForElementInObject(obj,searchString))
                            searchResult.push(data);
                    }
                    else if((obj+'').toLowerCase().includes((''+searchString).toLowerCase())){
                        searchResult.push(data);
                        break;
                    }
                }
            };
        };
        return searchResult;
    }

    static checkForElementInObject(obj:any,element:any){
        let result : boolean = false;
        for(const temp of Object.values(obj)){
            if((temp+'').toLowerCase().includes((''+element).toLowerCase())){
                return true;
            }
        }
        return result;
    }

    static sort(type:string,datas:any,order:number){
        return datas.sort((a:any,b:any)=>{
            let val1 : any = Object.values(a)[order];
            let val2 : any = Object.values(b)[order];
            return type == 'desc' ? this.sortDESC(val1.value,val2.value) : this.sortASC(val1.value,val2.value);
        });
    }

    private static sortASC(a:any,b:any){

        if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          }
    }
    private static sortDESC(a:any,b:any){
        
        if (a < b) {
            return 1;
          } else if (a > b) {
            return -1;
          }
    }
    
    
}