import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid,Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InstructorTable from './InstructorTable'


function AssignCourse() {

    const [open, setOpen] = React.useState(false);
  
  
    const handleChange = (newValue) => {
    //   setValue(newValue);
    };
  
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


  return (
    <div>

<Button style={{ margin: "0 0 15px 0" }} onClick = {handleClickOpen}>Assign course</Button>
<Dialog
        open={open}
        onClose={handleClose}
        // aria-labelledby="responsive-dialog-title"
        fullWidth 
        maxWidth={"md"}
      >
        <DialogTitle 
        // id="responsive-dialog-title" 
        sx = {{mt:.5,p:.5,
        // bgcolor:"rgb(241 243 244)"
        // bgcolor:"#bebec2"

        }}>
          <Typography
          sx={{ flex: '1 1 100%',ml:3}}
          variant="h6"
          id="tableTitle"
          component="div"
        >
         Assign Course
        </Typography>
          <Stack alignItems={"end"} justifyContent ={"center"} sx = {{mr:1,mt:-4.5,flex: '1 1 100%' }}>
          <IconButton onClick={handleClose}><CloseIcon /></IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent  
        // sx = {{p:1,mr:1}}
        >
            
        <InstructorTable/>
        </DialogContent>
        <DialogActions sx = {{p:1,mr:3,mb:2}}>
          <Button onClick={handleClose} 
          // size = "small"
          >
            <Typography sx = {{fontFamily:"Poppins",color:"#ffffff"}} >Assign</Typography>
          </Button>
          <Button onClick={handleClose} 
          // size = "small"
          >
            <Typography sx = {{fontFamily:"Poppins",color:"#ffffff"}}>Cancel</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AssignCourse