import { Box, Button, Grid, Stack, Typography,TextField, Snackbar } from "@mui/material";
import React ,{ useEffect } from "react";
import RichTextbox from "./RichTextbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Quizservice from "./quizservice";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Select from "@mui/material/Select";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from "react-router-dom";
import { 
  useHistory,
  useLocation,
  Link
} from "react-router-dom";

const choice = ["Answer 1", "Answer 2", "Answer 3", "Answer 4", "Answer 5"];
export default function Shortanswer() {
const history = useHistory();
const location = useLocation();
let { id } = useParams();
const [isAddMode,setAddMode] = React.useState(true);
const[alert,setalert] = React.useState(false);
const[Loading,setLoading] = React.useState(false);
const [que,setque] = React.useState('');
const [ansid,setansid] = React.useState('');
const [queid,setqueid] = React.useState('');
const [score, setscore] = React.useState(0);
const [correctans,setcorrectans] = React.useState('');
const [countans,setcountans] = React.useState(0);
const[keylist,setkeyword] = React.useState([]);
const [popup,setPopup] = React.useState(false);


useEffect(() => {
  if (location.state != undefined) {
      const { questiontext } = location.state
      console.log("data",questiontext);
      setque(questiontext.question);
      console.log("question",que);
      setqueid(questiontext.id);
      setscore(questiontext.score);
      setcountans(questiontext.no_correctanswers);
      var array = questiontext.keywords.split(',');

      setkeyword(array);
      console.log("keywords",keylist)
      setAddMode(false);
      console.log(isAddMode);
  
  // 
      // get user and set form fields
      new Quizservice().getanswerById(questiontext.id).then(user => {
        setansid(user.data[0].answerId);
        
        console.log("editform",user.data);
        setcorrectans(user.data[0].answer);
        
        
          
          // setansid(user.data.answerId);
          
        
      })
      
          // setactive(course.is_active);
          // setpublised(course.is_published);
          // setlmslink(course.lms_link);
          // setthumbnailid(course.thumbnail_asset_id);
          // const fields = ['title', 'firstName', 'lastName', 'email', 'role'];
          // fields.forEach(field => setValue(field, user[field]));
          // setUser(user);
      
  
  }
}, []);
const getrichttext = (data) => {
    console.log(data)
    setque(data) // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  }
  const handleDeleteFalse = () =>{
    setPopup(false);
   }

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const savequiz = () => {
    setLoading(true);
    var raw2 = {
      "quiz_id": id,
      "question": que,
      "type": "short answer",
      "score": score,
      "active": 1,
      "url":"/shortanswer",
      "no_correctanswers":countans,
      "keywords":keylist,
      "answers": [
        {
          "answers":correctans,
          "correct":0,
          "active":1
        }
      ]
    };
    
    let payload = JSON.stringify(raw2)
    setLoading(true);
    
    
    if (isAddMode) {
        console.log("data",payload);
        new Quizservice().addQueans(payload).then(
            
            (response) => {
                        console.log(response);
                        setLoading(false);
                        setalert(true);
                        setPopup(false);
                        // history.push("/createquestion/"+id);
                        
                    
            }
        );
    } else {
        var editraw = {
          "quiz_id": id,
          "question": que,
          "questionId":queid,
          "type": "short answer",
          "score": score,
          "active": 1,
          "url":"/shortanswer",
          "answerId":ansid,
          "no_correctanswers":countans,
          "keywords":keylist,
          "answers": [
            {
              "answerId":ansid,
              "answers":correctans,
              "correct":0,
              "active":1
            }
          ]
        };
        let editpayload = JSON.stringify(editraw)
        setLoading(true);
        console.log("data",editpayload);
        new Quizservice().updatequestionanswer(editpayload).then(
            
            (response) => {
                console.log(response);
                setLoading(false);
                setPopup(false);
                setalert(true);
                // history.push("/courses");
            }
        );
    }
  }
  return (
    <>
    <Dialog
      open={popup}
      onClose={handleDeleteFalse}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{fontSize:'.9005rem',color:'#000000'}}  id="alert-dialog-title">
      {isAddMode ? 'Are you sure you want to save the question?' : 'Are you sure you want to edit the question?'}
      </DialogTitle>
      <DialogActions>
        <Button size="small" onClick={handleDeleteFalse}>No</Button>
        <Button size="small" onClick={savequiz} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
    <div style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 999 }}>
    {isAddMode === true  && <Box >
              <Snackbar anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
              }} open={alert} autoHideDuration={6000} onClose={(e) => setalert(false)}>
                  <Alert onClose={(e) => setalert(false)} severity = "success" sx={{ width: '100%' }}>
                      Question Successfully Created!
                  </Alert>
              </Snackbar>
        </Box>}
    {isAddMode === false && <Box >
              <Snackbar anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
              }} open={alert} autoHideDuration={6000} onClose={(e) => setalert(false)}>
                  <Alert onClose={(e) => setalert(false)} severity = "success" sx={{ width: '100%' }}>
                      Question Successfully Updated!
                  </Alert>
              </Snackbar>
        </Box>  }
    </div><Dialog 
                        color="success"
                        PaperProps={{
                            style: {
                           
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            },
                        }} open={Loading} 
                        >
                        <DialogContent sx={{display:'block',justifyContent:'center',alignItem:'center'}}>
                        <CircularProgress sx={{justifyContent:'center',alignItem:'center',ml:'2rem'}} />
                        <h4 style={{color:"#fff"}}>Loading...</h4>
                        </DialogContent>
    </Dialog>
    <Grid container columns={12} spacing={1} sx={{ mt: 3, mb: 3 }}>
      <Grid item xs={2} sm={2} md={1} lg={1}>
        {/* left */}
      </Grid>
      <Grid item xs={8} sm={8} md={10} lg={10}>
        <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
        {isAddMode ? 'Adding a short answer question' :'Editing a short answer question' }
        </Typography>

        <Typography variant="h6" sx={{ color: "text.secondary" }}>
          General :
        </Typography>

        <Grid container columns={12} sx={{ mt: 2 }}>
          {/* <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
            <Typography>Question name</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
            <TextField fullWidth></TextField>
          </Grid> */}

          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
            <Typography>Question text</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
          <TextField multiline rows={4} fullWidth value={que} onChange={(e) => {setque(e.target.value)}}></TextField>
          {/* <RichTextbox func={getrichttext}  /> */}
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
            <Typography>Default mark</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
            <TextField value={score} onChange={(e) => setscore(e.target.value)}></TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
            <Typography> No.of Correct Answer</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
            <TextField value={countans} onChange={(e) => setcountans(e.target.value)}></TextField>
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ color: "text.secondary" }}>
          Answers :
        </Typography>

        <Box sx={{ mb: 2, p: 2, border: 1,mt: 2, borderColor: "#c0c1c4" }}>
          <Grid container columns={12}>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
              <Typography>Answer</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
            <TextField sx={{minWidth:'100%'}} value={correctans} onChange={(e) => setcorrectans(e.target.value)} ></TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
              <Typography>Keywords</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} sx={{ mb: 2 }}>
            <Autocomplete
              multiple
              freeSolo
              id="tags-outlined"
              options={[]}
              value={keylist}
              onChange={(e, newval, reason) => {
                setkeyword(newval);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  
                  placeholder="Enter keywords"
                  onKeyPress={e => {
                    if (e.code === 'Enter' && e.target.value) {
                      setkeyword(keylist.concat(e.target.value));
                      console.log("e")
                    }
                  }}
                />
              )}
            />
          </Grid>
          </Grid>
        </Box>

        <Grid container columns={12} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Button
                variant="contained"
                onClick={(e) => setPopup(true)}
                
                sx={{
                  bgcolor: "#f95877",
                  color: "white",
                  ":hover": {
                    bgcolor: "#f95877",
                    color: "white",
                  },
                }}
              >
                Save
              </Button>

              <Link to="/createquiz" style={{ textDecoration: "none",color: "#000000"  }}>

              <Button
                variant="contained"
                // onClick={handlecancel}
                sx={{
                  bgcolor: "#f95877",
                  color: "white",
                  ":hover": {
                    bgcolor: "#f95877",
                    color: "white",
                  },
                }}
              >
                Cancel
              </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} sm={2} md={1} lg={1}>
        {/* right */}
      </Grid>
    </Grid>
    </>
  );
}
