import React ,{ useEffect } from "react";
import { Box, Button, Grid, Snackbar, Stack, TextField, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RichTextbox from './RichTextbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useParams } from "react-router-dom";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import Quizservice from "./quizservice";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {
  useHistory,
  useLocation,
 Link
  } from "react-router-dom";

const choice = ['Choice 1','Choice 2','Choice 3','Choice 4','Choice 5','Choice 6']
const questionType = ['single select','multi select']


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function MultipleChoice() {

  
  let { id } = useParams();
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(false);

  const [seletctVal, setSeletctVal] = React.useState("");
  const [que,setque] = React.useState('');
  const[value,setValue] = React.useState(1);
  const[Arraylist,setarray] = React.useState([]);
  const[answeridlist,setansweridlist] = React.useState([]);
  const [isAddMode,setAddMode] = React.useState(true);
  const[correctlist,setcorrect] = React.useState([0]);
  const [payloadarr, setpayload] = React.useState([]);
  const [popup, setPopup] = React.useState(false);
  const[alert,setalert] = React.useState(false);
  const[Loading,setLoading] = React.useState(false);
  const [single, setsingle] = React.useState(0);
  const [countans,setcountans] = React.useState(0);
  const [queid,setqueid] = React.useState('');
  console.log("answerlist",Arraylist)
  console.log("correctlist",correctlist)
  console.log("answeridlist",answeridlist)
  console.log(payloadarr)
  
  
  // const history = useHistory ();
  // const handlecancel = (event) => {
  //   history.push('/createquiz'); 
  //     };
  useEffect(() => {
    if (location.state != undefined) {
        const { questiontext } = location.state
        console.log("data",questiontext);
        setque(questiontext.question);
        console.log("question",que);
        setqueid(questiontext.id);
        setValue(questiontext.score);
        setcountans(questiontext.no_correctanswers);
        setSeletctVal(questiontext.type)
        // var array = questiontext.keywords.split(',');
  
        // setkeyword(array);
        // console.log("keywords",keylist)
        setAddMode(false);
        console.log(isAddMode);
    
    // 
        // get user and set form fields
        new Quizservice().getanswerById(questiontext.id).then(user => {
          console.log("answerdata",user.data)
          {user.data.map((type,id) => {
            setarray(state => ({
              ...state,
              [ id ]: type.answer,
           
            }))
            setcorrect(state => ({
              ...state,
              
              [ id ]: type.correct,
    
            }))
            setansweridlist(state => ({
              ...state,
              
              [ id ]: type.answerId,  
              
            }))
          // setansid(user.data[0].answerId);
          
          console.log("editform",type);
          })}
          // setcorrectans(user.data[0].answer);
          
          
            
            // setansid(user.data.answerId);
            
          
        })
        
            // setactive(course.is_active);
            // setpublised(course.is_published);
            // setlmslink(course.lms_link);
            // setthumbnailid(course.thumbnail_asset_id);
            // const fields = ['title', 'firstName', 'lastName', 'email', 'role'];
            // fields.forEach(field => setValue(field, user[field]));
            // setUser(user);
        
    
    }
  }, []);


  const getrichttext = (data) => {
    console.log(data)
    setque(data) // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  }

  const selectHandler = (event) => {
    setSeletctVal(event.target.value);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleDeleteFalse = () =>{
    setPopup(false);
   }
  const savearray = () =>{
    
    {Arraylist && setpayload(Object.entries(Arraylist).filter(Arraylist => Arraylist[1] != '').map((fr, i) => ({"answers" : fr[1],  "correct" :correctlist[i],"active": 1,"answerId":answeridlist[i]})))}
    setPopup(true);
    
  }
  const savequiz = () => {
    if(payloadarr.length > 0) {
      var raw2 = {
        "quiz_id": id,
        "question": que,
        "type": seletctVal,
        "score": value,
        "active": 1,
        "url":'/multichoice',
        "no_correctanswers":countans,
        "keywords":'',
        "answers": [
          {
    
          }
        ]
      };
      
      raw2["answers"] = payloadarr
      let payload = JSON.stringify(raw2)
      setLoading(true);
      
      
      if (isAddMode) {
          console.log("data",payload);
          new Quizservice().addQueans(payload).then(
              
              (response) => {
                          console.log(response);
                          setLoading(false);
                          setalert(true);
                          setPopup(false);
                          // history.push("/createquestion/"+id);
                          
                      
              }
          );
      } else {
        var editraw = {
          "quiz_id": id,
          "question": que,
          "questionId":queid,
          "type": seletctVal,
          "score": value,
          "active": 1,
          "url":'/multichoice',
          "no_correctanswers":countans,
          "keywords":'',
          "answers": [
            {
      
            }
          ]
        };
        
        editraw["answers"] = payloadarr
        let payload = JSON.stringify(editraw)
        setLoading(true);
          console.log("data",payload);
          new Quizservice().updatequestionanswer(payload).then(
              
              (response) => {
                  console.log(response);
                  setLoading(false);
                  setalert(true);
                  setPopup(false);
                  // history.push("/courses");
              }
          );
      }
      
      console.log(payload)
      
      }
  }
  const handlechoice = (event,id) => {
    setarray(state => ({
      ...state,
      [ id ]: event.target.value,
      

      
      
      
    }))
    setcorrect(state => ({
      ...state,
      
      [ id ]: 1
      
      
      
      
      
      
      
    }))
  }
  const handlecorrect = (event,id) => {
    if(event.target.value == 'correct'){
    setcorrect(state => ({
      ...state,
      
      [ id ]: 1
      
      
      
      
      
      
      
    }))}
    else{
      setcorrect(state => ({
        ...state,
        
        [ id ]: 0
        
        
        
        
        
        
        
      }))
      
    }
  
    
  }
  return (
    <>
    <div style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 999 }}>
    {isAddMode === true  && <Box >
              <Snackbar anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
              }} open={alert} autoHideDuration={6000} onClose={(e) => setalert(false)}>
                  <Alert onClose={(e) => setalert(false)} severity = "success" sx={{ width: '100%' }}>
                      Question Successfully Created!
                  </Alert>
              </Snackbar>
        </Box>}
    {isAddMode === false && <Box >
              <Snackbar anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
              }} open={alert} autoHideDuration={6000} onClose={(e) => setalert(false)}>
                  <Alert onClose={(e) => setalert(false)} severity = "success" sx={{ width: '100%' }}>
                      Question Successfully Updated!
                  </Alert>
              </Snackbar>
        </Box>  }
    </div><Dialog 
                        color="success"
                        PaperProps={{
                            style: {
                           
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            },
                        }} open={Loading} 
                        >
                        <DialogContent sx={{display:'block',justifyContent:'center',alignItem:'center'}}>
                        <CircularProgress sx={{justifyContent:'center',alignItem:'center',ml:'2rem'}} />
                        <h4 style={{color:"#fff"}}>Loading...</h4>
                        </DialogContent>
    </Dialog>
    <Dialog
      open={popup}
      onClose={handleDeleteFalse}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{fontSize:'.9005rem',color:'#000000'}}  id="alert-dialog-title">
      {isAddMode ? 'Are you sure you want to save the question?' : 'Are you sure you want to edit the question?'}
      </DialogTitle>
      <DialogActions>
        <Button size="small" onClick={handleDeleteFalse}>No</Button>
        <Button size="small" onClick={savequiz} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
    <Grid container columns = {12} spacing = {1} sx = {{mt:3,mb:3}} >
        <Grid item xs = {2} sm = {2} md = {1} lg ={1} >
          {/* left */}
          </Grid>
        <Grid item xs = {8} sm = {8} md = {10} lg ={10}>
        <Typography variant = "h4" sx = {{mt:2,mb:2}}>{isAddMode ? 'Adding a multiplechoice question' :'Editing a multiplechoice question' }</Typography>

        {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          > */}
              <Typography variant = "h6" sx = {{color:'text.secondary'}}>General :</Typography>
          {/* </AccordionSummary>
          <AccordionDetails> */}
           <Grid container columns = {12} sx = {{mt:2}}  >

              {/* <Grid item xs = {12} sm = {12} md = {3} lg = {3} sx = {{mt:1}} >
                <Typography>Question name</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} sx = {{mb:2}}>
                <TextField fullWidth></TextField>
              </Grid> */}

              <Grid item xs = {12} sm = {12} md = {3} lg = {3} sx = {{mt:1}}>
                <Typography>Question text</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} sx = {{mb:2}}>
                {/* <TextField fullWidth multiline rows={4}></TextField> */}
                <TextField multiline rows={4} fullWidth value={que} onChange={(e) => {setque(e.target.value)}}></TextField>
                {/* <RichTextbox func={getrichttext} /> */}

              </Grid>

              <Grid item xs = {12} sm = {12} md = {3} lg = {3} sx = {{mt:1}}>
                <Typography>Default mark</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} sx = {{mb:2}}>
                <TextField value ={value}
          onChange ={(e)=>(setValue(e.target.value))}></TextField>
              </Grid>

              <Grid item xs = {12} sm = {12} md = {3} lg = {3} sx = {{mt:1}}>
                <Typography>One or multiple answers?</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} sx = {{mb:2}}>
              <FormControl 
              sx={{ width: '26.5%' }}
              >

                <Select id="demo-simple-select" value={seletctVal} onChange={selectHandler}>
                  {questionType.map((type,id) => (
                    <MenuItem value={type} key={id}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>

              <Grid item xs = {12} sm = {12} md = {3} lg = {3} sx = {{mt:1}}>
                <Typography>No.of Correct Answer</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} sx = {{mb:2}}>
                <TextField value={countans} onChange={(e) => setcountans(e.target.value)} ></TextField>
              </Grid>


           </Grid>
           {/* </AccordionDetails>
           </Accordion>  */}


      {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
      <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          > */}
        
        <Typography variant = "h6" sx = {{color:'text.secondary',}}>Answers :</Typography>
           {choice.map((choice,id) =>(



            <Box key = {id} sx = {{mb:3,p:1,mt:2,
            // bgcolor:"#fee7eb",
            border:1,borderColor:"#c0c1c4"}}>
            <Grid container columns = {12}>
              <Grid item xs = {12} sm = {12} md = {3} lg = {3}  >
                <Typography sx = {{mt:2}}>{choice}</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} >
                <TextField value={Arraylist[id]} onChange = {(e) => handlechoice(e,id)}   fullWidth></TextField>
                <div style={{display:'flex',flexDirection:'row'}}>
                <Typography  sx={{justifyContent: 'center' ,mt:3,mb:2,alignText:"left",fontFamily:"Helvetica Neue,Helvetica,Arial,sans-serif",color:"#336",fontSize: '.9375rem',fontWeight:"9px"}}>correct answer:</Typography>
                <Select  size='small' sx={{minWidth:"130px",mt:2,mb:2,ml:1}} id="demo-simple-select" value={correctlist[id] === 0 ? "not correct":"correct"} onChange={(e) => handlecorrect(e,id)}>
                  <MenuItem value='correct' key={0}>
                      correct
                  </MenuItem>
                  <MenuItem value="not correct" key={1}>
                      not correct
                  </MenuItem>
                </Select>
                </div>
                {/* <Checkbox   checked={correctlist.value} onChange={(e) => handlecorrect(e,id)} {...label} /> */}
                {/* <RichTextbox/> */}

              </Grid>

              {/* <Grid item xs = {12} sm = {12} md = {3} lg = {3} >
                <Typography sx = {{mt:4}} >Feedback</Typography>
              </Grid>
              <Grid item xs = {12} sm = {12} md = {9} lg = {9} >
                <RichTextbox/>

              </Grid> */}

            </Grid>
            </Box>

            ))}

{/* </AccordionDetails>
</Accordion> */}
        
            
        <Grid container columns = {12} sx = {{mt:2}}>
          <Grid item xs = {12} sm = {12} md = {12} lg = {12}>
            <Stack direction = "row" justifyContent = "center" spacing = {2}>
              <Button onClick={savearray} variant = "contained" 
              sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                bgcolor: '#f95877',
                color: 'white', },}}
              
              >Save</Button>


                      <Link to="/createquiz" style={{ textDecoration: "none",color: "#000000"  }}>
              <Button variant = "contained"
              // onClick={handlecancel}
              
              sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                bgcolor: '#f95877',
                color: 'white', },}}
              >Cancel</Button>
              </Link>

            </Stack>
          </Grid>
          </Grid>

          
        </Grid>
        <Grid item xs = {2} sm = {2} md = {1} lg ={1}>
          {/* right */}
          </Grid>

        </Grid>
        </>
  )
}
