import { Component, ReactNode,useRef  } from "react";
import AdminNavbar from "../components/Dashboard/AdminNavbar";
import "../components/Forms/ProgramForm.scss";
import { Link } from "react-router-dom";
import Service from "../service/service";
import React from "react";
import { type } from "os";
import Multiselect from 'multiselect-react-dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import AdminHeader from "../components/Dashboard/AdminHeader";
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { BlockUI } from 'primereact/blockui';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Box, Grid, Typography } from "@mui/material";
import DashboardBreadcrums from "../JsComponents/Quiz/DashboardBreadcrums";
import AssignCourse from "../JsComponents/AssignCourse";
import InstructorTable from "../JsComponents/AssignCourse/InstructorTable";

class CourseForm extends React.Component {
    isEditForm: boolean = false;
    selectedCourseList: any = [];
    courseList: any = [];
    categories: any = [];
    asset: any = {};
    state: any = {};
    courses: any = [];
    courseIds: any = [];
    props: any;
    form : any = {
        title : {valid:false},
        thumbnail : {valid : false},
        description : {valid : false},
        due_in : {valid : false},
        duration : {valid : false},
        lms_link : {valid : false},
    }
    validForm : boolean = false;
    formSubmitted : boolean = false;
    blockedPanel : boolean = false;
    toast:any;
    constructor(props: any) {
        super(props);
        this.props = props;
        this.toast = React.createRef();
        // this.onSelectCategory = this.onSelectCategory.bind(this);
        // this.state = props.location.state.program ? props.location.state.program : {} ;
        if (props.location.state != undefined) {
            this.state = props.location.state.course;
            this.isEditForm = true;
            // this.state.course_list.forEach((course:any)=>{
            //     this.courseIds.push(course.id);
            // })
        }
        this.getProgramGroups();
    }
    getProgramGroups() {
        this.categories = new Service().getCategories();
    }

    onChangeValue = (event: any) => {
        this.setState({ [event.target.id]: event.target.value });
        this.validateForm();
    };

    onUploadThumbnail = (event: any) => {
        this.asset = {
            type: 'thumbnail',
            file: event.target.files[0]
        };
        this.validateForm();
    }

    saveProgram = () => {
        // this.toast = useRef(null);
        this.formSubmitted = true;
        // var duration:any = (this.state.duration + '').split(':');
        if(this.validateForm()){
            this.blockedPanel = true;
            const course = {
                "id": this.state.id != undefined ? this.state.id : 0,
                "title": this.state.title,
                "description": this.state.description,
                "duration": this.state.duration,
                "due_in": this.state.due_in,
                "lms_link": this.state.lms_link,
                "is_published": this.state.is_published ? this.state.is_published : false,
                "thumbnail_asset_id": this.state.thumbnail_asset_id != undefined ? this.state.thumbnail_asset_id : 0,
            };
            if (!this.isEditForm) {
                new Service().addCourse(course).then(
                    (response: any) => {
                        this.state = response.data;
                        this.setState(this.state);
                        new Service().addAssetToCourse(this.asset.file, response.data.id, this.asset.type).then((res: any) => {
                            
                            this.props.history.push('/courses');
                        }).catch(error=>{
                            this.blockedPanel = false;
                            this.setState({isUIBlocked:this.blockedPanel});
                            this.toast.current.show({severity:'error', summary: 'Error saving the file', detail:'Something went wrong.', life: 3000});
                        })
                    }
                ).catch(error=>{
                    if((error+"").includes("409")){
                        this.toast.current.show({severity:'error', summary: 'Error saving the course', detail:'The Course with this title already exists.', life: 3000});
                        this.blockedPanel = false;
                        this.setState({isUIBlocked:this.blockedPanel});
                    }else{
                        this.blockedPanel = false;
                        this.setState({isUIBlocked:this.blockedPanel});
                        this.toast.current.show({severity:'error', summary: 'Error saving the course', detail:'Something went wrong.', life: 3000});
                    }
                });
            } else {
                new Service().updateCourse(course).then(
                    (response: any) => {
                        this.state = response.data;
                        if (this.asset.file != undefined)
                            new Service().addAssetToCourse(this.asset.file, response.data.id, this.asset.type).then((res: any) => {
                                this.props.history.push('/courses');
                            }).catch(error=>{
                                this.blockedPanel = false;
                                this.setState({isUIBlocked:this.blockedPanel});
                                this.toast.current.show({severity:'error', summary: 'Error updating the course', detail:'Something went wrong.', life: 3000});
                            });
                    }
                ).catch(error=>{
                    this.blockedPanel = false;
                    this.setState({isUIBlocked:this.blockedPanel});
                    this.toast.current.show({severity:'error', summary: 'Error updating the course', detail:'Something went wrong.', life: 3000});
                });
            }
        }

    }

    validateForm(){
        const title = document.getElementById('title') as HTMLInputElement;
        this.form.title.valid = title.value.length < 5 ? false : true;
        const duration = document.getElementById('duration') as HTMLInputElement;
        this.form.duration.valid = duration.value.length < 3 ? false : true;
        const lms_link = document.getElementById('lms_link') as HTMLInputElement;
        this.form.lms_link.valid = lms_link.value.length < 10 ? false : true;
        const thumbnail = document.getElementById('thumbnail') as HTMLInputElement;
        this.form.thumbnail.valid = (thumbnail.value.length != 0 || this.state.thumbnail_asset_id != undefined) ? true : false;
        const due_in = document.getElementById('due_in') as HTMLInputElement;
        this.form.due_in.valid = due_in.value.length != 0 ? true : false;
        const description = document.getElementById('description') as HTMLInputElement;
        this.form.description.valid = description.value.length < 5 ? false : true;
        this.setState({form : this.form});
        
        this.validForm = true;
        Object.values(this.form).forEach((ele:any)=>{
            if(Object.values(ele)[0] === false){
                this.validForm =  false;
                return;
            }
        });
        return this.validForm;
    }

    cancel() {
        this.props.history.push("/courses");
    }

    createBreadcrums: any= ["HOME","CREATE COURSE"];
    editBreadcrums: any= ["HOME","EDIT COURSE"];

    render() {
        return (
            <section>
                <div className="container">
                    <AdminHeader />
                    {/* <AdminNavbar menu="courses" /> */}
                    <div className="container" style={{ marginTop: "30px" }}>
                        <div className="row">
                            <div className="header" >
                                {/* <Link to={'/courses'} style={{ width: '5%', textAlign: 'center' }} >
                                    <FaArrowAltCircleLeft className="back-btn" />
                                </Link>
                                <h3>Add Course</h3> */}
                                 <Grid width="auto" container columns={12} sx = {{mb:-1,ml:.5}} >
                                        <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        sx={{
                                            textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                                        }}
                                        >
                                        <Typography
                                            variant="button"
                                            sx={{fontWeight:400,fontSize: '1.55rem'}}
                                        >
                                            {this.isEditForm?  'Edit Course' :'Create Course'}
                                        </Typography>
                                        </Grid>
                                        <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        sx={{
                                            mb: 2,
                                            textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                                        }}
                                        >
                                            { this.isEditForm?
                                        <DashboardBreadcrums pages={this.editBreadcrums} />
                                        :
                                        <DashboardBreadcrums  pages={this.createBreadcrums}/>
                                            }
                                        </Grid>
                                </Grid>
                                <Box sx = {{mt:2}}>
                                <InstructorTable />
                                </Box>
                            </div>
                        </div>
                        <div className="row">
                            <div className="programForm">
                                <div className="card my-3">
                                    <div className="card-body">
                                        <h5 className="card-header" style={{ fontSize: "1rem", backgroundColor: "#fff" }}>Course Form</h5>
                                        <div className="program-form pt-4">
                                            <div className="row">
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Course Title<span style={{ color: "#ff0000" }}>*</span> </label>
                                                        <input type="text" className="form-control my-1" id="title" defaultValue={this.state.title} onChange={this.onChangeValue} />
                                                        {
                                                            (!this.form.title.valid && this.formSubmitted) &&
                                                            <span className="error">* Title is required.</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Duration<span style={{ color: "#ff0000" }}>*</span> </label>
                                                        <input type="text" className="form-control my-1" id="duration" defaultValue={this.state.duration} onChange={this.onChangeValue} />
                                                        <span className="text-muted">Ex : 5 mins, 1 hr, 2 hrs 30 mins</span>
                                                        {
                                                            (!this.form.duration.valid && this.formSubmitted) &&
                                                            <span className="error">* Duration is required.</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">LMS Link<span style={{ color: "#ff0000" }}>*</span> </label>
                                                        <input type="url" className="form-control my-1" id="lms_link" defaultValue={this.state.lms_link} onChange={this.onChangeValue} />
                                                        {
                                                            (!this.form.lms_link.valid && this.formSubmitted) &&
                                                            <span className="error">* Lms link is required.</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Due In<span style={{ color: "#ff0000" }}>*</span> </label>
                                                        <input type="date" className="form-control my-1" id="due_in" defaultValue={this.state.due_in} onChange={this.onChangeValue} />
                                                        {
                                                            (!this.form.due_in.valid && this.formSubmitted) &&
                                                            <span className="error">* DueIn date is required.</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Add Thumbnail<span style={{ color: "#ff0000" }}>*</span> </label>
                                                        <input type="file" accept="image/jpg, image/jpeg, image/png" className="form-control my-1" id="thumbnail" onChange={this.onUploadThumbnail} />
                                                        {
                                                            (!this.form.thumbnail.valid && this.formSubmitted) &&
                                                            <span className="error">* Please upload thumbnail.</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label" >Publish<span style={{ color: "#ff0000" }}>*</span> </label>
                                                        <InputSwitch className="form-control my-1 inputSwitch" checked={this.state.is_published} id="is_published" onChange={this.onChangeValue} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group px-3 my-2">
                                                        <label className="form-label">Description<span style={{ color: "#ff0000" }}>*</span> </label>
                                                        <textarea className="form-control my-1" name="" id="description" rows={4} cols={50} defaultValue={this.state.description} onChange={this.onChangeValue} ></textarea>
                                                        {
                                                            (!this.form.description.valid && this.formSubmitted) &&
                                                            <span className="error">* Description is required.</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-start my-2">
                                                <button className="btn btn-primary mx-3 btn-save" type="button" onClick={this.saveProgram}>Save</button>
                                                <button className="btn btn-primary" onClick={() => this.cancel()}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BlockUI blocked={this.blockedPanel} fullScreen>
                    <Panel>
                    <div style={{textAlign:"center"}}>
                        
                    </div>
                    </Panel>
                </BlockUI>
                <ProgressSpinner hidden={!this.blockedPanel} style={{position:"absolute",top:"45%",left:"45%"}}/>
                <Toast ref={this.toast} />
            </section>
        );
    }
}
export default CourseForm;

