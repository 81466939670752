import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { v4 as uuid } from "uuid";
import moment from 'moment';
import List from '@mui/material/List';
import Quizservice from "./quizservice";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Divider } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { useSelector } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory, } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { useEffect } from "react";
import dayjs from 'dayjs';



function ConfirmationDialogRaw(props) {
  const options = useSelector((state) => state.Icons.icons);
  console.log(options)
  const history = useHistory();
  const { onClose, value: valueProp, open,prop,scroll, ...other } = props;
  
  const [value, setValue] = React.useState(valueProp);
  const [summary,setsummary] = React.useState('');
  const [title,settitle] = React.useState('');
  const [score,setscore] = React.useState(0);
  const [publised,setpublised] = React.useState(false);
  const [ispublished,setispublished] = React.useState(0);
  const[startsat,setstartsat] = React.useState(moment(dayjs(new Date())).format('YYYY-MM-DD HH:mm:ss '));
  const[startsdate,setstartsdate] = React.useState(moment(dayjs(new Date())).format('YYYY-MM-DD HH:mm:ss '));
  const[alert,setalert] = React.useState(false);
  const [isAddMode,setAddMode] = React.useState(true);
  const[endsat,setendsat] = React.useState(moment(dayjs(new Date())).format('YYYY-MM-DD HH:mm:ss '));
  const[endsdate,setendsdate] = React.useState(moment(dayjs(new Date())).format('YYYY-MM-DD HH:mm:ss '));
  console.log("create",title,score,ispublished,summary,startsdate,endsdate)
  // const[userid,setuserid] = React.useState('2583df24-8754-471f-a35c-48340f6a9b41');
  // const[quizid,setquizid] = React.useState('1f25b73b-c690-4d27-80b8-9a40d2965734');
  const[Loading,setLoading] = React.useState(false);
  
  // console.log(value)
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
    history.push(options[value].tourl);
  };
  const handlestartsdate = (newValue) => {
        
    setstartsat(newValue);
    setstartsdate(moment(newValue.$d).format('YYYY-MM-DD HH:mm:ss'))
    
    
  };
  const handleendsdate = (newValue) => {
        
    setendsat(newValue);
    setendsdate(moment(newValue.$d).format('YYYY-MM-DD HH:mm:ss '))
    
    
  };
  const savequiz = () =>{
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("title",title);
    bodyFormData.append("summary",summary);
    bodyFormData.append("type",1);
    bodyFormData.append("score",score);
    bodyFormData.append("published",ispublished);
    bodyFormData.append("startsAt",startsdate);
    bodyFormData.append("endsAt",endsdate);
    bodyFormData.append("mark_deleted",0);
    const program = {
        "title": title,
        "summary": summary,
        "type": 1,
        "score": score,
        "published": ispublished,
        "startsAt":startsdate,
        "endsAt":endsdate,
        "mark_deleted": 0,
    };
    if (isAddMode) {
        console.log("data",bodyFormData);
        new Quizservice().addQuiz(bodyFormData).then(
            
            (response) => {
                        console.log(response);
                        setLoading(false);
                        setalert(true);
                        onClose();
                        // history.push("/courses");
                        prop.func("its triggered")
                    
            }
        );
    } else {
        console.log("data",program);
        new Quizservice().updateCourse(program).then(
            
            (response) => {
                console.log(response);
                setLoading(false);
                history.push("/courses");
            }
        );
    }

   }
   const handlePublished = (e) =>{
      if (e.target.checked === true){
        setispublished(1)
      }
      else{
        setispublished(0)

      }

      setpublised(e.target.checked)



   }

//   const handleChange = (event) => {
//     setValue(event.target.value);
//     setDesscription(options[event.target.value].description)
//   };

  return (
    <><div>
    <div style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 999 }}>
    {alert && <Alert onClose={(e) => setalert(false)} severity="success">
              <AlertTitle>Success</AlertTitle>
              Quiz created succesfully<strong>check it out!</strong>
    </Alert> }
    </div>
    <Dialog 
                        color="success"
                        PaperProps={{
                            style: {
                           
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            },
                        }} open={Loading} 
                        >
                        <DialogContent sx={{display:'block',justifyContent:'center',alignItem:'center'}}>
                        <CircularProgress sx={{justifyContent:'center',alignItem:'center',ml:'2rem'}} />
                        <h4 style={{color:"#fff"}}>Loading...</h4>
                        </DialogContent>
    </Dialog>
    </div>
    <Dialog
      
      
      disableBackdropClick
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight:'auto'} ,fontFamily:"Helvetica Neue,Helvetica,Arial,sans-serif",color:"#336",fontSize: '.9375rem',fontWeight:"400",textAlign:"left"}}
      maxWidth="sm"
      PaperProps={{style: {backgroundColor: 'transparent'}}}
      
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    > 
      <div >
      <DialogTitle sx={{minHeight: '3rem',color:"#336",backgroundColor:'#fff',fontSize:'1.5rem',display:'flex',padding:'1rem 1rem',borderBottom:"1px solid #dee2e6"}}>Create a Quiz Bank</DialogTitle>
      
      <Grid  container column={12}>
        <Grid  item lg={12} md={12} sm={12} xs={12}>
            {/* <div style={{borderRight:'1px solid grey', maxHeight: '508px', display: 'block'}} > */}
            <DialogContent  style={{minHeight:"auto",backgroundColor:"#fff"}}  dividers>
            
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container columns={12} >
                            <Grid item sm={5} md={5} lg={5} >
                                <Typography variant="button" sx={{ fontWeight: 600, letterSpacing: 2 }}>BASIC INFORMATION
                                </Typography>
                            </Grid>
                            <Grid item sm={9} md={9} lg={9} sx={{ mt: 1.5, pl: 2 }}>
                                <Divider />
                            </Grid>
                        </Grid>
                        <div style={{ marginBottom: "2rem" }}>
                            <Typography variant="overline" display="block" mb={1} mt={2} sx={{ color: 'text.secondary', fontWeight: 700 }}>
                                QUIZ TITLE
                            </Typography>
                            <TextField fullWidth id='quizTitle' value={title} onChange={(e) => {settitle(e.target.value)}}  helperText="Please see our course title guideline" />
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                            <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 700 }} display="block" mb={1} mt={2}>
                                SUMMARY
                            </Typography>
                            <TextField
                                multiline
                                rows={3}
                                value={summary}
                                onChange={(e) => {setsummary(e.target.value)}}
                                fullWidth
                                id="outlined-multiline-static"
                                helperText="Shortly describe this quiz."
                            />
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                            <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 700 }} display="block" mb={1} mt={2}>
                                SCORE
                            </Typography>
                            <TextField
                                
                                value={score}
                                onChange={(e) => {setscore(e.target.value)}}
                                
                                id="outlined-multiline-static"
                                helperText="describe this duration."
                            />
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                        <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 700 }} display="block" mb={1} mt={2}>
                                STARTS AT
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                            
                            inputFormat="DD/MM/YYYY"
                            
                            value={startsat}
                            onChange={handlestartsdate}
                            renderInput={(params) => <TextField helperText="describe this starts date."  {...params} />}
                            /> 
                        </LocalizationProvider>
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                        <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 700 }} display="block" mb={1} mt={2}>
                                ENDS AT
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                            
                            inputFormat="DD/MM/YYYY"
                            
                            value={endsat}
                            onChange={handleendsdate}
                            renderInput={(params) => <TextField helperText="describe this ends date."  {...params} />}
                            /> 
                        </LocalizationProvider>
                        </div>
                        <div  style={{ marginBottom: "2rem",display:'flex' }}>
                            <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 700 }} display="block" mt={.5} >
                                PUBLISHED
                            </Typography>
                            <Switch
                            checked={publised}
                            // onChange={(e) => setpublised(e.target.checked)}
                            onChange={handlePublished}

                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </Box>
                
            </DialogContent>    
            {/* </div> */}

        </Grid>
        
      </Grid>
      </div>
      <DialogActions sx={{backgroundColor:"#fff",borderTop:'1px solid grey'}}>
        <Button onClick ={savequiz} sx={{backgroundColor:"#f95877",border:"1px solid transparent",borderRadius:".5rem",marginLeft:'.5rem',marginRight:'.5rem',color:'white'}} autoFocus>
          Add
        </Button>
        <Button sx={{backgroundColor:"#f95877",border:"1px solid transparent",borderRadius:".5rem",marginLeft:'.5rem',marginRight:'.5rem',color:'white'}} onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    
    </Dialog></>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function Createquedialog(prop) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

//   const [quizBankId, setQuizBankId] = React.useState();

  // console.log("prop.quizbankId",quizbankId)
  console.log("prop.open",open)


  // if (quizbankId !== null){
  //   setOpen(true)

  // }


// useEffect(() => {
//   setQuizBankId(prop.quizbankId)
  
// },[quizBankId])
  // useEffect(() => {
  //   console.log("prop.quizbankId",prop.quizbankId)
  //   if (prop !== undefined) {

  //           setOpen(true)
        // const { course } = location.state
        // console.log("data",course);
        
    //     setAddMode(false);
    //     console.log(isAddMode);
    
    // // if (!isAddMode) {
    //     // get user and set form fields
    //     // new Service().getCourseid(idnum).then(user => {
    //     //     console.log("editform",user.data.description);
    //         settitle(course.title);
    //         setid(course.id);
    //         setdescription(course.description);
    //         setdue(course.due_in
    //             );
    //         setduration(course.duration);
    //         setactive(course.is_active);
    //         setpublised(course.is_published);
    //         setlmslink(course.lms_link);
    //         setthumbnailid(course.thumbnail_asset_id);
    //         // const fields = ['title', 'firstName', 'lastName', 'email', 'role'];
    //         // fields.forEach(field => setValue(field, user[field]));
    //         // setUser(user);
        
    
//     }
// }, []);
  

  const handleClickListItem = () => {
    setOpen(true);
  
  };

  const handleClose = (newValue) => {
    setOpen(false);


    if (newValue) {
      setValue(newValue);
    }
  };


  return (
    
    <>
    <Box  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} >

          <Button
              
              sx={{ backgroundColor: "#f95877" }}
              onClick={handleClickListItem}
          >
              Create a new question bank
          </Button>


      </Box>
      <Box>
              <ConfirmationDialogRaw
                  prop = {prop}
                  value={value}
                  keepMounted
                  open={open}
                  onClose={handleClose} />
      </Box>
      </>
  );
}