import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Card, CardContent, Divider, Grid, IconButton, Pagination, Stack, Typography } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import React , {useEffect} from "react";
import { connect } from "react-redux";
import { fetchQuiz, stuDashboardQuizResult } from "../../store/action";
import DashboardBreadcrums from "../Quiz/DashboardBreadcrums";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Courses from './Courses';
import { Link } from 'react-router-dom';
import Eventscheduler from '../../JsComponents/Partcipants/Scheduler';
import UserTimeline from './Studenttimeline';
import { useDispatch, useSelector } from "react-redux";




const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "4px",
  margin:0,
  padding:0,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === 'light' ? '#5567ff' : '#5567ff',
  },
}));

const StudentDashboard = ({ quizdata }) => {

  console.log(quizdata);


  const breadcrums = ["HOME","STUDENT","DASHBOARD"];

  const [value,setValue] = React.useState('')

	const page  = "/stuDashboardQuizResult/1f25b73b-c690-4d27-80b8-9a40d2965734"

  const dispatch = useDispatch();
	useEffect(() => {
	  dispatch(stuDashboardQuizResult(page));
	  
	}, [page]);

	const quizResult = useSelector((state) => state.stuDashboardQuizResult);
  {quizResult && console.log("score",quizResult.data[0].score)}


  if (!quizdata){
    return (
        <h1>Loading...</h1>
    )
}




  
  return (
    <Grid container columns={12} sx={{ mt: 3, mb: 3 }}>
      <Grid item xs={2} sm={2} md={1} lg={1}></Grid>
      <Grid item xs={8} sm={8} md={10} lg={10}>
        <Grid container columns={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
            }}
          >
            <Typography
              variant="button"
              sx={{ lineHeight: 1.4, fontSize: "1.45rem", fontWeight: 300 }}
            >
              Dashboard
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              mb: 2,
              textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
            }}
          >
            <DashboardBreadcrums pages={breadcrums} />
          </Grid>

          <Grid container spacing = {1} sx = {{mb:2}}>
            <Grid item xs={3.4} sm={2.5} md={1.5} lg={1}>
              <Typography variant="button" 
              sx = {{fontSize: '.9375rem',fontWeight: 600,letterSpacing: "2px",  
                fontFamily: "Exo\ 2,Helvetica Neue,Arial,sans-serif"}}>Courses</Typography>
            </Grid>
            <Grid item xs={8.6} sm={9.5} md={10.5} lg={11} sx = {{mt:1.5}}>
              <Divider />
            </Grid>
          </Grid>

          {/* <Grid container spacing = {3}>
            <Grid item md ={3}>

            </Grid>
          </Grid> */}

          <Courses/>


          <Grid container spacing = {1} sx = {{mb:2}}>
            <Grid item xs={3.3} sm={2.5} md={1.5} lg={0.9}>
              <Typography variant="button" 
              sx = {{fontSize: '.9375rem',fontWeight: 600,letterSpacing: "2px",  
                fontFamily: "Exo\ 2,Helvetica Neue,Arial,sans-serif"}}>QUIZZES</Typography>
            </Grid>
            <Grid item xs={8.7} sm={9.5} md={10.5} lg={11.1} sx = {{mt:1.5}}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>

        <Grid container rowSpacing={3} columnSpacing = {3}>

            

          {quizResult && quizdata.map((quizdata,id) => (
            <Grid key = {id} item xs = {12} sm = {12} md = {6} lg = {6}>
              <Card sx = {{borderRadius:".5rem",border:"1px grey"}} elevation={1}>


            <CardContent sx = {{p:0,mb:-3}}>
              <Grid container  sx = {{p:1,mb:-.5}}>
                    <Grid item xs = {3} sm = {3} md = {3} lg = {2} >
                      {/* <Box sx = {{mr:3}}> */}
                          <img src={quizdata.image} alt="aitahub"  width={64}  height = {48}
                           style = {{borderRadius:'0.25rem'}}/>
                          {/* </Box> */}
                    </Grid>
                            
                    <Grid item xs = {7} sm = {7} md = {7} lg = {8} >
                      
                        <Typography sx = {{fontSize: "1rem",fontWeight: 550,color:"text.secondary"}} >{quizdata.name}</Typography>
                        <Typography sx = {{fontSize: '.8125rem',fontWeight: 400}} >
                          
                        {quizdata.progress?quizResult.date:
                        quizdata.date}
                          </Typography>
                    </Grid>
                    
                    <Grid item xs = {2} sm = {2} md = {2} lg = {2} >
                        <Stack  justifyContent={"flex-end"} >
                        <Typography  textAlign={"right"} variant = "button" 
                        sx = {{fontSize: '.9140625rem',fontWeight: 550,color:"text.secondary",mr:1.5}}
                        >{quizdata.progress?quizResult.data[0].score:
                        quizdata.score}</Typography>
                        <Typography textAlign={"right"} variant = "button"
                        sx = {{fontSize: '.8125rem',fontWeight: 400,lineHeight: 1.5}}

                        >score</Typography>
                        </Stack>
                      </Grid>

                </Grid>

                      <Grid item xs = {12} sm = {12} md = {12} lg = {12}>
                      {quizdata.progress?<BorderLinearProgress variant="determinate" value={quizdata.progress} />:
                      <Divider/>
                      }

                      </Grid>
                  <Grid container  >
                      <Grid item xs = {6} sm = {6} md = {6} lg = {6} sx = {{p:1}}>

                      {quizdata.progress?

                      <Button size="small" variant = "contained"  startIcon={<RefreshIcon  sx = {{width:16,height:17}}/>}>
                            <Typography variant = "button"  >{quizdata.button}
                            
                    </Typography>
                            </Button>
                            :
                            
                    <Button size="small" sx = {{pr:3}}  variant = "contained" startIcon={<PlaylistAddCheckIcon  sx = {{width:16,height:17}}/>}
                    endIcon={<Avatar sx={{ width: "16px", height: "16px",bgcolor:"white",mb:1 }}>

                      <Typography sx = {{fontWeight: 'bold',fontSize:"12px",color:"text.primary",textAlign:"center"}}>5</Typography>
                      </Avatar>}
                    
                    >
                          
                            <Typography variant = "button" >{quizdata.button}
                            
                    </Typography>
                            </Button>
                            
                            }


                        
                          
                        
                  </Grid>
                  
                  
                  <Grid item xs = {6} sm = {6} md = {6} lg = {6} >
                  <Stack direction = "row" justifyContent = {'end'} sx = {{mr:.5}}>
                          
                            <IconButton><MoreHorizIcon/></IconButton>
                            
                        </Stack>
                </Grid>

                </Grid>
            </CardContent>
            </Card>

            </Grid>
            
          ))}

          </Grid>
          <Grid container spacing = {1} sx = {{mt:3}}>
              <Grid item xs={6} sm={6} md={6} lg={12}>
                <Typography variant="button" 
                sx = {{fontSize: '.9375rem',fontWeight: 600,letterSpacing: "2px",  
                  fontFamily: "Exo\ 2,Helvetica Neue,Arial,sans-serif"}}>Upcoming Events</Typography>
              </Grid>
              <Grid item xs={8.6} sm={9.5} md={10.5} lg={11} sx = {{mt:1.5}}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx = {{mt:1.5}}>
                <UserTimeline />
              </Grid>
          </Grid>
          <Stack direction = "row" spacing={2} alignItems = "center"  justifyContent = "flex-start" sx = {{mt:3,ml:-2}}>
                      <Pagination 
                      
                      count={3} 
                      onChange={(e, value) => setValue(value)}
                  
                      />
                  </Stack>
          
         
            
      </Grid>
      
      <Grid item xs={2} sm={2} md={1} lg={1}></Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return { quizdata: state.quiz };
};



export default connect( mapStateToProps,fetchQuiz("quizdashboard?id=1&id=3"))(StudentDashboard);
