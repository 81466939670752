import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllMycourse } from "../../store/action";
import { getAllPosts,getSortedpost,getSortedratingpost } from "../../store/action";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Pagination from '@mui/material/Pagination';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import * as React from "react";
import Mycourse from "./Mycourses";




function Home() {
  const [page, setPage] = React.useState(1);
 
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getAllMycourse({page}));
  }, [page]);

  
  return (
    <Grid width="auto"container columns={12} >
      <Grid item lg={1} md={1} sm={1} xs={1}  >

      </Grid>
      <Grid item lg={10} md={10} sm={10} xs={10} >
        <Card
        elevation="none"
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          minHeight:"100vh",
          minWidth: "768px",
          maginLeft:"auto",
          maxWidth:"fit-content",
          
          border:"none",
          display:"flex",
          
          }}
        >
          <Grid container width="auto"  spacing={1} columns={12} sx={{padding: 4}} >
            <Grid item lg={9} md={9} sm={9} xs={12}  >
            <Typography sx={{fontSize:".8225rem",fontWeight:"400",textTransform: 'uppercase',paddingTop:0.85,fontFamily:"Exo\ 2,Helvetica Neue,Arial,sans-serif",color:"rgba(39,44,51,.5)"}}>my courses</Typography>
            </Grid>
            <Grid align="right"  item lg={1} md={1} sm={1} xs={12}  >
              <Typography sx={{fontSize:".8125rem",textTransform: 'uppercase',fontWeight:"400",paddingTop:0.85,fontFamily:"Exo\ 2,Helvetica Neue,Arial,sans-serif",color:"rgba(39,44,51,.5)"}}>SORT BY</Typography>
            </Grid>
            <Grid align="right"  item  lg={2} md={2} sm={2} xs={2} >
              <Button
                  id="demo-customized-button"
                  style={{fontSize:".8125rem",textDecoration:"underline",fontWeight:"400",border:"none"}}
                  disableElevation
                  
                  onClick={() => dispatch(getSortedpost({page}))}
                  endIcon={<UnfoldMoreIcon  />}
                >
                  NEWEST                  
              </Button>
            </Grid>
            {/* <Grid align="right"  item  lg={2} md={2} sm={2} xs={2} >
              <Button
                  id="demo-customized-button"
                  style={{fontSize:".8125rem",textDecoration:"underline",fontWeight:"400",border:"none"}}
                  disableElevation
                  
                  onClick={() => dispatch(getSortedratingpost({page}))}
                  endIcon={<UnfoldMoreIcon  />}
                >
                  POPULARITY                  
              </Button>
            </Grid> */}
            {/* <Grid sx={{justifyContent:"flex-start",minWidth:'768px',alignContent:'space-around'}} item lg={12} md={12} sm={12} xs={12}   >
              <p1>{"MY COURSES"}</p1>
            </Grid> */}
            <Grid sx={{justifyContent:"flex-start",minWidth:'768px',alignContent:'space-around'}} item lg={12} md={12} sm={12} xs={12}   >
            <Mycourse />
            </Grid>
            <Grid container spacing={3} columns={12}  >
              <Grid item lg={12} md={12} sm={12} xs={12} align="left" sx={{marginTop:4}} >
                  <Stack spacing={2}>
                      <Pagination 
                      
                      count={3} 
                      onChange={(e, value) => setPage(value)}
                  
                      />
                  </Stack>
              </Grid>

          </Grid> 
          </Grid> 
          
          
        </Card>

      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1}  >

      </Grid>
    </Grid>

  )
    
}

export default Home;