import React from "react";
import "../../components/Header/Header.scss";
import "../../styles/Button.scss";
import "./Login.scss";
import { BrowserRouter as router, Route, Link } from "react-router-dom";
import Service from "../../service/service";
class signup extends React.Component {
    state: any = {
        firstname: "",
        lastname: "",
        mailid: "",
        mobilenumber: "",
        password: ""
    }
    onValueChange = (event: any) => {
        this.setState({ [event.target.id]: [event.target.value] })
    };
    signupUser() {
        new Service().signupUser(this.state).then(
            res => {
                console.log("signupResult" + res);
            }
        );

    }
    render() {

        return (
            <section className="py-4 py-lg-5 pading">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7 col-xl-8">
                            <div className="login-section">
                                <div className="login-form custom">
                                    <ul className="login-slider login-pad mb-0">
                                        <li className="login-slider__item login-form__signup form-section col-12" id="signUp">
                                            <div className="log-head">
                                                <h1 className="fnt-32 fw-700 text-2424 mb-2">Sign up</h1>
                                            </div>

                                            <div className="logn-form">
                                                <div className="validate-form mb-3 mb-md-4">
                                                    <div className="form-required">
                                                        <div className="form-fields">
                                                            <input className="input" id="firstname" type="text" autoComplete="off" placeholder="First Name" onChange={this.onValueChange} />
                                                            <span className="form-fields__border"></span>
                                                        </div>
                                                        <div style={{ height: "10px" }}></div>
                                                        <div className="form-fields">
                                                            <input className="input" id="lastname" type="text" autoComplete="off" placeholder="Last Name" onChange={this.onValueChange} />

                                                            <span className="form-fields__border"></span>
                                                        </div>
                                                        <div style={{ height: "10px" }}></div>
                                                        <div className="form-fields">
                                                            <input className="input" id="mailid" type="text" autoComplete="off" placeholder="Mail Id" onChange={this.onValueChange} />

                                                            <span className="form-fields__border"></span>
                                                        </div>
                                                        <div style={{ height: "10px" }}></div>
                                                        <div className="form-fields">
                                                            <input className="input" id="mobilenumber" type="number" autoComplete="off" placeholder="Mobile Number" onChange={this.onValueChange} />

                                                            <span className="form-fields__border"></span>
                                                        </div>
                                                        <div style={{ height: "10px" }}></div>
                                                        <div className="form-fields">
                                                            <input className="input" id="password" type="password" autoComplete="off" placeholder="Password" onChange={this.onValueChange} />

                                                            <span className="form-fields__border"></span>
                                                        </div>
                                                    </div>
                                                    <div className="form-submit">
                                                        <button className="btn btn--primary form-submit__btn js-signUp js-next" type="button" onClick={() => this.signupUser()}>Signup</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
export default signup;