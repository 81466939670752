import React, { Component } from "react";
import Service from "../../service/service";
import "../../components/Footer/Footer.scss";
import "./payments.scss"
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import AdminHeader from "../../components/Dashboard/AdminHeader";
import ReactPaginate from "react-paginate";
import { FaFileExport } from 'react-icons/fa';
import Filter from "../../components/Filter";
import Moment from 'react-moment';
import 'moment-timezone';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import moment from "moment";
import SortableTable from "../../components/SortableTable";

export default class payments extends Component {
    props :any;
    state: any = {
        active: 'dashboard',
        color: 'dashboard',
        show: false,
        sidenavValue: ''
    };
    perPage : number = 5;
    datasOnPage : any = [];
    currentPaymentList:any=[];
    paymentList:any=[];
    selectedUser : any = {};
    selectedProgram : any = {};
    tableData : any = [];
    sortTable : any;
    constructor(props: any) {
        super(props);
        this.props = props;
        this.sortTable = React.createRef();
        new Service().getPaymentDetails().then(
            (response:any) => {
                this.paymentList = response.data.data;
                this.currentPaymentList = [...this.paymentList];
                this.datasOnPage = [...this.paymentList].slice(0,this.perPage);
                this.initializeTableRecords();
                this.exportPaymentExcelFile();
            }
        ).catch((res:any)=>{
                if((res+'').includes("401"))
                    this.props.history.push("/")
        });
    }

    handlePageClick = (data: any) => {
        let selected = data.selected + 1;
        this.datasOnPage = [...this.currentPaymentList].slice(this.perPage*data.selected,this.perPage*selected);
        this.initializeTableRecords();
        this.sortTable.current.initialize(this.tableData);
        // this.setState({isReadyToRender:true});
    };

    exportPaymentExcelFile(){
        const excel : any = new Service().exportPaymentExcelFile();
        fetch(excel.url, excel.option)
        .then(res => res.blob())
        .then(blob => {
        var anchorElem = document.getElementById('paymentExport') as HTMLAnchorElement;
        anchorElem.href = URL.createObjectURL(blob);
        })
        .catch(err=>this.props.history.push('/login'));
    }

    onSearch=(event:any)=>{
        this.currentPaymentList = Filter.search(this.paymentList,event.target.value);
        this.datasOnPage = [...this.currentPaymentList].slice(0,this.perPage);
        this.initializeTableRecords();
        this.sortTable.current.initialize(this.tableData);  
    }
    showUserDetails = (user:any) =>{
        this.selectedUser = user;
        this.setState({isUserDetailsTab:true});
    }

    showProgramDetails = (program:any)=>{
        this.selectedProgram = program;
        this.setState({isProgramDetailsTab:true});
    }

    initializeTableRecords(){
        let table : any = {
            styleProp : {
                width : "100%",
                height : "70vh"
            },
            columnDefinition : []
        }
        this.datasOnPage.forEach((data:any) => {
            table.columnDefinition.push({
                username : {
                    value : data.user.first_name+" "+data.user.last_name,
                    sortable : true,
                    action : {
                        event : this.showUserDetails,
                        param : data.user
                    },
                    style : {
                        color:"#fa5a78",
                        cursor : "pointer"
                    }
                },
                program : {
                    value : data.program.title,
                    sortable : true,
                    action : {
                        event : this.showProgramDetails,
                        param : data.program
                    },
                    style : {
                        color:"#fa5a78",
                        cursor : "pointer"
                    } 
                },
                transaction_id : {
                    value : data.transaction_id,
                    sortable : true
                },
                transaction_amount : {
                    value : data.transaction_amount,
                    sortable : true
                },
                transaction_date : {
                    value : moment(new Date(data.transaction_date)).format("DD/MM/YYYY hh:mm"),
                    sortable : true,
                },
                status:{
                    value : data.status ? "Success" : "Failure",
                    sortable : true,
                    style : data.status ? {color:"#00c04b",fontWeight:"bold"} : {color:"#fa5a78",fontWeight:"bold"}
                },
                response : {
                    value : data.response,
                    sortable : false
                }
            });
        });
        this.tableData = table;
        this.setState({isReadyToRender:true});
        
    }
    
    render() {

        return (
            <span>
                <AdminHeader/>
                <AdminNavbar menu="payments" />
                <div className="container">
                    <div className="row">
                    <div className="col-12 col-md-12 col-xl-12 remove-course-pading">
                    <div className="dashboard-content course-pad">
                        <div className="row">
                        <div className="Header">
                            <h1>Payments</h1>
                            <div>
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText onChange={this.onSearch} style={{marginRight:"20px",paddingTop:"5px",paddingBottom:"5px",cursor:"text"}} placeholder="Search" />
                                </span>
                                {/* <input type="text" placeholder="search" onChange={this.onSearch} style={{marginRight:"20px"}}/> */}
                                <a href="" id="paymentExport" target="_self" rel="noopener noreferrer" download={"transaction_summary"}>
                                    <FaFileExport className="exportIcon"/>
                                </a>
                            </div>
                        </div>
                        {
                        this.state.isReadyToRender &&
                            <SortableTable ref={this.sortTable} data = {this.tableData}/>
                        }   
                        {/* <table className="table table-bordered" style={{fontSize:"12px"}}>
                            <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Login Id</th>
                                <th>Status</th>
                                <th>Transaction Amount</th>
                                <th>Transaction ID</th>
                                <th>Transaction Date</th>
                                <th>Program</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.datasOnPage.map((payment: any, index: any) => (
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td onClick={()=>{this.showUserDetails(payment.user)}} className="details-tab-btn">{payment.user.login_id }</td>
                                        <td style={payment.status ? {color:"#00c04b",fontWeight:"bold"} : {color:"#fa5a78",fontWeight:"bold"}}>{payment.status ? "Success" : "Failure"}</td>
                                        <td>₹{payment.transaction_amount}</td>
                                        <td>{payment.transaction_id}</td>
                                        <td><Moment format="DD/MM/YYYY hh:mm">{payment.transaction_date}</Moment></td>
                                        <td onClick={()=>{this.showProgramDetails(payment.program)}} className="details-tab-btn">{payment.program.title}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table> */}
                        </div>
                    </div>
                    <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={Math.ceil(this.currentPaymentList.length/this.perPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'activeElement'}
                            // containerClassName={'pagination'}
                            />
                </div>
                    </div>
                </div>
                <Dialog header="User Details" visible={this.state.isUserDetailsTab} style={{ width: '50vw' }} onHide={()=>this.setState({isUserDetailsTab:false})}>
                    <div>
                        <table style={{fontSize:"12px",width:"100%"}}>
                            <tr>
                                <td>
                                    <tr>
                                        <td className="field-title">Username</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{this.selectedUser.first_name+" "+this.selectedUser.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td className="field-title">Email</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{this.selectedUser.email}</td>
                                    </tr>
                                    <tr>
                                        <td className="field-title">Mobile</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{this.selectedUser.mobile}</td>
                                    </tr>
                                </td>
                                <td>
                                    <tr>
                                        <td className="field-title">Login Id</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{this.selectedUser.login_id}</td>
                                    </tr>
                                    <tr>
                                        <td className="field-title">Current Education</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{this.selectedUser.current_education}</td>
                                    </tr>
                                    <tr>
                                        <td className="field-title">College Name</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{this.selectedUser.college_name}</td>
                                    </tr>
                                </td>
                            </tr>
                        </table>
                    </div>
                </Dialog>
                <Dialog header="Program Details" visible={this.state.isProgramDetailsTab} style={{ width: '50vw' }} onHide={()=>this.setState({isProgramDetailsTab:false})}>
                        <div>
                            <table style={{fontSize:"12px",width:"100%"}}>
                                <tr>
                                    <td>
                                        <tr>
                                            <td className="field-title">Title</td>
                                            <td>&nbsp;:&nbsp;</td>
                                            <td>{this.selectedProgram.title}</td>
                                        </tr>
                                        <tr>
                                            <td className="field-title">Category</td>
                                            <td>&nbsp;:&nbsp;</td>
                                            <td>{this.selectedProgram.category}</td>
                                        </tr>
                                    </td>
                                    <td>
                                        <tr>
                                            <td className="field-title">Price</td>
                                            <td>&nbsp;:&nbsp;</td>
                                            <td>{this.selectedProgram.price}</td>
                                        </tr>
                                        <tr>
                                            <td className="field-title">Offer Price</td>
                                            <td>&nbsp;:&nbsp;</td>
                                            <td>{this.selectedProgram.offer_price}</td>
                                        </tr>
                                    </td>
                                </tr>
                            </table>
                            <table style={{fontSize:"12px",padding:"10px",border:"1px solid black"}}>
                                <tr>
                                    <td style={{fontWeight:"bold",padding: "10px 10px 0"}}>Description : </td>
                                </tr>
                                <tr>
                                    <td style={{padding: "0 0 10px 20px"}}>{this.selectedProgram.description}</td>
                                </tr>
                            </table>
                        </div>
                </Dialog>
            </span>
        );
    }
}