import React from 'react'
import MUIRichTextEditor from "mui-rte";
import { createMuiTheme} from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import { convertToRaw } from 'draft-js'

export default function RichTextbox(props) {


    const emojis = [
        {
            keys: ["face", "grin"],
            value: "😀",
            content: "😀",
        },
        {
            keys: ["face", "joy"],
            value: "😂",
            content: "😂",
        },
        {
            keys: ["face", "sweat"],
            value: "😅",
            content: "😅",
        }
    ]
    
    const [value, setValue] = React.useState("");
        console.log(value);
        const onEditorChange = event => {
            const plainText = event.getCurrentContent().getPlainText() // for plain text
            const rteContent = convertToRaw(event.getCurrentContent()) // for rte content with text formating
            plainText && setValue(plainText)
            props.func(value)
            // rteContent && setContent( values => ({ ...values, content:rteContent})) // store your rteContent to state
        }
        // const save = (data) => {
            
        //     setValue(data);
        //     props.func(value)
        //     console.log(value);
        //   };
        const defaultTheme = createMuiTheme()
    
    Object.assign(defaultTheme, {
      overrides: {
          MUIRichTextEditor: {
              root: {
                  marginTop: 10,
                  width: "auto",
                  borderTop:"1px solid gray",
                  borderRadius:"0.5rem",
                  borderRight: "1px solid gray" ,
                  borderLeft:"1px solid gray",
                  backgroundColor:"#f2f2f2",
                  
              },
              editor: {
                  borderBottom: "1px solid gray" ,
                  borderRadius:"0.5rem",
                  borderTop:"1px solid gray",
                  padding:'1',
                  height:"72px",
                  minHeight:"72px",
                  backgroundColor:"#fff"
              }
          }
      }
    })

  return (
    <ThemeProvider theme={defaultTheme}>
                    <MUIRichTextEditor
                        contentEditable='true'
                        label="Enter here..."
                        autocomplete={{
                          strategies: [
                              {
                                  items: emojis,
                                  triggerChar: ":"
                              }
                          ]
                      }}
                        onChange={onEditorChange}
                        // onSave={save}
                        inlineToolbar={true}
                    />
                </ThemeProvider>
  )
}
