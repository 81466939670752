import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import CircularProgress from '@mui/material/CircularProgress';
import { convertToRaw } from 'draft-js'
// import Dashboard from "./Fileupload";
// import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import MUIRichTextEditor from "mui-rte";
import { createMuiTheme} from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom'
import { Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { pink } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Service from "../../service/service";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import CheckIcon from '@mui/icons-material/Check';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { Fragment, useEffect, useState } from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import { Diversity1Outlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom'
import AssignCourse from '../AssignCourse';
import DashboardBreadcrums from '../Quiz/DashboardBreadcrums';
const emojis = [
    {
        keys: ["face", "grin"],
        value: "😀",
        content: "😀",
    },
    {
        keys: ["face", "joy"],
        value: "😂",
        content: "😂",
    },
    {
        keys: ["face", "sweat"],
        value: "😅",
        content: "😅",
    }
]

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
  overrides: {
      MUIRichTextEditor: {
          root: {
              marginTop: 10,
              width: "100%",
              borderTop:"1px solid gray",
              borderRadius:"0.5rem",
              borderRight: "1px solid gray" ,
              borderLeft:"1px solid gray",
              backgroundColor:"#f2f2f2",
              
          },
          editor: {
              borderBottom: "1px solid gray" ,
              borderRadius:"0.5rem",
              borderTop:"1px solid gray",
              padding:'1',
              maxWidth:'100%',
              height:"auto",
              minHeight:"200px",
              backgroundColor:"#fff"
          }
      }
  }
})

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    '&:hover': {
        backgroundColor: pink[700],
    },
}));

const tagsList = [
    { title: 'VueJS' },
    { title: 'Angular' },
    { title: 'React.JS' }
];

function CreateCourse() {
    const history = useHistory();
    const location = useLocation();
    let { idnum } = useParams();
    const [isAddMode,setAddMode] = useState(true);
    const course = {};
    const [open, setOpen] = useState(false);
    const [opencontent, setOpencontent] = useState(false);
    // const[isEditForm,setisEditForm] = useState(false);
    const [title,settitle] = useState('');
    const[id,setid] = useState();
    const[thumbnailassetid,setthumbnailid] = useState(10);
    const[due,setdue] = useState('2023-01-18');
    console.log(due)
    const[Loading,setLoading] = useState(false);
    const[lmslink,setlmslink] = useState('https://www.alphalearn.com/?gclid=Cj0KCQiAq5meBhCyARIsAJrtdr4ulZ10MPNU0b-V1dO1-TzpcPFfG-3aTxK90y7GRQ6fOxDpVZ1kEY0aAkuKEALw_wcB');
    const[ispublised,setpublised] = useState(true);
    const[isactive,setactive] = useState(true);
    const [description,setdescription] = useState('');
    const [duration,setduration] = useState('');
    const [sections, setSections] = useState([]);
    const [Category, setCategory] = React.useState('Vue');
    const [TextValue,setTextValue] = useState('');
    const [sectionObj, setSectionObj] = useState({
        title: '',
        subsections: []
    });
    const [content, setContent] = React.useState({
        title:'',
        content:''
    });
    console.log("content",content);
    // const save = (data) => {
    //     console.log("data",data['blocks'])
        
    //     setContent( values => ({ ...values, content:data}));
        
    //   };
    const onEditorChange = event => {
        const plainText = event.getCurrentContent().getPlainText() // for plain text
        const rteContent = convertToRaw(event.getCurrentContent()) // for rte content with text formating
        rteContent && setContent( values => ({ ...values, content:rteContent})) // store your rteContent to state
      }
    useEffect(() => {
        if (location.state != undefined) {
            const { course } = location.state
            console.log("data",course);
            
            setAddMode(false);
            console.log(isAddMode);
        
        // if (!isAddMode) {
            // get user and set form fields
            // new Service().getCourseid(idnum).then(user => {
            //     console.log("editform",user.data.description);
                settitle(course.title);
                setid(course.id);
                setdescription(course.description);
                setdue(course.due_in
                    );
                setduration(course.duration);
                setactive(course.is_active);
                setpublised(course.is_published);
                setlmslink(course.lms_link);
                setthumbnailid(course.thumbnail_asset_id);
                // const fields = ['title', 'firstName', 'lastName', 'email', 'role'];
                // fields.forEach(field => setValue(field, user[field]));
                // setUser(user);
            
        
        }
    }, []);


    const handleCategory = (event) => {
        setCategory(event.target.value);
      };
    const Contenttitlechange = (event) => {
        setContent({
            title: event.target.value,
          });
    }
    const handleTextchange = (e) => {
        const file = e.target.files[0];
    
        let reader = new FileReader();
    
        reader.onload = (e) => {
          const file = e.target.result;
          console.log(file);
          setContent( values => ({ ...values, content:(file.split(/\n/))}))
          setTextValue(JSON.stringify(file));
        };
    
        reader.onerror = (e) => alert(e.target.error.name);
        reader.readAsText(file);
      };
    const onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setSectionObj(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        sections.push(sectionObj);
        setSections(sections => [...sections]);
        handleClose();
    }

    const handleSubChange = (e, index) => {
        const { name, value } = e.target;
        sectionObj.subsections[index] = { ...sectionObj.subsections[index], [name]: value };
        setSectionObj({ ...sectionObj });
    }
    const addContent = () => {
        setOpencontent(true);
    }
    const addSection = () => {
        setOpen(true);
        setSectionObj({
            title: '',
            subsections: []
        })
    };
    const handlecontentsubmit = () => {
        fetch("http://localhost:8000/contentcourse", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(content)
        }).then(
        setContent({
            title:'',
            content:''
        }),
        setOpencontent(false)
        )
        
    }
    const deleteSubsection = (i) => {
        sectionObj.subsections = sectionObj.subsections.filter((sub, index) => index !== i);
        setSectionObj({ ...sectionObj });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleContentclose = () => {
        setOpencontent(false);
    };
    const handledue = (newValue) => {
        
        setdue(newValue);
        // setdue(moment(newValue.$d).format('DD/MM/YYYY'))
        
        
      };
    const createSubSection = (e) => {
        e.preventDefault();
        sectionObj.subsections.push({
            subtitle: '',
            type: '',
            file: ''
        });
        setSectionObj({ ...sectionObj });
    }

    useEffect(() => {
        if (sections) {
            handleClose();
        }
    }, [sections])

   const savecourse = () =>{
    setLoading(true);
    const program = {
        "id": id != undefined ? id : 0,
        "title": title,
        "description": description,
        "duration": duration,
        "thumbnail_asset_id": thumbnailassetid != undefined ? thumbnailassetid : 0,
        "due_in": due,
        "lms_link": lmslink,
        "is_published" : ispublised,
        "is_active":isactive 
    };
    if (isAddMode) {
        console.log("data",program);
        new Service().addCourse(program).then(
            
            (response) => {
                        console.log(response);
                        setLoading(false);
                        history.push("/courses");
                    
            }
        );
    } else {
        console.log("data",program);
        new Service().updateCourse(program).then(
            
            (response) => {
                console.log(response);
                setLoading(false);
                history.push("/courses");
            }
        );
    }

   }
  const createBreadcrums = ["HOME","CREATE COURSE"];
  const editBreadcrums = ["HOME","EDIT COURSE"];


    return <div  id="create-course" style = {{marginLeft:"3rem",marginRight:"2rem"}}>


<Grid width="auto" container columns={12} sx  = {{mt:4,ml:2}}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                  }}
                >
                  <Typography
                    variant="button"
                    sx={{fontWeight:400,fontSize: '1.55rem'}}
                  >
                    {isAddMode ? 'Create Course' : 'Edit Course'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    mb: 2,
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                  }}
                >
                    { isAddMode? 
                  <DashboardBreadcrums  pages={createBreadcrums}/>
                   :<DashboardBreadcrums pages={editBreadcrums} />
                    }
                    {console.log("isAddMode",isAddMode)}
                </Grid>
            </Grid>
                        <Dialog 
                        color="success"
                        PaperProps={{
                            style: {
                           
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            },
                        }} open={Loading} 
                        >
                        <DialogContent sx={{display:'block',justifyContent:'center',alignItem:'center'}}>
                        <CircularProgress sx={{justifyContent:'center',alignItem:'center',ml:'2rem'}} />
                        <h4 style={{color:"#fff"}}>Loading...</h4>
                        </DialogContent>
                        </Dialog>
        <Grid container spacing={3} id="grid" style={{ maxWidth: "1200px", 
        margin: "0 auto" ,
        }}>

        
            {/* <Grid item lg={11} md={11} sm={12} xs={12}> */}
                {/* <Typography sx={{mt:'2rem',fontWeight:400,fontSize: '1.875rem',marginBottom:'1rem'}}>{isAddMode ? 'Add Course' : 'Edit Course'}</Typography> */}
                
            {/* </Grid> */}
            
            <Grid xs={9}>
                <Item>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container columns={12} >
                            <Grid item sm={3} md={3} lg={3} >
                                <Typography variant="button" sx={{ fontWeight: 600, letterSpacing: 2 }}>BASIC INFORMATION
                                </Typography>
                            </Grid>
                            <Grid item sm={9} md={9} lg={9} sx={{ mt: 1.5, pl: 2 }}>
                                <Divider />
                            </Grid>
                        </Grid>
                        <div style={{ marginBottom: "2rem" }}>
                            <Typography variant="overline" display="block" mb={1} mt={2} sx={{ color: 'text.secondary', fontWeight: 700 }}>
                                COURSE TITLE
                            </Typography>
                            <TextField fullWidth id='courseTitle' value={title} onChange={(e) => {settitle(e.target.value)}} helperText="Please see our course title guideline" />
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                            <Typography variant="overline" display="block" mb={1} mt={2}>
                                DESCRIPTION
                            </Typography>
                            <TextField
                                multiline
                                rows={4}
                                value={description}
                                onChange={(e) => {setdescription(e.target.value)}}
                                fullWidth
                                id="outlined-multiline-static"
                                helperText="Shortly describe this course."
                            />
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                            <Typography variant="overline" display="block" mb={1} mt={2}>
                                DURATION
                            </Typography>
                            <TextField
                                
                                value={duration}
                                onChange={(e) => {setduration(e.target.value)}}
                                
                                id="outlined-multiline-static"
                                helperText="describe this duration."
                            />
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                        <Typography variant="overline" display="block" mb={1} mt={2}>
                                DUEIN
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                            
                            inputFormat="DD/MM/YYYY"
                            
                            value={due}
                            onChange={handledue}
                            renderInput={(params) => <TextField helperText="describe this duein."  {...params} />}
                            /> 
                        </LocalizationProvider>
                        </div>
                        <div  style={{ marginBottom: "2rem",display:'flex' }}>
                            <Typography variant="overline" display="block" mt={.5} >
                                PUBLISHED
                            </Typography>
                            <Switch
                            checked={ispublised}
                            onChange={(e) => setpublised(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography variant="overline" display="block" ml={2} mt={.5} >
                                ACTIVE
                            </Typography>
                            <Switch
                            checked={isactive}
                            onChange={(e) => setactive(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <Grid  container columns={12} >
                            <Grid  item sm={2} md={2} lg={2} >
                                <Typography variant="button" sx={{ fontWeight: 600, letterSpacing: 2 }}>CONTENT
                                </Typography>
                            </Grid>
                            <Grid item sm={8} md={8} lg={8} sx={{ mt: 1.5, pl: 2}}>
                                <Divider />
                            </Grid>
                            <ColorButton variant="contained"  style={{ margin: "0 0 15px 0px"}} onClick={addContent}>ADD CONTENT</ColorButton>
                            
                            <Dialog maxWidth="auto" open={opencontent} onClose={handleContentclose} id="content">
                                <form  >
                                    <DialogTitle>Create Content</DialogTitle>
                                    <DialogContent  dividers>
                                        <TextField
                                            autoFocus
                                            style={{ marginBottom: "2rem" }}
                                            id="title"
                                            label="Title"
                                            name='title'
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            value={content.title || ""}
                                            onChange={Contenttitlechange}
                                        />
                                        <div style={{ marginBottom: "2rem" }}>
                                            <Typography variant="overline" display="block" mb={1} mt={2}>
                                                DESCRIPTION
                                            </Typography>
                                            <input type="file" name="input" onChange={handleTextchange} />
                                            <ThemeProvider theme={defaultTheme}>
                                                <MUIRichTextEditor
                                                    contentEditable='true'
                                                    label="Enter here..."
                                                    value = {TextValue}
                                                    autocomplete={{
                                                    strategies: [
                                                        {
                                                            items: emojis,
                                                            triggerChar: ":"
                                                        }
                                                    ]
                                                }}
                                                    onChange={onEditorChange}
                                                    // onSave={save}
                                                    inlineToolbar={true}
                                                />
                                            </ThemeProvider>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        {content.title && content.content &&
                                        <><Button onClick={handlecontentsubmit}>Save</Button><Button onClick={handleContentclose}>Cancel</Button></>
                                        }
                                    </DialogActions>
                                </form>
                            </Dialog>
                            <Grid item sm={2} md={2} lg={2} >
                                <Typography variant="button" sx={{ fontWeight: 600, letterSpacing: 2 }}>SECTIONS
                                </Typography>
                            </Grid>
                            <Grid item sm={8} md={8} lg={8} sx={{ mt: 1.5, pl: 2 }}>
                                <Divider />
                            </Grid>
                            <ColorButton variant="contained" style={{ margin: "0 0 15px" }} onClick={addSection}>ADD SECTION</ColorButton>
                            <Dialog open={open} onClose={handleClose} id="section">
                                <form onSubmit={handleSubmit}>
                                    <DialogTitle>Create Section</DialogTitle>
                                    <DialogContent dividers>
                                        <TextField
                                            autoFocus
                                            style={{ marginBottom: "2rem" }}
                                            id="title"
                                            label="Title"
                                            name='title'
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            value={sectionObj.title || ""}
                                            onChange={onInputChange}
                                        />
                                        <ColorButton variant="contained" style={{ marginBottom: "2rem" }} onClick={createSubSection}>CREATE SUB-SECTION</ColorButton>
                                        {sectionObj.subsections.length > 0 ?
                                            sectionObj.subsections.map((subsection, i) =>
                                                <List sx={{ width: '100%', bgcolor: 'background.paper' }} key={i}>
                                                    <ListItem alignItems="flex-start">
                                                        <TextField
                                                            id="subtitle"
                                                            label="Sub Title"
                                                            name='subtitle'
                                                            type="text"
                                                            variant="standard"
                                                            value={subsection.subtitle}
                                                            onChange={event => handleSubChange(event, i)}
                                                            style={{ width: "14rem", marginRight: "1rem" }}
                                                        />
                                                        <FormControl variant="standard" style={{ width: "8rem", margin: "0 1rem" }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                label="Type"
                                                                name='type'
                                                                value={subsection.type}
                                                                onChange={event => handleSubChange(event, i)}
                                                            >
                                                                <MenuItem value="ppt">PPT</MenuItem>
                                                                <MenuItem value="mp4">mp4</MenuItem>
                                                                <MenuItem value="pdf">PDF</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <ListItemButton style={{ position: "absolute", right: "35px", bottom: 0 }}>
                                                            <ListItemIcon>
                                                                <Fragment>
                                                                    <input
                                                                        color="primary"
                                                                        accept="image/*"
                                                                        type="file"
                                                                        id="icon-button-file"
                                                                        style={{ display: 'none' }}
                                                                    />
                                                                    <label htmlFor="icon-button-file">
                                                                        <FileUploadIcon />
                                                                    </label>
                                                                    
                                                                </Fragment>
                                                            </ListItemIcon>
                                                        </ListItemButton>
                                                        {/* <Stack alignItems="center" style={{paddingBottom:'1rem',paddingTop:'1rem',marginRight:'8rem',border:'1px solid gray',width:'100%',backgroundColor:"#fff"}} spacing={3}>
                                                        <UploadFileIcon sx={{ color: "rgba(1, 79, 110, 1)", height: '32px', width: '32px' }} />
                                                        <input type="file" 
                                                        
                                                        
                                                        
                                                        onChange={onFileChange} />
                                                        <Typography variant="qasubtitle" htmlFor="filePicker">Drag and drop the file here or click the button to upload the file.</Typography>
                                                        <Button  variant="outlined" onClick={onFileUpload} sx={{ padding: 1, fontSize: '14px',  borderRadius: '4px', height: '32px', fontWeight: '600' ,
                                                        bgcolor:"#f95877" ,color: 'white',':hover': {
                                                        bgcolor: '#f95877',
                                                        color: 'white', }
                                                        
                                                        }}>Upload</Button>

                                                        </Stack> */}
                                                        <ListItemButton style={{ position: "absolute", right: "-10px", bottom: 0 }} onClick={() => deleteSubsection(i)}>
                                                            <ListItemIcon>
                                                                <DeleteIcon />
                                                            </ListItemIcon>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            )
                                            : <></>
                                        }
                                    </DialogContent>
                                    <DialogActions>
                                        <Button type="submit">Save</Button>
                                        <Button onClick={handleClose}>Cancel</Button>
                                    </DialogActions>
                                </form>
                            </Dialog>
                            <Grid item sm={12} md={12} lg={12} id="sections">
                                {sections.map(section =>
                                    <Accordion key={section.id}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography variant="caption" sx={{ color: 'text.secondary', fontWeight: 700 }}>{section.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                <nav>
                                                    <List>
                                                        {section.subsections.map(subsection => <ListItem disablePadding>
                                                            <ListItemButton>
                                                                <ListItemIcon>
                                                                    <DragHandleIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary={subsection.subtitle} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                        )}
                                                    </List>
                                                </nav>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Item>
            </Grid>
            <Grid xs={3}>
                <Item style={{ marginBottom: "2rem" }}>
                    
                        <CardContent style={{ textAlign: 'center' }}>
                        
                            {/* <ColorButton onClick ={savecourse} variant="contained" style={{ margin: "0 0 15px 0" }}>SAVE CHANGES</ColorButton> */}
                            <Button onClick ={savecourse} style={{ margin: "0 0 15px 0" }}>SAVE CHANGES</Button>
                            {/* <AssignCourse /> */}
                            <Divider></Divider>
                            <nav aria-label="secondary">
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemButton>
                                            <ListItemText primary="SAVE DRAFT" primaryTypographyProps={{
                                                fontSize: '14px',
                                                fontWeight: 'medium'
                                            }} />
                                            <CheckIcon />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton>
                                            <ListItemText primary="DELETE COURSE" primaryTypographyProps={{
                                                fontSize: '14px',
                                                fontWeight: 'medium'
                                            }} />
                                            <CheckIcon />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </nav>
                        </CardContent>
                    
                </Item>
                <Item style={{ marginBottom: "2rem" }}>
                    <Grid container columns={12} >
                        <Grid item sm={3} md={3} lg={3} >
                            <Typography variant="button" sx={{ fontWeight: 600, letterSpacing: 2 }}>VIDEO
                            </Typography>
                        </Grid>
                        <Grid item sm={9} md={9} lg={9} sx={{ mt: 1.5, pl: 2 }}>
                            <Divider />
                        </Grid>
                    </Grid>
                    
                        <iframe width="260" src="https://www.youtube.com/embed/bMknfKXIFA8" title='1' allowFullScreen></iframe>
                        <CardContent>
                            <Typography variant="caption" sx={{ fontWeight: 700 }}>
                                URL
                            </Typography>
                            <TextField
                                fullWidth
                                id="standard-helperText"
                                defaultValue="https://www.youtube.com/embed/bMknfKXIFA8"
                                helperText="Enter a valid video URL."
                                variant="standard"
                            />
                        </CardContent>
                    
                </Item>
                <Item style={{ marginBottom: "4rem" }}>
                    <Grid container columns={12} >
                        <Grid item sm={4} md={4} lg={4} >
                            <Typography variant="button" sx={{ fontWeight: 600, letterSpacing: 2 }}>OPTIONS
                            </Typography>
                        </Grid>
                        <Grid item sm={8} md={8} lg={8} sx={{ mt: 1.5, pl: 2 }}>
                            <Divider />
                        </Grid>
                    </Grid>
                    
                        <Box sx={{padding: "1rem"}}>
                            <FormControl fullWidth>
                                <Typography variant="button" display="block" mb={1} sx={{ color: 'text.secondary', fontWeight: 500 }}>
                                    CATEGORY
                                </Typography>
                                <Select id="demo-simple-select" onChange={handleCategory} value={Category}>
                                    <MenuItem value="Vue">Vue</MenuItem>
                                    <MenuItem value="React">React</MenuItem>
                                    <MenuItem value="Angular">Angular</MenuItem>
                                </Select>
                                <FormHelperText>Select a category.</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth sx={{ mt: 2 }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-price" sx={{ color: 'text.secondary', fontWeight: 500 }}>PRICE</InputLabel>
                                <Input
                                    id="standard-adornment-price"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                />
                                <FormHelperText id="standard-price-helper-text">The recommended price is between $17 and $24</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <Typography variant="button" display="block" mb={1} sx={{ color: 'text.secondary', fontWeight: 500 }}>
                                    TAGS
                                </Typography>
                                <Stack spacing={3}>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={tagsList}
                                        getOptionLabel={(option) => option.title}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </Stack>
                                <FormHelperText id="standard-tags-helper-text">Select one or more tags.</FormHelperText>
                            </FormControl>
                        </Box>
                    
                </Item>
            </Grid>
        </Grid>
        {/* <div>
        <Dashboard />
        </div> */}
    </div>;
}

export default CreateCourse;



