import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { useSelector } from "react-redux";
import SelectLabels from './Selectedit';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {getquebank} from '../../store/action';
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Moment from 'moment';
import { useHistory} from 'react-router-dom';
import axios from 'axios'
import { Alert, Dialog, DialogActions, DialogTitle, Snackbar } from '@mui/material';




function EnhancedTableHead(props) {
  // const headcells = useSelector((state) => state.Quizbank.quizbankheadcell);
  // console.log("headcelss", headcells);
  const { onSelectAllClick,numSelected, rowCount, onRequestSort } =
    props;
 

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        
        <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Question Type
            
          </TableCell>
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Edit
            
          </TableCell>
          {/* <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Summary
            
          </TableCell> */}
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Score
            
          </TableCell>
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Active
            
          </TableCell>
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Createdat
            
          </TableCell>
          <TableCell
            
            align='left'
            padding={'normal'}
            sx={{fontSize:'18px',fontWeight:400,color:'#000000'}}
            
          >
            Updatedat
            
          </TableCell>
        
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  
  onSelectAllClick: PropTypes.func.isRequired,
  
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({numSelected,selected,func}) => {

  const[deletepopup,setDeletepopup] = React.useState(false);
  const[alert,setAlert] = React.useState(false);


  const handleDelete = () =>{
    setDeletepopup(true);
    setAlert(false)

   }

  const handleDeleteFalse = () =>{
    setDeletepopup(false);
   }
  const deleteQuestion = () =>{

    var data = JSON.stringify({
      "questionId":selected
    });
    
    var config = {
      method: 'put',
    maxBodyLength: Infinity,
      url: 'https://event.aitahub.com/deleteQuestion',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      func("its triggered")

      console.log(JSON.stringify(response.data));
      // navigate.push("/createquestion")
      // selected.setSelected([])
      setDeletepopup(false)
      setAlert(true)
    })
    .catch(function (error) {
      console.log(error);
    });


  }

  
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setAlert(false);

  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h8"
          id="tableTitle"
          component="div"
        >
          0 selected
        </Typography>
      )}

      {numSelected > 0 && 
        <Tooltip title="Delete">
          <IconButton onClick = {handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }

        <Dialog
          open={deletepopup}
          onClose={handleDeleteFalse}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{fontSize:'.9005rem',color:'#000000'}}  id="alert-dialog-title">
            <Alert severity="error">Are you sure you want to delete the Question?</Alert>
            <Typography sx = {{pt:3,pl:7}} variant = "body2" color = "grey">Warning! Question cannot be undone.</Typography>
            </DialogTitle>
            <DialogActions sx = {{mr:2}}>
              <Button size="small" onClick={handleDeleteFalse}>No</Button>
              <Button size="small" onClick={deleteQuestion} autoFocus>
                Yes
              </Button>
            </DialogActions>
        </Dialog>   

        <Box >
              <Snackbar anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
              }} open={alert} autoHideDuration={6000} onClose={handleAlertClose}>
                  <Alert onClose={handleAlertClose} severity = "success" sx={{ width: '100%' }}>
                      Question Successfully Deleted!
                  </Alert>
              </Snackbar>
        </Box>


    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function QueTable(prop) {
  const[pulltrigger,setpulltrigger] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  const delete_trigger = (data) => {
    setpulltrigger(true) // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
    setTimeout(() => setpulltrigger(false), 500)
    setSelected([])
  }

  console.log("id",prop.id)
  const id = prop.id
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getquebank({id}));
    
  }, [id,pulltrigger]);
  const users = useSelector((state) => state.Quizbank.quizbank);
  console.log("users", users);
  
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);



  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users && users.map((n) => n.id);
      setSelected(newSelected);
      console.log(selected);
      return;
    }
    setSelected([]);
    
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    users && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} selected = {selected} func = {delete_trigger}   />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              // order={order}
              // orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              rowCount={users && users.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {users && users.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}}
                        scope="row"
                        padding="none"
                      >
                        {row.type}
                      </TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}} align="left">
                      <Link to={{ pathname: row.url+"/"+prop.id, state: { questiontext: users[index] } }} style={{ textDecoration: 'none' }}>
                        <Button
                            size = "small"
                            sx={{ backgroundColor: "#f95877" }}
                            
                        >
                            Edit
                        </Button>
                      </Link>
                      </TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}}  align="left">{row.score}</TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}}  align="left">{row.active === 0?'Not Active':'Active'}</TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}}  align="left">{Moment(row.createdAt).format('DD-MM-YYYY HH:mm:ss')}</TableCell>
                      <TableCell sx={{fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',color:"#336",fontSize: '.975rem',fontWeight:"450"}} align="left">{Moment(row.updatedAt).format('DD-MM-YYYY HH:mm:ss')}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>

    </Box>
  );
}
