import axios from "axios";
import Config from "../components/Global";
axios.defaults.baseURL = "https://uat.admin.aitahub.com/api/";
//axios.defaults.baseURL = "http://localhost:8082/";
export default class Service {

  async getAllCourseList() {
    const courseList = await axios({
      url: "/course",
      method: "GET",
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    });
    return courseList.data.data;
  }

  async getUserList() {
    const userList = await axios({
      url: "/student/profiles",
      method: 'get',
      // params: {
      //   "per_page": 10,
      //   "page": pageNumber
      // },
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    });
    return userList.data.data;
  }
  async addProgram(data:any){
    const response = await axios({
      url: '/program',
      method: 'POST',
      data: data,
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })
    return response.data;

  }
  async updateProgram(data:any){
    const response = await axios({
      url: '/program',
      method: 'PUT',
      data: data,
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })
    return response.data;

  }
  getImageAsset(assetId:number) {
    return axios.defaults.baseURL+"imageasset/"+assetId;
    // return {
    //   url : axios.defaults.baseURL+"imageasset/"+assetId,
    //   option : { 
    //     method: 'get', 
    //     headers: new Headers({
    //         'Authorization': `Bearer ${Config.getToken()}`
    //     }), 
    //   }
    // };
  }

  getDocumentPDF(assetId:number){
    return axios.defaults.baseURL+"pdfasset/"+assetId;
    // return {
    //   url : axios.defaults.baseURL+"imageasset/"+assetId,
    //   option : { 
    //     method: 'get', 
    //     headers: new Headers({
    //         'Authorization': `Bearer ${Config.getToken()}`
    //     }), 
    //   }
    // };
  }

  async getProgramList() {
    const programList = await axios({
      url: "admin/programs",
      headers: {
        'Authorization': `Bearer ${Config.getToken()}`
      } 
    });
    return programList.data;
  }

  async addCourse(data:any){
    const response = await axios({
      url: '/course',
      method: 'POST',
      data: data,
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })
    return response.data;

  }

  async getCoursesList() {
    const courseList = await axios({
      url: "/course",
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    });
    return courseList.data;
  }

  async updateCourse(data:any){
    const response = await axios({
      url: '/course',
      method: 'PUT',
      data: data,
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })
    return response.data;

  }

  async getCourseList() {
    const response = await axios({
      url: "student/courses",
      method: "GET",
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    });
    return response.data;
  }

  async getUserById(userId: any) {
    const userById = await axios({
      url: "admin/student/" + userId,
      method: "GET",
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    });
    return userById.data;
  }

  async getUserCourseById(userId: any) {
    const user = await axios({
      url: "admin/student/" + userId + "/courses",
      method: "GET",
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    });
    return user.data;
  }

  async assignUserCourse(courseId: any, userId: any) {
    const userCourse = await axios({
      url: "admin/enroll",
      method: "POST",
      data: {
        "user_id": userId,
        "type": "StudentEnrollment",
        "course_id": courseId
      },
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    });
    return userCourse.status
  }

  async getUserByCourseId(courseId: any) {
    const getusersByCourseId = await axios({
      url: "admin/courses/" + courseId + "/users",
      method: "GET",
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    });
    return getusersByCourseId.data;
  }


  async signupUser(userDetails: any) {
    const signup = await axios({
      url: "student/register",
      method:"POST",
      // headers:{
      //   'Authorization': 'Bearer ' + Config.getToken(),
      // },
      data : {
        "displayName": userDetails.firstname + " " + userDetails.lastname,
        "givenName": userDetails.firstname,
        "mailNickname": userDetails.firstname,
        "userPrincipalName": userDetails.firstname + "." + userDetails.lastname + "@aitahub.com",
        "mail": userDetails.firstname + "." + userDetails.lastname + "@aitahub.com",
        "mobilePhone": userDetails.mobilenumber,
        "surname": userDetails.lastname,
        "passwordProfile": {
          "password": userDetails.password
        }
      }
    });
    return signup.data
  }


  getCategories() {
    return [
      {
        name: "All Programs",
        value : "All_PROGRAMS"
      },
      {
        name: "MIDSP - Most in Demand Skill Programs",
        value : "MOST_IN_DEMAND_SKILL_PROGRAMS",
        courses: [
          {
            name: "MIDSP-Data Science Program",
            duration: "",
            price: "",
            pdf: "",
            image_url: "",
          },
          {
            name: "MIDSP-Chartered Accountant Program",
            duration: "",
            price: "",
            pdf: "",
            image_url: "",
          },
          {
            name: "MIDSP-Data Science Management Program",
            duration: "",
            price: "",
            pdf: "",
            image_url: "",
          }
        ]
      },
      {
        name: "JRP - Job Readiness Program",
        value: "JOB_READINESS_PROGRAMS",
        courses: [
          {
            name: "JRP-Tech Careers",
            duration: "",
            price: "",
            pdf: ""
          },
          {
            name: "JRP-Business Careers",
            duration: "",
            price: "",
            pdf: ""
          }
        ]
      },
      {
        name: "JPP - Job Placement Program",
        value:"JOB_PLACEMENT_PROGRAMS",
        courses: [
          {
            name: "JPP-Tech Careers",
            duration: "",
            price: "",
            pdf: ""
          },
          {
            name: "JPP-Business Careers",
            duration: "",
            price: "",
            pdf: ""
          }
        ]
      },
      {
        name: "CET - Competitive Exam Training",
        value: "COMPETITIVE_EXAM_TRAINING",
        courses: [
          {
            name: "JRP - Job Readiness Program",
            duration: "",
            price: "",
            pdf: ""
          }
        ]
      },
      {
        name: "ES - Employment Skills ",
        value:"EMPLOYEMENT_SKILLS",
        courses: [
          {
            name: "ES - Employment Skills",
            duration: "",
            price: "",
            pdf: ""
          }
        ]
      }
    ]
  }

  async addAsset(file:any,programId:any,type:any){
    var formData = new FormData();
    formData.append(type,file);
    return await axios({
      url: "/program/"+ programId + "/" + type,
      method: "PUT",
      data: formData,
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })  
  }

  async addAssetToCourse(file:any,courseId:any,type:any){
    var formData = new FormData();
    formData.append(type,file);
    return await axios({
      url: "/course/"+ courseId + "/" + type,
      method: "PUT",
      data: formData,
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })  
  }

  async deleteProgram(programId:any){
    return await axios({
      url: "/program/" + programId,
      method: "DELETE",
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })
  }

  async deleteCourse(courseId:any){
    return await axios({
      url: "/course/" + courseId,
      method: "DELETE",
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })
  }

  getCourseThumbnail(assetId:number) {
    return {
      url : axios.defaults.baseURL+"course/thumbnail/"+assetId,
      option : { 
        method: 'get', 
        headers: new Headers({
            'Authorization': `Bearer ${Config.getToken()}`
        }), 
      }
    };
  }
  async getPaymentDetails(){
    return await axios({
      url: "/payment",
      method: "GET",
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })
  }

  async getProgramById(programId:number){
    return await axios({
      url: "/program/"+programId,
      method: "GET",
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })
  }

  async addPaymentDetails(data:any){
    return await axios({
      url: "payment",
      method : "POST",
      data : data,
      headers:{
        'Authorization': 'Bearer ' + Config.getToken(),
      }
    })
  }

  async updateUser(userDetails:any){
    return await axios(
      {
        url : "student/profile",
        method:"PUT", 
        data :userDetails,
        headers:{
          'Authorization': 'Bearer ' + Config.getToken(),
        }
      });
  }

  async getUserByEmail(email:string){
    return await axios(
      {
        url : "/user/profile?email="+email,
        method:"GET", 
        headers:{
          'Authorization': 'Bearer ' + Config.getToken(),
        }
      });
  }

  exportPaymentExcelFile(){
    return {
      url : axios.defaults.baseURL+"payment/excel",
      option : { 
        method: 'get', 
        headers: new Headers({
            'Authorization': `Bearer ${Config.getToken()}`
        }), 
      }
    };
  }
}

