import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import axios from "axios";
import ViewHeader from "./ViewHeader";

export default function TextViewer() {
  const [textdata, SetTextdata] = useState([]);

  let fetchData = async () => {
    let resp = await axios.get("http://localhost:8100/typescript.txt");
    let final = await resp.data;
    console.log(final);
    SetTextdata(final);
  };

  useEffect(() => {
    fetchData();
  });

  return (
    <div style = {{marginBottom:"36px"}}>
      <ViewHeader/>
      <Grid container columns={12} spacing={1}>
        <Grid item md={1} lg={1}></Grid>

        <Grid item md={10} lg={10}>
          {/* video content Grid */}
          <Typography variant="h3" sx = {{mt:3,mb:3}}>What is Angular?</Typography>

          <Typography variant="h6">{textdata}</Typography>

        </Grid>
        <Grid item md={1} lg={1}></Grid>
      </Grid>
    </div>
  );
}
